import React from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import is from 'date-fns/locale/is';
registerLocale('is', is)
import Application from "../../components/application";
import ApplicationApproved from "../../components/application-approved";
import ApplicationElectricalContract from "../../components/application-electrical-contract";

import { fetchApartments } from '../../api/apartments/actions'
import { currencyFormatter } from '../../utils'
import ApplicationInfoHeader from '../application-info-header';

const CustomInput = ({ value, onClick }) => (
        <>
            <input type="text" className={`${value && 'date'} d-none d-xxl-block`} disabled onClick={onClick} value={value} placeholder="Óskir um annan afhendingard." />
            <input type="text" className={`${value && 'date'} d-xxl-none`} disabled onClick={onClick} value={value} placeholder="Önnur afhend.dags." />

            <svg onClick={onClick} width="18" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#686C6E" fillRule="nonzero"><path d="M16.153 1.847h-3.11V.603a.31.31 0 10-.622 0v2.799a.31.31 0 10.622 0v-.933h3.11c.515 0 .933.419.933.933v2.799H.914V3.402c0-.515.419-.933.933-.933h2.488v-.622H1.847c-.857 0-1.555.698-1.555 1.555v12.751c0 .857.698 1.555 1.555 1.555h14.307c.857 0 1.555-.698 1.555-1.555V3.402a1.558 1.558 0 00-1.556-1.555zm.933 14.306a.934.934 0 01-.933.933H1.847a.934.934 0 01-.933-.933v-9.33h16.173v9.33h-.001z" /><path d="M5.268 3.713a.31.31 0 00.311-.311v-.933h6.22v-.622h-6.22V.603a.31.31 0 10-.622 0v2.799a.31.31 0 00.311.311z" /></g></svg>
        </>
    );

class ApplicationOverview extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
        moment.updateLocale(moment.locale('is'), { invalidDate: "Ekkert valið" })
        this.handleDateChange = this.handleDateChange.bind(this)
    }

    componentDidMount() {
        let n = window.location.hash.lastIndexOf('/');
        let id_ibud = window.location.hash.substring(n + 1);
        this.props.dispatch(fetchApartments(id_ibud));
    }

    componentDidUpdate() {
        animateLabels();
        slideLabelsUp();
    }

    handleDateChange(date) {
        let d = moment(date).format('DD.MM.YYYY');
        this.setState({
            newDeliveryDate: date
        });
    };

    doesApplicationExist(idIbud) {
      let hasApplication = false;
      let members = this.props.members.results;
      if (members && members[0]) {
          if (members[0].Applications.length > 0) {
              members[0].Applications.map((application) => {
                hasApplication = (parseInt(application.ibudId) === idIbud) ? true : hasApplication;
              })
          }
      }
      return hasApplication
  }

    render() {
        let apartment = this.props.apartment.results;
        let applicant = this.props.members.results[0].Membership
        let housemate = this.props.members.results[0].Housemates[0]
        let application = {}
        if (!this.props.members.isFetching && !this.props.apartment.isFetching && this.props.members.results && apartment) {
            application = this.props.members.results[0].Applications.find(item => item.ibudId == apartment.id_ibud)
        }

        return (
            <div className="col-12 col-lg-7 my-information">
                {this.props.members.isFetching && <div className="loader"></div>}
                {!this.props.members.isFetching && !this.props.apartment.isFetching &&
                    <>
                        <ApplicationInfoHeader title="Mín umsókn" apartment={apartment} doesApplicationExist={this.doesApplicationExist} members={this.props.members.results} application={application}></ApplicationInfoHeader>
                        {/* <>{this.getRelevantApplicationView(apartment, status)}</> */}
                        <div className="row">
                        <h3 className="col-12">Umsækjandi</h3>
                        <div className="col-12 personal-info-section">
                          <div className="row">
                            <h4>{applicant.name}&nbsp;</h4>
                            <h4>kt. {applicant.Kennitala}</h4>
                          </div>
                          <div className="row flex-column">
                            <p>Netfang: <b>{applicant.email}</b></p>
                            <p>Sími: <b>{applicant.phone}</b></p>
                            <p>Heimilisfang: <b>{applicant.address}</b></p>
                            <p>Póstnúmer: <b>{applicant.postal_code}</b></p>
                            <p>Fjöldi barna: <b>{applicant.children}</b></p>
                          </div>
                        </div>
                    </div>
                    { housemate &&        
                      <div className="row">
                          <h3 className="col-12">Meðleigjandi / Maki</h3>
                          <div className="col-12 housemate-info-section">
                            <div className="row">
                              <h4>{housemate.name}&nbsp;</h4>
                              <h4>kt. {housemate.housemate_ssn}</h4>
                            </div>
                            <div className="row flex-column">
                              <p>Heimilisfang: <b>{housemate.address}</b></p>
                              <p>Póstnúmer: <b>{housemate.postal_code}</b></p>
                            </div>
                          </div>
                      </div>
                    }
                    {/* <div className="row files-row">
                        <h3 className="col-12">Fylgigögn</h3>
                        <div className="col-12"><p>Umsögn leigusala eða meðmæli</p></div>
                    </div>
                    <div className="row comments-row">
                        <h3 className="col-12">Athugasemdir</h3>
                        <div className="col-12 info-section">
                            
                        </div>
                    </div> */}
                    </>
                }
            </div>
        )
    }
}

ApplicationOverview.defaultProps = {
}


export default ApplicationOverview;