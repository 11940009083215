import React from 'react';
import {shortenLongTownName, currencyFormatter} from '../../utils';


class ApartmentCard extends React.Component {

    constructor(props) {
        super(props)
    }

    hasBanner(apartment) {
        if (apartment.ibud_laus) {
            let bannerContent = []
            if (apartment.tegund_ibudar === "Leiguíbúð") {
                bannerContent.push(<div className="row"><div className="col-12 before-advertised lfb">Til leigu</div></div>)
            } else {
                if (apartment.nybygging && apartment.nytt_i_solu)
                    {bannerContent.push(<div className="row">
                    <div className="col-3 new-apartment">Nýbygging</div>
                    <div className="col-9 before-advertised">Nýtt í sölu</div>
                    </div>
                    )}
                 else if (apartment.nybygging && !apartment.nytt_i_solu) {
                    bannerContent.push(<div className="row"><div className="col-4 col-md-3 new-apartment">Nýbygging</div></div>)
                } else if (!apartment.nybygging && apartment.nytt_i_solu) {
                    bannerContent.push(<div className="row"><div className="col-12 before-advertised">Nýtt í sölu</div></div>)
                } else if(apartment.i_kaupferli) {
                    bannerContent.push(<div className="row"><div className="col-12 before-advertised">Í kaupferli</div></div>)
                } else if(apartment.adur_auglyst) {
                    bannerContent.push(<div className="row"><div className="col-12 before-advertised red-bg">Laus til umsóknar óháð félagsnúmeri</div></div>)
                } else {
                    bannerContent.push(<></>)
                }
            }
            return bannerContent
        }
    }

    render() {
        let apartments = this.props.apartments
        let lfb = (this.props.lfb === "True")
        
        return (apartments.map((apartment, index) => {
            let isKaupferli = apartment.i_kaupferli ? 'order-2' : 'order-1'; 
            
            return (
            <div className={`${isKaupferli} ${(this.props.lfb === 'True' && this.props.threeEmptyApart) ? 'col-12' : 'col-12 col-md-6 col-lg-4'} card-box`} key={index}>
            
                <a href={this.props.prependUrl ? this.props.prependUrl + apartment.id_ibud.toString() : apartment.id_ibud.toString()} className="card apartment-card">
                    <div className="img-box">
                        <img className="card-img-top" src={apartment.card_image} alt="Apartment card image"/>
                        { this.hasBanner(apartment) }
                    </div>
                    <div className="card-body">
                        <div className="card-title">
                            <span className="title">{apartment.heimilisfang}</span>{apartment.numer_ibudar}
                            <span className="float-right card-pno d-none d-xxl-block">{apartment.postnumer} {apartment.sveitarfelag}</span>
                            <span className="float-right card-pno d-block d-xxl-none">{apartment.postnumer} {shortenLongTownName(apartment.sveitarfelag)}</span>
                        </div>
                        {  !lfb &&
                          <div className="card-info">Verð búseturéttar <span className="float-right">{apartment.verd_buseturettar && apartment.ibud_laus ? currencyFormatter(apartment.verd_buseturettar) + " kr." : ""}</span></div>
                        }
                        <div className="card-info">{ !lfb ? "Mánaðarlegt búsetugjald" : "Leiguverð" }<span className="float-right">{apartment.busetugjald && apartment.ibud_laus ? currencyFormatter(apartment.busetugjald) + " kr." : ""}</span></div>
                        <div className="card-info">Stærð <span className="float-right">{apartment.fermetrar} m<sup>2</sup></span></div>
                        <div className="card-info">Tegund húsnæðis <span className="float-right">{apartment.tegund_husnaedis}</span></div>
                        <div className="card-info">Herbergi <span className="float-right">{apartment.herbergi}</span></div>
                    </div>
                </a>
            </div>
            )}
          ))
    }
}

ApartmentCard.defaultProps = {
}


export default ApartmentCard;
