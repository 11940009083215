import React from "react";
import { connect } from 'react-redux'
import { fetchMemberInfo } from "../../api/members/actions";
import { fetchApartments } from '../../api/apartments/actions'


class DropdownMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          showMyApartment: true,
          showApplications: true,
          showMyInformation: true
        };

        this.showMyApartment = this.showMyApartment.bind(this)
    }

    showMyApartment(){
        let members = this.props.members
        let val = false
        if(!members.isFetching && members.results.length){
          if(members.results[0].CurrentApartment){
            val = members.results[0].CurrentApartment.length > 0
          } else if (members.results[0].Current_apartment){
            val = members.results[0].Current_apartment.length > 0
          }
        }
        return val
      }

    render() {
        return (
          <>
            { this.showMyApartment() && 
              <li className="dropdown-item" id="mobile-menu-my-apartment">
                    <a className="dropdown-link" href="/minarsidur/#ibudin-min"
                        onClick={() => {window.location.href='/minarsidur/#ibudin-min'; window.location.reload(true);}}>Íbúðin
                        mín</a>
              </li>
            }
            <li className="dropdown-item" id="mobile-menu-my-applications">
                <a className="dropdown-link" href="/minarsidur/#umsoknir"
                    onClick={() => {window.location.href='/minarsidur/#umsoknir'; window.location.reload(true);}}>Umsóknir</a>
            </li>
            <li className="dropdown-item" id="mobile-menu-my-information">
                <a className="dropdown-link" href="/minarsidur/#minar-upplysingar"
                    onClick={() => {window.location.href='/minarsidur/#minar-upplysingar'; window.location.reload(true);}}>Mínar
                    upplýsingar</a>
            </li>
            <li className="dropdown-item" id="mobile-menu-ursogn">
                <a className="dropdown-link" href="/minarsidur/#ursogn"
                    onClick={() => {window.location.href='/minarsidur/#ursogn'; window.location.reload(true);}}>Breyta félagsaðild</a>
            </li>
            <li className="dropdown-item username">
                <a className="dropdown-link user-icon"
                    href="#">
                    <svg className="mr-2 mb-2" xmlns="http://www.w3.org/2000/svg" width="16" height="18">
                        <path fill="#686C6E" fillRule="nonzero"
                            d="M10.818 9.969c1.57-.944 2.618-2.649 2.618-4.595C13.436 2.406 11 0 8.002 0 4.999 0 2.564 2.406 2.564 5.374c0 1.946 1.049 3.647 2.618 4.595C2.156 11.096 0 13.989 0 17.369c0 .348.289.631.638.631a.633.633 0 0 0 .638-.63c0-3.55 2.827-6.457 6.374-6.638h.007a6.612 6.612 0 0 1 .69 0h.007c3.543.18 6.37 3.089 6.37 6.637 0 .348.284.631.638.631a.635.635 0 0 0 .638-.63c0-3.38-2.156-6.272-5.182-7.401zM8 9.483c-.108 0-.217-.004-.325-.016C5.53 9.307 3.84 7.53 3.84 5.374c0-2.267 1.866-4.11 4.16-4.11 2.29 0 4.157 1.843 4.157 4.11 0 2.157-1.69 3.93-3.83 4.093-.11.014-.22.016-.327.016z" />
                    </svg>
                    { this.props.members.results.length > 0 && this.props.members.results[0].Membership.name }
                </a>
            </li>
            <li className="dropdown-item">
                <a className="dropdown-link logout" href="/minarsidur/utskraning?next=/">Útskrá
                    <svg className="logout-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 16">
                        <path d="M18.3 8l-4.8 4.8-.7-.7 3.6-3.6H7v-1h9.4L12.8 4l.7-.7z" />
                        <path d="M9 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h7c1.1 0 2-.9 2-2v-3.3h-1V14c0 .5-.5 1-1 1H2c-.5 0-1-.5-1-1V2c0-.5.5-1 1-1h7c.5 0 1 .5 1 1v3h1V2c0-1.1-.9-2-2-2zm1 8.5h1v-1h-1v1z" />
                    </svg>
                </a>
            </li>
          </>
        )
    }
}


function mapStateToProps(state) {

  var f = {
      members: {},
      apartments: {},
  }
  f.members = (state.members === undefined) ? {} : state.members
  f.apartments = (state.apartments === undefined) ? {} : state.apartments
  return f;
}

export default connect(mapStateToProps)(DropdownMenu);