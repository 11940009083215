import React from 'react';
import { connect } from 'react-redux'
import { getPrettierDateFormat } from '../../utils';
import { SvgEditIcon } from '../svg'
import MyPaymentInformation from "../../components/my-payment-information";
import moment from 'moment';

const kennitala = require('kennitala');

class MyInformation extends React.Component {

    constructor(props) {
        super(props)

        let fullname = this.props.members[0].Membership.name.trim();
        let firstName = fullname.substr(0, fullname.lastIndexOf(' '));
        let lastName = fullname.substr(fullname.lastIndexOf(' ') + 1);

        let kt = kennitala.info(this.props.members[0].Membership.Kennitala)
        let plan = window.ASKELL_PLAN_ADULT
        if (kt["age"] < 18) {
            plan = window.ASKELL_PLAN_CHILD
        }

        this.state = {
            name: this.props.members[0].Membership.name,
            kennitala: this.props.members[0].Membership.Kennitala,
            mobile: this.props.members[0].Membership.phone === ("0000000" || "") ? "" : this.props.members[0].Membership.phone,
            email: this.props.members[0].Membership.email === ("no@fislausnir.com" || "") ? "" : this.props.members[0].Membership.email,
            address: this.props.members[0].Membership.address,
            postalCode: this.props.members[0].Membership.postal_code + " " + this.props.members[0].Membership.town,
            isChild: kt["age"] < 18 ? true : false,

            oldEmail: this.props.members[0].Membership.email,
            oldMobile: this.props.members[0].Membership.phone,
            oldAddress: this.props.members[0].Membership.address,
            oldPostalCode: this.props.members[0].Membership.postal_code + " " + this.props.members[0].Membership.town,

            validEmail: true,
            validMobile: true,

            emailUpdated: true,
            updatingEmail: false,
            mobileUpdated: true,
            updatingMobile: false,
            addressUpdated: true,
            updatingAddress: false,
            postalCodeUpdated: true,
            updatingPostalCode: false,
            password: "",
            confirmedPassword: "",
            isCardHolderValid: true,
            isValidUntilValid: true,
            isCardNumberValid: true,
            isCvcNumberValid: true,
            paymentInformation: {
                cardHolder: '',
                validUntil: '',
                cardNumber: '',
                cvc: '',
                extra: {
                    customer: {
                        customer_reference: this.props.members[0].Membership.Kennitala,
                        email: this.props.members[0].Membership.email,
                        first_name: firstName,
                        last_name: lastName,
                    },
                    subscriptions: [
                        {
                            plan: plan,
                            name: fullname,
                            kennitala: this.props.members[0].Membership.Kennitala,
                        }
                    ],
                }
            },
            pdfSigned: false,
            withdrawComplete: false,
        }
        this.isPaymentInformationValid = this.isPaymentInformationValid.bind(this);


    }

    componentDidMount() {
        $('.edit-icon').click(function () {
            $(this).prev('input').focus();
        })
        slideLabelsUp();
        updateValidUntil();
        window.addEventListener('message', this.messageHandler);

        $('#cardNumber').on('keypress change', function () {
            $(this).val(function (index, value) {
              return value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ').substr(0,19);
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.passwordUpdate !== this.props.passwordUpdate && this.props.passwordUpdate && !this.props.passwordUpdate.isFetching) {
            this.setState({
                passwordUpdated: this.props.passwordUpdate.results,
            })
            if (this.props.passwordUpdate.results == true) {
                this.setState({
                    password: "",
                    confirmedPassword: ""
                })
            }
        }
        if (!this.props.leiga && prevProps.creditCardUpdate.status !== this.props.creditCardUpdate.status && this.props.creditCardUpdate.status === "success") {
            this.setState({
                paymentInformation: {
                    cardHolder: '',
                    validUntil: '',
                    cardNumber: '',
                    cvc: '',
                },
            })
        }
    }


    validateEmail(value) {
        let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let isEmailValid = true;
        if (value) {
            isEmailValid = re.test(String(value).toLowerCase());
        } else {
            isEmailValid = re.test(String(this.state.email).toLowerCase());
        }

        this.setState({
            validEmail: isEmailValid
        })
    }

    validateMobileNumber(noLength) {
        if (noLength < 7) {
            this.setState({
                validMobile: false
            })
        } else {
            this.setState({
                validMobile: true
            })
        }
    }

    updateContactInformation() {
        if (this.state.oldEmail !== this.state.email || this.state.oldMobile !== this.state.mobile || this.state.oldAddress !== this.state.address || this.state.oldPostalCode !== this.state.postalCode) {
            if (this.state.oldEmail !== this.state.email) {
                this.validateEmail();
                this.setState({
                    updatingEmail: true,
                })
            }
            if (this.state.oldMobile !== this.state.mobile) {
                this.validateMobileNumber(this.state.mobile.length);
                this.setState({
                    updatingMobile: true,
                })
            }
            if (this.state.oldAddress !== this.state.address) {
                this.setState({
                    updatingAddress: true,
                })
            }
            if (this.state.oldPostalCode !== this.state.postalCode) {
                this.setState({
                    updatingPostalCode: true,
                })
            }

            let splitPostalCode = this.state.postalCode.split(" ")

            if (this.state.validEmail && this.state.validMobile) {
                fetch('/api/v1/minarsidur/', {
                    method: 'PATCH',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': window.csrf_token,
                    },
                    body: this.props.leiga === true ? JSON.stringify({ "email": this.state.email, "phone_no": this.state.mobile, address: this.state.address, "postal_code": splitPostalCode[0], "children": "" }) : JSON.stringify({ "email": this.state.email, "phone_no": this.state.mobile, address: this.state.address, "postal_code": splitPostalCode[0] }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            if (this.state.updatingEmail) {
                                this.setState({
                                    emailUpdated: true,
                                    oldEmail: this.state.email,
                                    updatingEmail: false,
                                })
                            }
                            if (this.state.updatingMobile) {
                                this.setState({
                                    mobileUpdated: true,
                                    oldMobile: this.state.mobile,
                                    updatingMobile: false,
                                })
                            }
                            if (this.state.updatingAddress) {
                                this.setState({
                                    addressUpdated: true,
                                    oldAddress: this.state.address,
                                    updatingAddress: false,
                                })
                            }
                            if (this.state.updatingPostalCode) {
                                this.setState({
                                    postalCodeUpdated: true,
                                    oldPostalCode: this.state.postalCode,
                                    updatingPostalCode: false,
                                })
                            }
                        } else {
                            if (this.state.updatingEmail) {
                                this.setState({
                                    emailUpdated: false,
                                    updatingEmail: false,
                                })
                            }
                            if (this.state.updatingMobile) {
                                this.setState({
                                    mobileUpdated: false,
                                    updatingMobile: false,
                                })
                            }
                            if (this.state.updatingAddress) {
                                this.setState({
                                    addressUpdated: false,
                                    updatingAddress: false,
                                })
                            }
                            if (this.state.updatingPostalCode) {
                                this.setState({
                                    postalCodeUpdated: false,
                                    updatingPostalCode: false,
                                })
                            }
                        }
                    })
                    .then(this.props.leiga === false && this.state.validEmail && this.state.oldEmail !== this.state.email && this.props.updateEmailAskell(this.state.email));
            }
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        if (name === 'email') {
            this.validateEmail(value);
        }
        if (name === 'mobile') {
            this.validateMobileNumber(value.length);
        }

        this.setState({
            [name]: value
        })
    }

    focusOnInput(id) {
        document.getElementById(id).select();
    }

    updatePassword() {
        let leiga = this.props.leiga;
        
        this.props.updatePassword(this.state.password, leiga)
    }

    updatePaymentInformation() {
        Promise.resolve(this.props.updatePaymentInformation(this.state.paymentInformation))
        .then(() => {
            this.props.updateDisplay('iframe')
        })
    }


    isPasswordValid() {
        if (this.state.password !== "" && this.state.confirmedPassword !== "" && this.state.password.length >= 6 && this.state.confirmedPassword.length >= 6) {
            return true
        } else {
            return false
        }
    }

    isPaymentInformationValid() {
        return this.state.paymentInformation.cardNumber.length > 10 && this.state.paymentInformation.cardHolder.length > 3 && this.state.paymentInformation.validUntil.length === 5 && this.state.paymentInformation.cvc.length >= 3
    }

    shouldDisplayPwMsg() {
        if (this.state.passwordUpdated == true) {
            return <div className="update success">Lykilorðinu hefur verið breytt.</div>
        } else if (this.state.passwordUpdated == false) {
            return <div className="update error">Ekki tókst að breyta lykilorðinu.</div>
        } else {
            return null
        }
    }

    handlePaymentInputChange(event) {
        const { name, value } = event.target;
        this.setState({
            paymentInformation: {
                ...this.state.paymentInformation,
                [name]: value
            }
        })

    }
    
    withdrawingComplete(){
        this.setState({
            withdrawComplete: !this.state.withdrawComplete
        })
    }

    
    render() {
        const { members, memberObject } = this.props;
        let member = this.props.members[0].Membership;
        let askell_data = this.props.members[0].askell_data;

        return (
            <>
            <div className="col-12 col-lg-7 my-information">
                <div className="row">
                    <h2 className="col-12 pl-0 pr-0">Mínar upplýsingar</h2>
                    {member.Númer &&
                        <div className="col-12 info-section">
                            <div className="row">
                                <div className="col-12 pl-0 pr-0 section-title">Félagsnúmer</div>
                                <div className="col-6 pl-0 large-nr">{member.Númer}</div>
                                {member.member_since ? <div className="col-6 pr-0 text-right partner-info">Félagi síðan {getPrettierDateFormat(member.member_since)}</div> : null}
                            </div>
                        </div>
                    }
                    <div className={member.Númer ? "col-12 info-section" : "col-12 info-section border-top"}>
                        <div className="row">
                            <div className="col-12 pl-0 pr-0 section-title">Samskipta upplýsingar</div>
                            <div className="col-12">
                                <form className="row" action="{% pageurl page %}" method="POST">
                                    <div className="col-12 col-md-5 form-group">
                                        <input type="email" name="email" maxLength="255" required="" id="email" value={this.state.email} onChange={this.handleChange.bind(this)} />
                                        <label htmlFor="email" className="col-form-label animate-label">Tölvupóstfang</label>
                                        <span className="edit-icon" onClick={() => this.focusOnInput('email')}><SvgEditIcon /></span>
                                        {!this.state.validEmail ? <div className="warning pl-0 col-12">Slá þarf inn gilt tölvupóstfang.</div> : null}
                                        {!this.state.emailUpdated ? <div className="warning pl-0 col-12">Ekki tókst að uppfæra tölvupóstfang.</div> : null}
                                    </div>
                                    <div className="col-12 col-md-5 form-group">
                                        <input type="text" name="address" maxLength="255" required="" id="address" value={this.state.address} onChange={this.handleChange.bind(this)} />
                                        <label htmlFor="address" className="col-form-label animate-label">Heimilisfang</label>
                                        <span className="edit-icon" onClick={() => this.focusOnInput('address')}><SvgEditIcon /></span>
                                        {!this.state.addressUpdated ? <div className="warning pl-0 col-12">Ekki tókst að uppfæra heimilisfang.</div> : null}
                                    </div>
                                    <div className="col-12 col-md-5 form-group">
                                        <input type="number" name="mobile" maxLength="255" required="" id="mobile" value={this.state.mobile} onChange={this.handleChange.bind(this)} />
                                        <label htmlFor="mobile" className="col-form-label animate-label">Farsími</label>
                                        <span className="edit-icon" onClick={() => this.focusOnInput('mobile')}><SvgEditIcon /></span>
                                        {!this.state.validMobile ? <div className="warning pl-0 col-12">Slá þarf inn gilt símanúmer.</div> : null}
                                        {!this.state.mobileUpdated ? <div className="warning pl-0 col-12">Ekki tókst að uppfæra símanúmer.</div> : null}
                                    </div>
                                    <div className="col-12 col-md-5 form-group">
                                        <input type="text" name="postalCode" maxLength="255" required="" id="postalCode" value={this.state.postalCode} onChange={this.handleChange.bind(this)} />
                                        <label htmlFor="postalCode" className="col-form-label animate-label">Póstnúmer</label>
                                        <span className="edit-icon" onClick={() => this.focusOnInput('postalCode')}><SvgEditIcon /></span>
                                        {!this.state.postalCodeUpdated ? <div className="warning pl-0 col-12">Ekki tókst að uppfæra póstnúmer og stað.</div> : null}
                                    </div>
                                    <div className="col-12 text-center">
                                        <a className={this.state.oldEmail !== this.state.email || this.state.oldMobile !== this.state.mobile || this.state.oldAddress !== this.state.address || this.state.oldPostalCode !== this.state.postalCode ? "btn-primary btn-red" : "btn-primary btn-red disabled"} onClick={this.updateContactInformation.bind(this)}>Vista</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 new-password-section">
                        <div className="row">
                            <div className="col-12 pl-0 pr-0 section-title">Breyta lykilorði</div>
                            <div className="col-12 pl-0 pr-0 subtitle">Lykilorðið þarf að vera a.m.k. sex stafir að lengd.</div>
                            {this.shouldDisplayPwMsg()}
                            <div className="col-12 password-wrapper">
                                <form className="row" action="{% pageurl page %}" method="POST">
                                    <div className="col-12 col-md-5 form-group">
                                        <input type="password" name="password" maxLength="255" required="" id="password" value={this.state.password} onChange={this.handleChange.bind(this)} />
                                        <label htmlFor="password" className="col-form-label animate-label">Nýtt lykilorð</label>
                                    </div>
                                    <div className="col-12 col-md-5 form-group">
                                        <input type="password" name="confirmedPassword" maxLength="255" required="" id="confirmedPassword" value={this.state.confirmedPassword} onChange={this.handleChange.bind(this)} />
                                        <label htmlFor="confirmedPassword" className="col-form-label animate-label">Staðfesta lykilorð</label>
                                    </div>
                                    <div className="col-12 text-center">
                                        <a className={this.state.password === this.state.confirmedPassword && this.isPasswordValid() ? "btn-primary btn-red" : "btn-primary btn-red disabled"} onClick={this.updatePassword.bind(this)}>Breyta lykilorði</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {!this.props.leiga && !this.state.isChild && 
                        <div className="col-12 info-section">
                            <div className="row">
                                <div className="col-12 pl-0 pr-0 section-title">Greiðsluupplýsingar fyrir félagsaðild</div>
                                <div className="col-12 pl-0 pr-0 subtitle">Ef þú hefur áður verið með greiðsluseðil mun stofnast ný reglubundin greiðsla sem endurnýjast árlega þar til þú hættir í félaginu.</div>
                                {this.props.creditCardUpdate.isFetching &&
                                    <div class="alert alert-info">Vista upplýsingar, augnablik</div>
                                }
                                {this.props.creditCardUpdate.status === "error" && !this.props.creditCardUpdate.isFetching &&
                                    <div class="alert alert-danger">Villa kom upp við að vista greiðslukort. {this.props.creditCardUpdate.errorMsg}</div>
                                }


                                <div className="col-12">
                                    { this.props.display === 'cardform' &&

                                        <form className="row" action="{% pageurl page %}" method="POST">
                                            {!this.state.formvalid ? <div className="pl-0 validation-warning col-12">Fylla verður rétt út í alla reiti í greiðsluforminu.</div> : null}
                                            {this.state.responseStatus === "error" ? <div className="validation-warning col-12">{this.state.responseMessage}</div> : null}
                                            <div className="col-12 col-md-7 form-group">
                                                <input
                                                    id="cardHolder"
                                                    name="cardHolder"
                                                    type="text"
                                                    value={this.state.paymentInformation.cardHolder} // Prop: The kennitala input data
                                                    onChange={this.handlePaymentInputChange.bind(this)}
                                                    required // Prop: Puts data into state

                                                />
                                                <label className="col-form-label animate-label" htmlFor="cardHolder">Korthafi</label>
                                                <span className="edit-icon" onClick={() => this.focusOnInput('cardHolder')}><SvgEditIcon /></span>
                                                {!this.state.isCardHolderValid ? <div className="validation-warning pl-0 col-12">Slá þarf inn fullt nafn.</div> : null}
                                            </div>
                                            <div className="col-12 col-md-4 form-group">
                                                <input
                                                    id="validUntil"
                                                    name="validUntil"
                                                    type="text"
                                                    maxLength="5"
                                                    value={this.state.paymentInformation.validUntil} // Prop: The kennitala input data
                                                    onChange={this.handlePaymentInputChange.bind(this)}
                                                    required // Prop: Puts data into state
                                                />
                                                <label className="col-form-label animate-label" htmlFor="validUntil">Gildistími (MM/ÁÁ)</label>
                                                <span className="edit-icon" onClick={() => this.focusOnInput('validUntil')}><SvgEditIcon /></span>
                                                {!this.state.isValidUntilValid ? <div className="validation-warning pl-0 col-12">Gildistími skal vera á forminu MM/ÁÁ.</div> : null}

                                            </div>
                                            <div className="col-12 col-md-7 form-group">
                                                <input
                                                    id="cardNumber"
                                                    name="cardNumber"
                                                    type="text"
                                                    maxLength="19"
                                                    value={this.state.paymentInformation.cardNumber} // Prop: The kennitala input data
                                                    onChange={this.handlePaymentInputChange.bind(this)}
                                                    required // Prop: Puts data into state
                                                />
                                                <label className="col-form-label animate-label" htmlFor="cardNumber">Kortanúmer</label>
                                                <span className="edit-icon" onClick={() => this.focusOnInput('cardNumber')}><SvgEditIcon /></span>
                                                {!this.state.isCardNumberValid ? <div className="validation-warning pl-0 col-12">Slá þarf inn gilt kortanúmer.</div> : null}
                                            </div>
                                            <div className="form-group col-3 col-md-2">
                                                <input
                                                    id="cvc"
                                                    name="cvc"
                                                    type="number"
                                                    value={this.state.paymentInformation.cvc} // Prop: The kennitala input data
                                                    onChange={this.handlePaymentInputChange.bind(this)}
                                                    required // Prop: Puts data into state
                                                />
                                                <label className="col-form-label animate-label" htmlFor="cvc">CVC</label>
                                                <span className="edit-icon" onClick={() => this.focusOnInput('cvc')}><SvgEditIcon /></span>
                                                {!this.state.isCvcNumberValid ? <div className="validation-warning pl-0 col-12">CVC of stutt.</div> : null}
                                            </div>
                                            { this.props.members && this.props.members[0].askell_customer &&
                                                <div className="col-12 pl-0 pr-0 subtitle"><b>Eftirfarandi kort er skráð núna:</b> {this.props.members[0].askell_customer.payment_method[0].display_info} rennur út { moment(this.props.members[0].askell_customer.payment_method[0].valid_until).format('DD.MM.YYYY')}</div>
                                            }
                                            
                                            <div className="col-12 text-center">
                                                {!this.props.creditCardUpdate.isFetching ?
                                                    <a className={this.isPaymentInformationValid() ? "btn-primary btn-red" : "btn-primary btn-red disabled"} onClick={this.updatePaymentInformation.bind(this)}>Vista</a>
                                                    :
                                                    <a className={"btn-primary btn-red disabled"}>Vista<div className="spinner-border"></div></a>
                                                }
                                            </div>
                                        </form>
                                    }

                                    { this.props.display === 'iframe' &&
                                        <div className="row justify-content-center payment-iframe-container">
                                        <div className="col-xl-8 col-md-12 col-16 title">
                                            <iframe src={memberObject.cardVerificationUrl} />
                                        </div>
                                        </div>
                                    }
                                    { this.props.display === 'done' &&
                                        <div class="alert alert-success">Nýjar greiðsluupplýsingar hafa verið vistaðar</div>
                                    }
                                </div>
                            </div>
                            { askell_data && askell_data.filter((f) => f.active).length > 0 &&
                                <div className="row">
                                    <div className="col-12 pl-0 pr-0 section-title">Reglubundnar greiðslur</div>
                                    <div className="col-12 pl-0 pr-0 subtitle">Listi yfir þær reglubundnu félagsaðildir sem þú ert skráð/ur fyrir.</div>

                                    <div className="col-12">
                                        <ul>
                                        {askell_data.filter((f) => f.active).map((subscription => {
                                            return (
                                                <li>
                                                    {subscription.plan.name} - {subscription.reference}<br />
                                                    Næsta endurnýjun {moment(subscription.active_until).format('DD.MM.YYYY')}<br />
                                                    Upphæð {parseInt(subscription.plan.amount)} kr.
                                                </li>
                                            )
                                        }))}
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {/* {!member.member_status !== 'Ekki í fél.' && 
                    <div className="col-12">
                        <a className="btn-primary btn-red"> Segja upp félagsaðild</a>
                      </div>
                      } */}
                </div>
            </div>
            
            </>
        )
    }
}
MyInformation.defaultProps = {
    display: 'cardform'
}

function mapStateToProps(state) {

    var f = {
        // members: {},
    }
    // f.members = (state.members === undefined) ? {} : state.members
    return f;
}

export default connect(mapStateToProps)(MyInformation);