import React from 'react';
import EmailSignupModal from "../email-signup-modal";


class Step3 extends React.Component {

    getChildMemberSubscriptions() {

        const memberChildren = this.props.allChildren.map((child) => {
            if (child.membership === true) {
                return (
                    <div className="member-info col-12 float-left text-left">{child.name}</div>
                )
            }
        })
        return memberChildren;
    }

    getMainSubscription() {
        if (this.props.mainIsAdult) {
            if (this.props.membershipRequest) {
                return(<div className="member-info col-12 float-left text-left">{this.props.mainName}</div>)
             } else {
                 return null
             }
        } else {
            return(<div className="member-info col-12 float-left text-left">{this.props.singleChildName}</div>)
        }
    }

    render() {
        if (this.props.currentStep !== 3) { // Prop: The current step
            return null
        }



        return (<>
            <div className="member-summary col-11">
                <div className="row">
                    <div className="col-12 confirmation-text">
                        <span>
                            Þú ert nú félagsmaður í Búseta!<br/>
                            Til hamingju með félagsaðild þína<br/>
                        </span>
                        <div className="buseti-info">
                            Takk fyrir að sækja um aðild að Búseta og um leið gerast félagsmaður. <br/>
                            Sem félagsmaður gefst þér nú tækifæri á að sækja um  <a href="/ibudir/#lausar-ibudir">lausar íbúðir</a> sem eru til úthlutunar annan fimmtudag hvers mánaðar í gegnum <a href="/minarsidur/">Minn Búseti</a>.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="member-info-label col-12 float-left text-left">Félagsmaður</div>
                    {this.getMainSubscription()}
                    {this.getChildMemberSubscriptions()}
                </div>
            </div>
            <div className="payment-information-summary col-11">
                <div className="row">
                    <div className="member-info-label col-6 float-left text-left d-none d-md-block">Greiðandi</div>
                    <div className="member-info-label col-6 float-right text-right d-none d-md-block">Kortanúmer</div>
                    <div className="member-info col-6 float-left text-left d-none d-md-block">{this.props.receiptInformation.cardHolder}</div>
                    <div className="member-info col-6 float-right text-right d-none d-md-block">{this.props.receiptInformation.cardNumber}</div>

                    <div className="member-info-label col-12 float-left text-left d-md-none">Greiðandi</div>
                    <div className="member-info col-12 float-left text-left d-md-none">{this.props.receiptInformation.cardHolder}</div>
                    <div className="member-info-label col-12 float-left text-left d-md-none">Kortanúmer</div>
                    <div className="member-info col-12 float-left text-left d-md-none">{this.props.receiptInformation.cardNumber}</div>

                    <div className="member-info-label col-12 float-left text-left">Kvittun var send á</div>
                    <div className="member-info col-12 float-left text-left">{this.props.receiptInformation.email}</div>
                    {/* <div className="col-12 continue-button-wrapper text-center">
                        <button
                            className="btn-primary btn-red"
                            type="button" data-toggle="modal" data-target="#ReceiptStepEmailSignupModal">
                            Skráðu þig á póstlista Búseta
                        </button>
                    </div>
                    <EmailSignupModal id="ReceiptStepEmailSignupModal" modalId={"ReceiptStepEmailSignupModal"} /> */}
                </div>
                
            </div>
        </>
        )
    }
}

Step3.defaultProps = {
}


export default Step3;