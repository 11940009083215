import React from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import ApplicationRow from "../application-row";
import { sortApplications } from '../../api/members/actions'


const DragHandle = sortableHandle(() => <div className="sort-handle">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20">
        <path d="M 16 2 L 12 6 L 15 6 L 15 10 L 3 10 L 3 12 L 29 12 L 29 10 L 17 10 L 17 6 L 20 6 L 16 2 z M 3 15 L 3 17 L 29 17 L 29 15 L 3 15 z M 3 20.013672 L 3 22.013672 L 15 22.013672 L 15 26 L 12 26 L 16 30 L 20 26 L 17 26 L 17 22.013672 L 29 22.013672 L 29 20.013672 L 3 20.013672 z"/>
    </svg>
</div>);

const SortableItem = sortableElement(({ application, applicationWindowOpen, index, item_index }) => {
    if (application) {
        return (
                <li>
                    {applicationWindowOpen ? <DragHandle /> : null}
                    <ApplicationRow application={application} key={index} index={index} item_index={item_index} applicationWindowOpen={applicationWindowOpen} />
                </li>
                )
    } else { 
        return null
    }
});

const SortableList = sortableContainer(({ applications, applicationWindowOpen }) => {
    if (applications) {
        return (
            <div className="row">
                <ul className="pl-0 w-100">
                    {applications.map((application, index) => {
                        return (<SortableItem key={`item-${index}`} index={index} item_index={index} application={application} applicationWindowOpen={applicationWindowOpen}/>)
                    })}
                </ul>
            </div>
        );
    } else {
        return null
    }

});



class SortableApplications extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            items: "",
            applications: this.props.applications,
            bla: "",
            applicationOrder: this.props.applicationOrder,
        }
    }
    componentDidMount() {
        if (this.props.applications) {
            this.setState({
                items: this.props.applications.map((application, key) => ('Item ' + key))

            })
        }
    }

    onSortEnd({ oldIndex, newIndex }) {
        let applicationOrder = this.state.applicationOrder;
        this.props.store.dispatch(sortApplications(oldIndex, newIndex, applicationOrder))
    };
    render() {
        return (
            this.state.items.length > 0 ?
                <SortableList useDragHandle items={this.state.items} applications={this.state.applications} onSortEnd={this.onSortEnd.bind(this)} applicationWindowOpen={this.props.applicationWindow} />
                : null
        );
    }
}

SortableApplications.defaultProps = {
}
export default SortableApplications;