import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const promotionPagesTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'PAGES');

export const requestPromotionPages = () => {
    return {
        type: promotionPagesTypes.REQUEST,
    };
};

export const receivePromotionPages = (response, query={}) => {
    return {
        type: promotionPagesTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receivePromotionPagesError = () => {
    return {
        type: promotionPagesTypes.ERROR,
    };
};

export const fetchPromotionPages = () => {
    return dispatch => {
        dispatch(requestPromotionPages());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        

        return fetch(`/api/v2/pages/?type=buseti_cms.ApartmentsListPage&fields=kynningarvefur`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receivePromotionPages(response));
                } catch (e) {
                    dispatch(receivePromotionPagesError(response))
                }


            })
            .catch(handleNetworkError);
    }
}