import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const mailinglistTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'MAILING_LIST');


export const requestMailingListSignup = () => {
    return {
        type: mailinglistTypes.REQUEST,
    };
};

export const receiveMailingListSignup = (response, query={}) => {
    return {
        type: mailinglistTypes.RECEIVE,
        // query: query,
        data: response,
    };
};

export const receiveMailingListSignupError = () => {
    return {
        type: mailinglistTypes.ERROR,
    };
};


export const mailingListSignup = (email, lists) => {
    return dispatch => {
        dispatch(requestMailingListSignup())
        const apiUrl = "/api/v1/postlisti/"

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }

        return fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ "email": email, "postlistar": lists }),

        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveMailingListSignup(response));
                } catch (e) {
                    dispatch(receiveMailingListSignupError(response))
                }


            })
            .catch(handleNetworkError);
    };
};

