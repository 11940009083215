import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const apartmentTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'IBUDIR');
export const apartmentMapTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'IBUDIR_HNIT');


export const requestApartments = () => {
    return {
        type: apartmentTypes.REQUEST,
    };
};

export const receiveApartments = (response, status, query={}) => {
    return {
        type: apartmentTypes.RECEIVE,
        query: query,
        data: response,
        status, status,
    };
};

export const receiveApartmentsError = (status) => {
    return {
        type: apartmentTypes.ERROR,
        status: status,
    };
};

export const requestMapApartments = () => {
    return {
        type: apartmentMapTypes.REQUEST,
    };
};

export const receiveMapApartments = (response, query={}) => {
    return {
        type: apartmentMapTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveMapApartmentsError = () => {
    return {
        type: apartmentMapTypes.ERROR,
    };
};

export const fetchApartments = (apartmentId) => {
    return dispatch => {
        const apiUrl = "/api/v1/ibudir/"

        dispatch(requestApartments());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        return fetch((apartmentId === undefined) ? apiUrl : apiUrl+`${apartmentId}`, {
            headers: headers
        })
        .then(response => {
            var status = response.status;
            if (status == 200){
                return Promise.resolve(response.json())
                    .then(data => {
                        dispatch(receiveApartments(data, status, apartmentId));
                    })
            } else {
                dispatch(receiveApartmentsError(status));
            }   
        })
        .catch(handleNetworkError);
    };
};


export const fetchEmptyApartments = (lfb, overwriteQuery, limit) => {
    return dispatch => {
        let apiUrl = "/api/v1/ibudir/?laust=true"
        if (overwriteQuery !== "" && overwriteQuery !== "None") 
        {
            apiUrl = "/api/v1/ibudir/?laust=true&" + `${overwriteQuery}`
        }
        if (limit !== undefined){
            apiUrl += "&limit="+limit
        }

        if (lfb === "True"){
            apiUrl += "&lfb=true"
        }

        dispatch(requestApartments());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        return fetch(apiUrl, {
            headers: headers
        })
            .then(response => {
                var status = response.status;
                if (status == 200){
                    return Promise.resolve(response.json())
                        .then(data => {
                            dispatch(receiveApartments(data, status, overwriteQuery));
                        })
                } else {
                    dispatch(receiveApartmentsError(status));
                }   
            })
            .catch(handleNetworkError);
    };
};

export const fetchMapApartments = (apartmentId) => {
    return dispatch => {
        const apiUrl = "/api/v1/ibudir/"

        dispatch(requestMapApartments());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        return fetch((apartmentId === undefined) ? apiUrl : apiUrl+`${apartmentId}`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveMapApartments(response, apartmentId));
                } catch (e) {
                    dispatch(receiveMapApartmentsError(response))
                }


            })
            .catch(handleNetworkError);
    };
};