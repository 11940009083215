import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

import { fetchMapApartments } from '../../api/apartments/actions'
import { connect } from "react-redux";

export class GoogleMapContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
        };
    }
    componentDidMount() {
        if (this.props.apartmentId === undefined || !this.props.apartmentId) {
            this.props.store.dispatch(fetchMapApartments())
        } else {
            this.props.store.dispatch(fetchMapApartments(this.props.apartmentId))
        }
    }

    onMarkerClick(props, marker, e) {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    onClose() {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    onMapClicked() {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    render() {
        let apartments = { ...this.props.mapApartments.results };
        const allCoordinates = [];
        if (apartments && Object.keys(apartments).length > 0) {
            if (this.props.singleCoordinate === false) {
                Object.values(apartments).map((apartment) => {
                    if (apartment.hnit && apartment.hnit !== null) {
                        let splitCoordinate = apartment.hnit.split(", ");
                        let lat = parseFloat(splitCoordinate[0]);
                        let lon = parseFloat(splitCoordinate[1]);
                        let address = apartment.heimilisfang + ", " + apartment.postnumer + " " + apartment.sveitarfelag
                        allCoordinates.push({ "address": address, "lat": lat, "lng": lon })
                    }
                    return allCoordinates;
                })

                var mapCenter = { lat: 64.128288, lng: -21.827774 };
                var bounds = new this.props.google.maps.LatLngBounds();
                for (var i = 0; i < allCoordinates.length; i++) {
                    bounds.extend(allCoordinates[i]);
                }
            } else {
                if (apartments.hnit !== null) {
                    let splitCoordinate = apartments.hnit.split(", ");
                    let lat = parseFloat(splitCoordinate[0]);
                    let lon = parseFloat(splitCoordinate[1]);
                    let address = apartments.heimilisfang + ", " + apartments.postnumer + " " + apartments.sveitarfelag;
                    allCoordinates.push({ "address": address, "lat": lat, "lng": lon })
                    var mapCenter = { lat: lat, lng: lon };
                }
            }
        }
        return (
            <Map
                google={this.props.google}
                zoom={13}
                initialCenter={{
                    lat: 64.128288,
                    lng: -21.827774
                }}
                defaultCenter={mapCenter}
                center={mapCenter}
                bounds={bounds}
                onClick={this.onMapClicked.bind(this)}
                centerAroundCurrentLocation={false}
                scrollwheel={false}
                styles={[{ "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e9e9e9" }, { "lightness": 17 }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 20 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }, { "lightness": 17 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 }] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 18 }] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 16 }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 21 }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#dedede" }, { "lightness": 21 }] }, { "elementType": "labels.text.stroke", "stylers": [{ "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 }] }, { "elementType": "labels.text.fill", "stylers": [{ "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "geometry", "stylers": [{ "color": "#f2f2f2" }, { "lightness": 19 }] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#fefefe" }, { "lightness": 20 }] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 }] }]}

            >
                {allCoordinates.map((coordinate, index) => (
                    <Marker
                        onClick={this.onMarkerClick.bind(this)}
                        name={coordinate.address}
                        position={{ lat: coordinate.lat, lng: coordinate.lng }}
                        key={index}
                        icon={{
                            url: this.props.leiga === "True" ? window.images.lfbPin : window.images.busetiPin,
                            // anchor: new google.maps.Point(32,32),
                            // scaledSize: new google.maps.Size(64,64)
                        }}
                    />
                ))}
                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}
                    onClose={this.onClose.bind(this)}
                >
                    <div>
                        <h4>{this.state.selectedPlace.name}</h4>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}
function mapStateToProps(state) {

    var f = {
        mapApartments: {},
    }
    f.mapApartments = (state.mapApartments === undefined) ? {} : state.mapApartments
    return f;
}


const GoogleMapApiContainer = GoogleApiWrapper({
    apiKey: 'AIzaSyACg0aRncts6zeIW4A-hqmacfqpL3efjuA'
})(GoogleMapContainer);

export default connect(mapStateToProps)(GoogleMapApiContainer);
