import React from 'react';
import ReactDOM from 'react-dom'

class EmailSignupModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            email: "",
            emailList1: false,
            emailList2: false,
            signupMessage: "Skráðu þig á póstlista og fáðu reglulega upplýsingar um lausar íbúðir.",
            signupCode: "",
            noEmailLists: false,
            '1-2rooms': false,
            '3rooms': false,
            '4rooms': false,
            '5-6rooms': false,
        }
    }


    onChange(key, event) {
        if (key === 'email') {
            this.setState({
                email: event.currentTarget.value
            })
            if (this.props.modalId === "ApartmentEmailSignupModalLower" || this.props.modalId === "ApartmentEmailSignupModal") {
                this.props.sendData(event.currentTarget.value);
            }
        } else {
            this.setState({
                [key]: event.currentTarget.checked
            })
        }
    }
    getEmailValues(email, signupMessage) {
        this.props.sendData(email, signupMessage);
    }

    handleSubmit() {
        if (!this.state.emailList1 && !this.state.emailList2) {
            this.setState({
                noEmailLists: true,
            });
        } else {
            this.setState({
                noEmailLists: false
            })
            fetch('/api/v1/postlisti/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': window.csrf_token,
                },
                body: JSON.stringify({ "email": this.state.email, "postlistar": [this.state.emailList1 ? "1" : "", this.state.emailList2 ? "2" : ""] }),
            }).then(response => response.json())
                .then(data => this.setState({ signupMessage: data.message }))
                .then(data => this.getEmailValues(this.state.email, this.state.signupMessage));
        }
    }

    handleLowerSubmit(e) {
        e.stopPropagation();
        e.preventDefault();
        if (this.props.modalId === "LFBEmailSignupModal") {
            let tags = [this.state['1-2rooms'] ? "1" : "", this.state['3rooms'] ? "2" : "", this.state['4rooms'] ? "3" : "", this.state['5-6rooms'] ? "4" : ""]
            fetch('/api/v1/lfb_postlisti/', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': window.csrf_token,
              },
              body: JSON.stringify({ "email": this.state.email, "tags": tags }),
            }).then(response => response.json())
              .then(data => {
                if (data.code === 200) {
                  this.setState({
                    name: '',
                    email: '',
                    '1-2rooms': false,
                    '3rooms': false,
                    '4rooms': false,
                    '5-6rooms': false,
                    signupMessage: data.message,
                    signupCode: data.code,
                  })
                } else {
                  this.setState({ signupMessage: data.message, signupCode: data.code })
                }
        
              })
        } else {
            if (!this.state.emailList1 && !this.state.emailList2) {
                this.setState({
                    noEmailLists: true
                })
            } else {
                this.setState({
                    noEmailLists: false
                })
                fetch('/api/v1/postlisti/', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': window.csrf_token,
                    },
                    body: JSON.stringify({ "email": this.state.email, "postlistar": [this.state.emailList1 ? "1" : "", this.state.emailList2 ? "2" : ""] }),
                }).then(response => response.json())
                    .then(data => this.setState({ signupMessage: data.message, signupCode: data.code }));
            }
        }
    }
    getCorrectEmailModalContent(modalId) {
        if (modalId === "LFBEmailSignupModal") {
            return (
                <form id="lfb-email-signup-form-footer">
                    {(this.state.signupMessage && this.state.signupCode !== "") && <div className="warning">{this.state.signupMessage}</div>}
                    <input type="email" id="email" placeholder="Tölvupóstfang" onChange={this.onChange.bind(this, 'email')} value={this.state.email}></input>
                    <div id="checkbox1">
                        <input type="checkbox" name="1-2rooms" checked={this.state['1-2rooms']} onChange={this.onChange.bind(this,'1-2rooms')} />
                        <span id="checkmark1"></span>
                        <label htmlFor="1-2rooms">1-2ja herbergja</label>
                    </div>
                    <div id="checkbox2">
                        <input type="checkbox" name="3rooms" checked={this.state['3rooms']} onChange={this.onChange.bind(this,'3rooms')} />
                        <span id="checkmark2"></span>
                        <label htmlFor="3rooms">3ja herbergja</label>
                    </div>
                    <div id="checkbox3">
                        <input type="checkbox" name="4rooms" checked={this.state['4rooms']} onChange={this.onChange.bind(this,'4rooms')} />
                        <span id="checkmark3"></span>
                        <label htmlFor="4rooms">4ra herbergja</label>
                    </div>
                    <div id="checkbox4">
                        <input type="checkbox" name="5-6rooms" checked={this.state['5-6rooms']} onChange={this.onChange.bind(this,'5-6rooms')} />
                        <span id="checkmark4"></span>
                        <label htmlFor="5-6rooms">5-6 herbergja</label>
                    </div>
                    <div className="button-wrapper">
                        <input type="submit" className="btn-primary btn-red" value="Skrá mig" onClick={e => this.handleLowerSubmit(e)}></input>
                    </div>
                </form>
            )
        } else {
            if (this.state.signupCode === 200) {
                return (
                    <div className="signup-success">Netfangið hefur verið skráð á póstlista {modalId === "LFBEmailSignupModal" && "Leigufélags "}Búseta.</div>
                )
            } else {
                return (<form id="email-signup-form-footer">
                    {this.state.signupCode === "403" ? <div className="warning">Netfangið er þegar skráð á póstlistann.</div> : null}
                    {this.state.noEmailLists ? <div className="warning">ATH - Velja þarf a.m.k. annan hvorn póstlistann.</div> : null}
                    <input type="email" id="email" placeholder="Tölvupóstfang" onChange={this.onChange.bind(this, 'email')} value={this.state.email}></input>
                    <div id="checkbox1">
                        <input id="available-apartments" type="checkbox" onChange={this.onChange.bind(this, 'emailList1')} checked={this.state.emailList1} />
                        <span id="checkmark1"></span>
                        <label htmlFor="available-apartments">Lausar íbúðir</label>
                    </div>
                    <div id="checkbox2">
                        <input id="newsletter" type="checkbox" onChange={this.onChange.bind(this, 'emailList2')} checked={this.state.emailList2} />
                        <span id="checkmark2"></span>
                        <label htmlFor="newsletter">Fréttir og fróðleikur frá Búseta</label>
                    </div>
                    <div className="button-wrapper">
                        <input type="submit" className="btn-primary btn-red" value="Skrá mig" onClick={e => this.handleLowerSubmit(e)}></input>
                    </div>
                </form>)
            }
        }
    }


    render() {
        return (
            <div className="modal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true" id={this.props.modalId}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="sign-up">Skráðu þig á póstlista og fáðu reglulega upplýsingar um lausar íbúðir.</div>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body">
                            {this.props.modalId === "ApartmentEmailSignupModalLower" || this.props.modalId === "ApartmentEmailSignupModal" || this.props.modalId === "ReceiptStepEmailSignupModal" ?
                                <form id="email-signup-form-footer">
                                    {this.state.noEmailLists ? <div className="warning">Velja þarf a.m.k. annan hvorn póstlistann</div> : null}
                                    <input type="email" id="email" placeholder="Tölvupóstfang" onChange={this.onChange.bind(this, 'email')} value={this.state.email}></input>
                                    <div id="checkbox1">
                                        <input id="available-apartments" type="checkbox" onChange={this.onChange.bind(this, 'emailList1')} checked={this.state.emailList1} />
                                        <span id="checkmark1"></span>
                                        <label htmlFor="available-apartments">Lausar íbúðir</label>
                                    </div>
                                    <div id="checkbox2">
                                        <input id="newsletter" type="checkbox" onChange={this.onChange.bind(this, 'emailList2')} checked={this.state.emailList2} />
                                        <span id="checkmark2"></span>
                                        <label htmlFor="newsletter">Fréttir og fróðleikur frá Búseta</label>
                                    </div>

                                    <div className="button-wrapper">
                                        <input type="submit" className="btn-primary btn-red" value="Skrá mig" onClick={this.handleSubmit.bind(this)} data-dismiss="modal"></input>
                                    </div>
                                </form> :
                                this.getCorrectEmailModalContent(this.props.modalId)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


EmailSignupModal.defaultProps = {
}

$.fn.emailSignupModal = function () {
    return this.each(function () {
        var options = $(this).data();
        ReactDOM.render(React.createElement(EmailSignupModal, options), this);
    });
};

$(document).ready(function () {
    $('[data-emailform-container]').emailSignupModal();
});


export default EmailSignupModal;