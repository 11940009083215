import React from 'react';
import { store } from '../../store'
import { createLFBUser } from '../../api/lfbSignup/actions'
import { fetchMemberInfo } from '../../api/members/actions'
const kennitala = require('kennitala')

class LfbFinishSignup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
          email: '',
          ssn: '',
          errors: {
            email: false,
            ssn: false
          }
        };

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.validate = this.validate.bind(this)
    }

    handleChange(event){
      this.setState({ [event.target.id] : event.target.value });
    }

    handleSubmit(){
      if(this.validate(this.state.ssn, this.state.email)){
        store.dispatch(createLFBUser(this.state.ssn, this.state.email))
          .then(() => {
            setTimeout(() => {
              store.dispatch(fetchMemberInfo(true))
            }, 500).then(() => window.location.reload(true))
          })
      }
    }

    validate(ssn, email){
      let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let validSsn = kennitala.isValid(ssn) && kennitala.isPerson(ssn)
      let validEmail = re.test(String(email).toLowerCase());

      this.setState({
        errors: {
            ...this.state.errors,
            ssn: !validSsn,
            email: !validEmail
        }
      })

      return validSsn && validEmail
    }

    render() {
        return ( 
          <>
            <div>Enginn notandi með þessa kennitölu. <br/> Vinsamlegast klárið nýskráningu með því að fylla út upplýsingarnar hér að neðan áður en haldið er áfram.</div>
            <form className="row" onSubmit={this.handleSubmit}>
              <input type="text" id="ssn" placeholder="Kennitala" value={this.state.ssn} onChange={this.handleChange}></input>
              {this.state.errors.ssn &&
                <div className="warning pl-0 col-12"> Kennitala ekki gild </div>
              }
              <input type="email" id="email" placeholder= "Netfang" value={this.state.email} onChange={this.handleChange}></input>
              {this.state.errors.email &&
                <div className="warning pl-0 col-12"> Netfang ekki gilt </div>
              }
              <input type="submit" value="Klára skráningu"></input>
            </form>
            {/* <a href="/minarsidur/utskraning?next=/" className="align-text-bottom">Útskráning</a> */}
          </>
        )
    }
}

LfbFinishSignup.defaultProps = {}


export default LfbFinishSignup;
