import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const pdfTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'PDF');
export const subscriptionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'SUBSCRIPTION');

export const requestPDF = () => {
    return {
        type: pdfTypes.REQUEST,
    };
};

export const receivePDF = (response, query={}) => {
    return {
        type: pdfTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receivePDFError = () => {
    return {
        type: pdfTypes.ERROR,
    };
};

export const fetchPDF = () => {
    return dispatch => {
        dispatch(requestPDF());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        

        return fetch(`/api/v1/pdf_document`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receivePDF(response));
                } catch (e) {
                    dispatch(receivePDFError(response))
                }


            })
            .catch(handleNetworkError);
    }
}