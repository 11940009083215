import React from 'react'
import ReactDOM from 'react-dom'
import BecomeMember from './becomeMember'
import { store } from '../../store' 

$.fn.becomeMember = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(BecomeMember, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-becomemember-container]').becomeMember();
});