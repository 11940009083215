import React from "react";
import { fetchEmptyApartments } from '../../api/apartments/actions'
import { fetchPromotionPages } from '../../api/pages/actions'

import { connect } from 'react-redux'
import ApartmentCard from "../../components/apartment-card";
import PromotionCard from "../../components/promotion-card";
import SearchBox from "../../components/search-box";
import GoogleMap from "../../components/map";

class ApartmentsContainer extends React.Component {
  constructor(props) {
    super(props)
    if (window.location.hash == "") {
      window.history.pushState({"tab": `#lausar-ibudir`}, "tab", `#lausar-ibudir`)
      this.state = {
        showEmptyApartments: true,
        showPromotionPages: false,
        showAllApartments: false,
        hasBeenSearched: false,
      }
    } else {
      this.state = {
        showEmptyApartments: window.location.hash == "#lausar-ibudir",
        showPromotionPages: window.location.hash == "#ibudir-i-byggingu",
        showAllApartments: window.location.hash == "#allar-ibudir",
        hasBeenSearched: false,
      }
    }
  }

  componentDidMount() {
    // dispatch a network call to the api
    this.props.dispatch(fetchEmptyApartments(this.props.lfb, this.props.overwriteQuery))
    this.props.dispatch(fetchPromotionPages())
  }
  componentWillReceiveProps(nextProps){
    let apartments = nextProps.apartments;
    if (apartments && !apartments.isFetching && apartments.results.length === 0) {
      window.history.pushState({"tab": `#allar-ibudir`}, "tab", `#allar-ibudir`)
      this.setState({
        showEmptyApartments: false,
        showPromotionPages: false,
        showAllApartments: true,
      })
    }

  }

  showAllEmptyApartments() {
    window.history.pushState({"tab": `#lausar-ibudir`}, "tab", `#lausar-ibudir`)
    this.setState({
      showEmptyApartments: true,
      showPromotionPages: false,
      showAllApartments: false,
    })

  }
  showPromotionPages() {
    window.history.pushState({"tab": `#ibudir-i-byggingu`}, "tab", `#ibudir-i-byggingu`)
    this.setState({
      showEmptyApartments: false,
      showPromotionPages: true,
      showAllApartments: false,
    })
  }
  showAllApartments() {
    window.history.pushState({"tab": `#allar-ibudir`}, "tab", `#allar-ibudir`)
    this.setState({
      showEmptyApartments: false,
      showPromotionPages: false,
      showAllApartments: true,
    })
  }

  showMap (hasSearched) {
    if (hasSearched) {
      this.setState({
        hasBeenSearched: hasSearched,
      })
    }
  }
  
  getMap(){
    if (this.state.showAllApartments && !this.state.hasBeenSearched) {
      return (<div className="row map">
        <GoogleMap store={this.props.store} singleCoordinate={false} leiga={this.props.lfb}/>
      </div>)
    } else {
      return null
    }
  }

  getCorrectListOfApartments(apartments, pages, hash) {
    apartments = apartments.filter(apartment => apartment.i_vinnslu === false)
    if (window.location.hash == "#lausar-ibudir") {
      const emptyApartments = apartments.filter(apartment => apartment.ibud_laus === true);
      if (emptyApartments.length === 0) {
        return(<div className="row justify-content-center"><p className="col-3 no-empty-apartments medium">Það eru engar íbúðir lausar sem stendur.</p></div>)
      } else {
        return (<div className="row"><ApartmentCard apartments={emptyApartments} lfb={this.props.lfb}/></div>)
      }
    } else if (window.location.hash == "#ibudir-i-byggingu") {
      if (pages && pages.length > 0) {
        return (<div className="row"><PromotionCard promotionPages={pages[0].kynningarvefur} /></div>)
      }
    } else if (window.location.hash == "#allar-ibudir") {
      return (<SearchBox apartments={apartments} store={this.props.store} hasBeenSearched={this.showMap.bind(this)} lfb={this.props.lfb} />)
    }
  }
  


  render() {
    const { pages } = this.props;
    const { apartments } = this.props;
    return (
      <>
      <div className="container all-apartments">
        <div className="row header-row">
          {this.props.showEmptyApartments === "True" ? <a href="#lausar-ibudir" className={this.state.showEmptyApartments ? "title align-text-bottom active" : "title align-text-bottom"} onClick={() => this.showAllEmptyApartments()}>Lausar íbúðir</a> : null }
          {this.props.showPromotionApartments === "True" ? <a href="#ibudir-i-byggingu" className={this.state.showPromotionPages ? "title align-text-bottom active" : "title align-text-bottom"} onClick={() => this.showPromotionPages()}>Nýbyggingar</a> : null }
          {this.props.showAllApartments === "True" ? <a href="#allar-ibudir" className={this.state.showAllApartments ? "title align-text-bottom active" : "title align-text-bottom"} onClick={() => this.showAllApartments()}>Allar íbúðir Búseta</a> : null }
        </div>
        <div className="apartments-list">
          {apartments.isFetching || pages.isFetching ?
            <div className="spinner"></div>
            : this.getCorrectListOfApartments(apartments.results, pages.results.items, window.location.hash)
          }
        </div>
      </div>
      {this.getMap()}
      
      </>
    )
  }
}

ApartmentsContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    apartments: {},
    pages: {},
  }
  f.apartments = (state.apartments === undefined) ? {} : state.apartments,
    f.pages = (state.pages === undefined) ? {} : state.pages
  return f;
}

export default connect(mapStateToProps)(ApartmentsContainer);