import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const postalCodesTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'POSTALCODES');

export const requestPostalCodes = () => {
    return {
        type: postalCodesTypes.REQUEST,
    };
};

export const receivePostalCodes = (response, query={}) => {
    return {
        type: postalCodesTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receivePostalCodesError = () => {
    return {
        type: postalCodesTypes.ERROR,
    };
};

export const fetchPostalCodes = (lfb) => {
    return dispatch => {
        let apiUrl = "/api/v1/ibudir/postnumer/"

        if (lfb === "True"){
            apiUrl += "?lfb=true"
        }

        dispatch(requestPostalCodes());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        

        return fetch(apiUrl, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receivePostalCodes(response));
                } catch (e) {
                    dispatch(receivePostalCodesError(response))
                }


            })
            .catch(handleNetworkError);
    }
}