import React from 'react';
import { currencyFormatter } from '../../utils';
import { fetchApartments } from '../../api/apartments/actions'

class MyApartment extends React.Component {

    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        if (this.props.members.results[0].CurrentApartment.length > 0 && !window.location.hash.includes("#umsokn/")) {
            const apart = await this.props.dispatch(fetchApartments(this.props.members.results[0].CurrentApartment[0].ibudId))
        }
    }

    hasStorage() {
        let apartment = { ...this.props.apartments.results }
        let storageFacts = []
        if (apartment.geymsla && apartment.geymslu_staerd !== undefined) {
            { storageFacts.push(<div className="card-info">Geymslustærð <span className="float-right">{apartment.geymslu_staerd} m<sup>2</sup></span></div>) }
        } else if (apartment.geymsla && apartment.geymslu_staerd === undefined) {
            { storageFacts.push(<div className="card-info">Geymsla <span className="float-right">Já</span></div>) }
        } else {
            { storageFacts.push(<div className="card-info">Geymsla <span className="float-right">Nei</span></div>) }
        }
        return storageFacts
    }

    renderImageLightBox(allImages) {
        if (allImages && Object.keys(allImages).length > 0) {
            return (Object.values(allImages).map((image, index) => {
                return (
                    <a className="lightbox-image" data-rel="lightcase:myImageGallery" href={image}>
                        <div className="img" style={{ backgroundImage: "url(" + image + ")" }}></div>
                    </a>
                )
            }))
        }
    }
    renderImages(images, allImages) {
        let imageList = []
        if (images && Object.keys(images).length > 0) {
            if (Object.keys(images).length > 1) {
                imageList.push(
                    <>
                        <img className="img-fluid lightbox-img" id={Object.keys(images)[0]} src={images[0]} alt="Large apartment image" />
                        {Object.keys(allImages).length > 0 ?
                            <a id="img-large-wrapper">
                                <div className="all-img-large">Allar myndir stórar</div>
                                <div className="img-amount">{Object.keys(allImages).length}</div>
                            </a> : <></>}
                    </>
                )
            }
            else {
                imageList.push(
                    <>
                        <img className="img-fluid lightbox-img" id={Object.keys(images)[0]} src={images[0]} alt="Large apartment image" />
                    </>
                )
            }
        }
        return imageList;
    }

    render() {
        let apartment = this.props.apartments;
        let allImages = { ...this.props.apartments.images }
        let images = { ...this.props.apartments.cover_images }
        
        return (
            <div className="col-12 col-lg-7 my-apartment">
                <div className="row">
                    <div className="col-12 pr-0 pl-0">
                        Íbúðin mín
                    </div>
                    <h2 className="col-12 pr-0 pl-0">
                        {apartment.heimilisfang}
                    </h2>
                    <div className="col-12 col-md-6 pl-0">
                        <div className="card-body pl-0">
                            <div className="card-info border-top-0 top">{apartment.numer_ibudar}<div className="town">{apartment.postnumer + " " + apartment.sveitarfelag}</div></div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 pl-0 pl-md-4 pr-0">
                        <div className="card-body pr-0">
                            {this.props.lfb || true ? "" :
                            <div className="card-info attention">Búseturéttur <span className="float-right">{apartment.verd_buseturettar ? currencyFormatter(apartment.verd_buseturettar) : ""}</span></div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-12 largest-img pr-0 pl-0">
                        {this.renderImages(images, allImages, apartment)}
                        <div id="lightbox">{this.renderImageLightBox(allImages)}</div>
                    </div>
                    <div className="col-12">
                        <div className="row apartment-information">
                            <div className="col-12 col-md-6 pl-0 pr-0 pr-md-4">
                                <div className="card-body pl-0">
                                    <div className="card-info">Fastanúmer <span className="float-right">{apartment.fastanumer}</span></div>
                                    <div className="card-info">Fjöldi herbergja <span className="float-right">{apartment.herbergi}</span></div>
                                    <div className="card-info">Stærð <span className="float-right">{apartment.fermetrar} m<sup>2</sup></span></div>
                                    <div className="card-info">Staðsetning <span className="float-right">{apartment.stadsetning}</span></div>
                                    <div className="card-info">Lyfta í húsinu <span className="float-right">{apartment.lyfta ? "Já" : "Nei"}</span></div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 pl-0 pl-md-4 pr-0">
                                <div className="card-body pr-0">
                                    <div className="card-info">Sérinngangur <span className="float-right">{apartment.serinngangur ? "Já" : "Nei"}</span></div>
                                    {this.hasStorage()}
                                    <div className="card-info">Bílageymsla <span className="float-right">{apartment.bilageymsla ? "Já" : "Nei"}</span></div>
                                    <div className="card-info">Bílskúr <span className="float-right">{apartment.bilskur ? "Já" : "Nei"}</span></div>
                                    <div className="card-info d-none d-md-block"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pr-0 pl-0">
                        <div className="apartment-description">
                            <div className="text" dangerouslySetInnerHTML={{ __html: apartment.lysing_ibudar ? apartment.lysing_ibudar.replace(/(?:\r\n|\r|\n)/g, '<br>') : "" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

MyApartment.defaultProps = {
}


export default MyApartment;