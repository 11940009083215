import React from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import is from 'date-fns/locale/is';
import { SvgEditIcon } from '../svg'
registerLocale('is', is)

import { createLfbApplication, fetchHousemate } from '../../api/lfbApplications/actions'
import { formatKennitala, currencyFormatter } from '../../utils'
const kennitala = require('kennitala')

import FileUploader from '../file-uploader'

const inputErrors = {
  name: 'Setja þarf inn nafn',
  ssn: 'Setja þarf inn kennitölu',
  address: 'Heimilisfang skal vera útfyllt',
  postal_code: 'Póstfang skal vera útfyllt',
  mobile: '',
  email: 'Fylla þarf inn tölvupóstfang',
  acceptedTerms: 'Samþykkja þarf skilmála',
  // housemateKennitala: 'Kennitala finnst ekki'
}


class Application extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            customer: {},
            applying: false,
            housemateName: '',
            housemateKennitala: '',
            children: "",
            acceptedTerms: false,
            files: [],
            image: '',
            errors: {
                name: '',
                ssn: '',
                address: '',
                postal_code: '',
                mobile: '',
                email: '',
                acceptedTerms: '',
                housemateKennitala: ''
            }
        }
        moment.locale('is')
        this.handleDateChange = this.handleDateChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        if (!this.props.members.isFetching && this.props.members.results.length > 0) {
            this.setState({
                customer: this.props.members.results[0].Membership,
                name: this.props.members.results[0].Membership.name,
                ssn: this.props.members.results[0].Membership.Kennitala,
                mobile: this.props.members.results[0].Membership.phone === ("0000000" || "") ? "" : this.props.members.results[0].Membership.phone,
                email: this.props.members.results[0].Membership.email === ("no@fislausnir.com" || "") ? "" : this.props.members.results[0].Membership.email,
                address: this.props.members.results[0].Membership.address,
                postal_code: this.props.members.results[0].Membership.postal_code,
            })
        } else if (!this.props.members.isFetching && this.props.members.results.message) {
            this.setState({
                customer: this.props.user
            })
        }
    }

    componentDidUpdate() {
        animateLabels();
        slideLabelsUp();
    }

    isFormValid() {
        let errors = this.state.errors
        let valid = true;
        Object.keys(errors).forEach(key => {
            switch (key) {
                // case 'name':
                //     let nameVal = this.state.customer.name;
                //     if (nameVal === '') {
                //         errors.name = 'Setja þarf inn nafn'
                //         valid = false;
                //     } else {
                //         errors.name = ''
                //     }
                //     break;
                // case 'ssn':
                //     let ssnVal = this.state.customer.Kennitala;
                //     if (ssnVal === '') {
                //         errors.ssn = 'Setja þarf inn kennitölu'
                //         valid = false;
                //     } else {
                //         errors.ssn = ''
                //     }
                //     break;
                case 'mobile':
                    let phoneVal = this.state.mobile;
                    if (!phoneVal) {
                        errors.mobile = 'Fylla þarf inn símanúmer'
                        valid = false;    
                    } 
                    else if (phoneVal.length < 7) {
                        errors.mobile = 'Símanúmer of stutt'
                        valid = false;
                    }
                    else {
                        errors.mobile = ''
                    }
                    break;
                case 'housemateKennitala':
                    if(this.state.housemateKennitala && !kennitala.isValid(this.state.housemateKennitala)){
                      errors.housemateKennitala = 'Vinsamlegast athugið innslátt'
                      valid = false
                    }
                    else {
                      errors.housemateKennitala = ''
                    }
                    break;
                default:
                    if (!this.state[key]) {
                        errors[key] = inputErrors[key]
                        valid = false;
                    } else {
                        errors[key] = ''
                    }
                    break;
            }
        });
        this.setState({ errors: errors })
        return valid;
    }

    handleDateChange(date) {
        this.setState({
            newDeliveryDate: date
        });
    };

    renderFilesAsFormData() {
        let formData = new FormData();
        this.state.files.map((file) => {
            formData.append("upload", file.file)
        })
        return formData
    }

    getFiles() {
        let allFiles = [];
        this.state.files.map((file) => {

            allFiles.push(file.base64)
        })
        return allFiles
    }

    handleRentalApartmentApplication(e, apartmentId) {
        e.preventDefault()  // Prevent full page reload
        $(window).scrollTop(0);
        let isFormValid = this.isFormValid();
        if (isFormValid) {
            let memberInfo = {}
            if (this.state.children) {
                memberInfo['children'] = this.state.children
            } else {
                memberInfo['children'] = this.state.customer.children
            }

            if (this.state.email) {
                memberInfo['email'] = this.state.email
            } else {
                memberInfo['email'] = this.state.customer.email
            }

            if (this.state.mobile) {
                memberInfo['phone_no'] = this.state.mobile
            } else {
                memberInfo['phone_no'] = this.state.customer.phone
            }

            if (this.state.address) {
                memberInfo['address'] = this.state.address
            } else {
                memberInfo['address'] = this.state.customer.address
            }

            if (this.state.postal_code) {
                let splitPostalCode = this.state.postal_code.split(" ")
                memberInfo['postal_code'] = splitPostalCode[0]
            } else {
                memberInfo['postal_code'] = this.state.customer.postal_code
            }

            let deliveryDate = ''
            if (this.props.newDeliveryDate) {
                deliveryDate = moment(this.props.newDeliveryDate).format('DD/MM/YYYY');
            }

            if (this.state.applying) {
                return;
            }
            this.setState({ applying: true }, () => {
                Promise.resolve(this.props.dispatch(createLfbApplication(apartmentId, deliveryDate, this.state.comment, kennitala.clean(this.state.housemateKennitala), this.state.files, memberInfo)))
            });
        }
    }

    handleChange(event) {
        const { name, value, checked } = event.target;
        if (event.target.type == "checkbox") {
            this.setState({
                [name]: checked
            })
        } else {
            this.setState({
                [name]: value
            })
            this.setState(prevState => ({
              errors: {
                ...prevState.errors,
                [name]: ''
              }
            }))
        }
        if (name === 'housemateKennitala') {
            let cleanKt = kennitala.clean(value)
            if (kennitala.isValid(cleanKt) && kennitala.isPerson(cleanKt)) {
                Promise.resolve(this.props.dispatch(fetchHousemate(cleanKt)))
                    .then(() => {
                        setTimeout(() => {
                            if (this.props.housemates.results.name) {
                                $("label[for='housemate-name']").addClass("animate-label");
                            }
                        }, 200)
                    }
                )
            }

        }
    }

    addFilesToState(files) {
        this.setState({
            files: [
                ...this.state.files,
                ...files
            ]
        })
    }

    removeImage(file) {
        let newFiles = []
        this.state.files.map((f) => {
            if (f.base64 !== file.base64) {
                newFiles.push(f)
            }
            return newFiles
        })
        this.setState({
            files: newFiles
        })
    }

    doesApplicationExist(idIbud) {
        let hasApplication = false;
        let members = this.props.members.results;
        if (members && members[0]) {
            if (members[0].Applications.length > 0) {
                members[0].Applications.map((application) => {
                  hasApplication = (parseInt(application.ibudId) === idIbud) ? true : hasApplication;
                })
            }
        }
        return hasApplication
    }

    getHousemateName() {
        let name = '';
        if (this.props.housemates && !this.props.housemates.isFetching && this.props.housemates.results.name) {
            name = this.props.housemates.results.name
        }
        return name
    }

    focusOnInput(id) {
        document.getElementById(id).select();
    }

    render() {
        let lfbCreatingApplication = this.props.lfbCreatingApplication;
        let responseCode = lfbCreatingApplication.responseCode;
        let responseMessage = lfbCreatingApplication.reponseMessage;
        let apartment = this.props.apartment;

        if (this.state.applying) {
            return (<div className="loader"></div>)
        }

        return (
        <>
          { !lfbCreatingApplication.isFetching && !responseCode && 
              <>
              { !this.doesApplicationExist(apartment.id_ibud) && 
                <>
                <div className="row application">
                  <h3 className="col-12">Umsækjandi</h3>
                  <div className="col-12 personal-info-section">
                    <form className="row" action="{% pageurl page %}" method="POST">
                        <div className="col-12 col-md-6 form-group pr-25">
                            <div class="form-group-item">
                                <input type="text" name="name" maxLength="255" required="" id="name" value={this.state.name} disabled />
                                <label htmlFor="name" className="col-form-label animate-label">Nafn</label>
                                {this.state.errors.name.length > 0 &&
                                    <div className="warning pl-0 col-12"> {this.state.errors.name} </div>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6 form-group pl-25">
                            <div class="form-group-item">
                                <input type="text" name="kennitala" maxLength="255" required="" id="kennitala" value={(this.state.customer && this.state.customer.Kennitala) ? formatKennitala(this.state.customer.Kennitala) : this.state.customer.Kennitala} disabled />
                                <label htmlFor="kennitala" className="col-form-label animate-label">Kennitala</label>
                                {this.state.errors.ssn.length > 0 &&
                                    <div className="warning pl-0 col-12"> {this.state.errors.ssn} </div>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6 form-group pr-25">
                            <div class="form-group-item">
                                <input type="email" name="email" maxLength="255" required="" id="email" value={this.state.email || this.state.email === "" ? this.state.email : this.state.customer.email} onChange={this.handleChange.bind(this)} />
                                <label htmlFor="email" className="col-form-label">Tölvupóstfang</label>
                                <span className="edit-icon" onClick={() => this.focusOnInput('email')}><SvgEditIcon/></span>
                                {this.state.errors.email.length > 0 &&
                                    <div className="warning pl-0 col-12"> {this.state.errors.email} </div>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6 form-group pl-25">
                            <div class="form-group-item">
                                <input type="text" name="address" maxLength="255" required="" id="address" value={this.state.address || this.state.address === "" ? this.state.address : this.state.customer.address} onChange={this.handleChange.bind(this)} />
                                <label htmlFor="address" className="col-form-label">Heimilisfang</label>
                                <span className="edit-icon" onClick={() => this.focusOnInput('address')}><SvgEditIcon/></span>
                                {this.state.errors.address.length > 0 &&
                                    <div className="warning pl-0 col-12"> {this.state.errors.address} </div>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6 form-group pr-25">
                            <div class="form-group-item">
                                <input type="number" name="mobile" maxLength="255" required="" id="mobile" value={this.state.mobile || this.state.mobile === "" ? this.state.mobile : this.state.customer.phone} onChange={this.handleChange.bind(this)} />
                                <label htmlFor="mobile" className="col-form-label">Farsími</label>
                                <span className="edit-icon" onClick={() => this.focusOnInput('mobile')}><SvgEditIcon/></span>
                                {this.state.errors.mobile.length > 0 &&
                                    <div className="warning pl-0 col-12"> {this.state.errors.mobile} </div>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6 form-group pl-25">
                            <div class="form-group-item">
                                <input type="text" name="postal_code" maxLength="255" required="" id="postal_code" value={this.state.customer.postal_code && (this.state.customer.postal_code === this.state.postal_code) ? this.state.customer.postal_code + " " + this.state.customer.town : this.state.postal_code} onChange={this.handleChange.bind(this)} />
                                <label htmlFor="postal_code" className="col-form-label">Póstnúmer</label>
                                <span className="edit-icon" onClick={() => this.focusOnInput('postal_code')}><SvgEditIcon/></span>
                                {this.state.errors.postal_code.length > 0 &&
                                    <div className="warning pl-0 col-12"> {this.state.errors.postal_code} </div>
                                }
                            </div>
                        </div>
                        <div className="col-12 form-group textarea">
                            <textarea rows="10" name="comment" maxLength="255" required="" id="comment" value={this.state.comment} onChange={this.handleChange} />
                            <label htmlFor="comment" className="col-form-label">Athugasemdir</label>
                        </div>
                        <div className="col-12 form-group type-checkbox submit-container">
                            <label htmlFor="acceptTermsQuestion" className="submit-label">
                                Ég hef kynnt mér <a href="buseti/personuvernd">upplýsingar</a> um vinnslu persónuupplýsinga og samþykki uppflettingu á vanskilaskrá og lánshæfismati frá Creditinfo.
                            </label>
                            <div className="checkbox-container">
                                <div className="submit-checkbox"></div>
                                <input
                                    id={"acceptedTerms"}
                                    name="acceptedTerms"
                                    type="checkbox"
                                    onChange={this.handleChange}
                                    checked={this.state.acceptedTerms} />
                            <label htmlFor="acceptedTerms" className="accept-terms">Samþykki uppflettingu</label>
                            </div>
                            {this.state.errors.acceptedTerms.length > 0 &&
                                <div className="warning pl-0 col-12"> {this.state.errors.acceptedTerms} </div>
                            }
                        </div>
                    </form>
                </div>
                </div>
                <div className="row application">
                    <h3 className="col-12">Meðleigjandi / Maki</h3>
                    <div className="col-12 housemate-info-section">
                        <form className="row" action="{% pageurl page %}" method="POST">
                            <div className="col-12 col-md-6 form-group pr-25">
                                <div class="form-group-item">
                                    <input className="kt-input" type="text" name="housemateKennitala" maxLength="255" required="" id="housemate-kennitala" value={this.state.housemateKennitala} onChange={this.handleChange} />
                                    <label htmlFor="housemate-kennitala" className="col-form-label">Kennitala</label>
                                    {this.props.housemates.results.errors && <div className="warning pl-0 col-12">{this.props.housemates.results.errors[0]}</div>}
                                    {this.state.errors.housemateKennitala && <div className="warning pl-0 col-12">{this.state.errors.housemateKennitala}</div>}
                                </div>
                            </div>
                            {/* <div className="loader"></div> */}
                            <div className="col-12 col-md-6 form-group pl-25">
                                <div class="form-group-item hide">
                                    <input  type="text" name="housemateName" maxLength="255" required="" id="housemate-name" value={this.getHousemateName()} />
                                    <label htmlFor="housemate-name" className="col-form-label sr-only">Nafn</label>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 form-group select-container">
                                <div class="form-group-item">
                                    <select id="children" name="children" onChange={this.handleChange} value={this.state.children} >
                                        <option value="" selected>Fjöldi barna</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                    </select>
                                    <label htmlFor="children" className="col-form-label sr-only">Fjöldi barna</label>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
                <div className="row files-row">
                    <h3 className="col-12">Fylgigögn</h3>
                    <div className="col-12"><p>Umsögn leigusala eða meðmæli</p></div>
                    <FileUploader
                        removeImage={this.removeImage.bind(this)}
                        multiple={true}
                        addFilesToState={this.addFilesToState.bind(this)}
                        files={this.state.files}
                    />
                    {this.state.imageErrorMsg ? <div className="error-msg d-block">{this.state.imageErrorMsg}</div> : null}
                </div>
                <div className="row">
                <div className="col-12">
                    <form id="apartment-apply">
                        <div className="button-wrapper">
                            <input type="submit" className="btn-primary btn-green" value="Senda inn umsókn" onClick={e => this.handleRentalApartmentApplication(e, apartment.id_ibud)} disabled={this.state.applying}></input>
                        </div>
                    </form>
                </div>
            </div>
            </>             
            }
          </>
          }
        </>)
    }
}

Application.defaultProps = { }

export default Application;