import React from 'react';
import moment from 'moment';
import { currencyFormatter } from '../../utils'
import DatePicker from "react-datepicker";

const CustomInput = ({ value, onClick, date }) => (
  <>
      <label onClick={onClick}>Óskir um annan afhendingard.</label>
      <input type="text" className={`${value && 'date'} `} onClick={onClick} value={value} placeholder="Önnur afhend.dags." />
      {/* <input type="text" className={`${value && 'date'} d-none d-xxl-block`} disabled onClick={onClick} value={value} placeholder="Óskir um annan afhendingard." /> */}

      <svg onClick={onClick} width="18" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#686C6E" fillRule="nonzero"><path d="M16.153 1.847h-3.11V.603a.31.31 0 10-.622 0v2.799a.31.31 0 10.622 0v-.933h3.11c.515 0 .933.419.933.933v2.799H.914V3.402c0-.515.419-.933.933-.933h2.488v-.622H1.847c-.857 0-1.555.698-1.555 1.555v12.751c0 .857.698 1.555 1.555 1.555h14.307c.857 0 1.555-.698 1.555-1.555V3.402a1.558 1.558 0 00-1.556-1.555zm.933 14.306a.934.934 0 01-.933.933H1.847a.934.934 0 01-.933-.933v-9.33h16.173v9.33h-.001z" /><path d="M5.268 3.713a.31.31 0 00.311-.311v-.933h6.22v-.622h-6.22V.603a.31.31 0 10-.622 0v2.799a.31.31 0 00.311.311z" /></g></svg>
  </>
);

class ApplicationInfoHeader extends React.Component {

    constructor(props) {
        super(props)

        moment.updateLocale(moment.locale('is'), { invalidDate: "Ekkert valið" })
    }

    doesApplicationExist(idIbud) {
      let hasApplication = false;
      let members = this.props.members;
      if (members && members[0]) {
          if (members[0].Applications.length > 0) {
              members[0].Applications.map((application) => {
                hasApplication = (parseInt(application.ibudId) === idIbud) ? true : hasApplication;
              })
          }
      }
      return hasApplication
  }

    render() {
      let {title, apartment, application, deliveryDate, subtitle} = this.props;

      return (
        <div className="row application-row">
          <h2 className="col-12 pl-0 pr-0">{title}</h2>
          { subtitle && <p className="col-12 pl-0 pr-0">{subtitle}</p>}
          <div className="info-section-container row">
              <div className="col-12 col-md-6 info-section pl-0">
                  <div>
                      <div className="address">
                          {apartment.heimilisfang}
                      </div>
                      <div className="address-small">{apartment.numer_ibudar}<span>{apartment.postnumer + " " + apartment.sveitarfelag}</span></div>
                  </div>
              </div>
              <div className="col-6 col-md-3 price-container pl-0"><span>Mánaðarleiga</span><p className="price">{apartment.busetugjald ? currencyFormatter(apartment.busetugjald) : ""}</p></div>
              {apartment.umsoknarfrestur && <div className="col-6 col-md-3 date-container"><span>Umsóknarfrestur</span><p className="date">{moment(apartment.umsoknarfrestur).format('DD. MM YYYY')}</p></div>}
              <div className="border-through"></div>
              {/* <div className="col-12 col-md-6 more-info pl-0"><a href={"/ibudir/" + apartment.id_ibud}>Nánari upplýsingar um íbúð</a></div> */}
              <div className="more-info">
                <a href={"/ibudir/" + apartment.id_ibud}>Upplýsingar um íbúð<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 28 52"><path fill="none" stroke="#000" stroke-width="4" d="M1 1l25 25L1 51" /></svg></a>
              </div>
              <div className="col-12 col-md-6 delivery-date pl-0">
                  <span className="col-12 p-0">Afhending íbúðar</span>
                  <div className={`col-12 p-0 ${(status === '' && !this.doesApplicationExist(apartment.id_ibud)) ? '' : ' disabled'}`}>
                      <p className="price col-5 p-0" >{apartment.afhending !== "" ? apartment.afhending : "Ekki vitað"}</p>
                  </div>
              </div>
              { application ?                      
                <div className="col-12 col-md-6 delivery-date pl-0">
                    <span className="col-12 p-0">Ósk um annan afhendingard.</span>
                    <div className={`col-12 p-0 ${(status === '' && !this.doesApplicationExist(apartment.id_ibud)) ? '' : ' disabled'}`}>
                        <p className="price col-5 p-0" >{moment(application.deliveryDate, "DD/MM/YYYY").format("DD.MM.YYYY")}</p>
                    </div>
                </div> 
                :
                <div className="col-12 col-md-6 change-date">
                  <div className="row datepicker-container">
                      <DatePicker
                          selected={deliveryDate !== '' ? deliveryDate : null}
                          onChange={this.props.handleDateChange}
                          locale='is'
                          dateFormat="dd.MM.yyyy"
                          placeholderText="Óskir um annan afhendingardag"
                          isClearable
                          customInput={<CustomInput/>}
                      />
                  </div>
              </div>
              }
          </div>
        </div>
      )
    }
}

ApplicationInfoHeader.defaultProps = { }

export default ApplicationInfoHeader;
