import React from 'react';
import ApplicationEditModal from "../application-edit-modal";
import ApplicationDeleteModal from "../application-delete-modal";
import SortableApplications from "../sortable-applications";
import ApplicationRow from "../application-row";

class Applications extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            applications: this.props.applications
        }
    }
    

    render() {
        const { applications } = this.state;
        const { isDeletingApplication, isFetchingMembers, applicationOrder, applicationWindow } = this.props;
        if (this.props.lfb) {
            return(
                <div className="col-12 col-lg-7 applications" >
                <div className="row">
                    <h2 className="col-12 pl-0 pr-0">Virkar umsóknir</h2>
                    {applications.length > 0 ?
                        <div className="col-12 application-body">
                            <div className="row application-row header">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 c-2">Umsókn um</div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 c-2">Umsókn send</div>
                                <div className="col-2">Staða</div>
                                <div className="col-2">Eyða</div>
                            </div>
                            {applications.map((application, index) => {                                
                                return <ApplicationRow application={application} key={index} index={index} item_index={index} lfb={true} showApplication={this.props.showApplication} />
                            })}
                            {applications.map((application) => {
                                return (
                                    [<ApplicationDeleteModal id={"ApplicationDeleteModal" + application.recordId} recordId={application.recordId} store={this.props.store} lfb={true} />]
                                )
                            })}
                        </div> : <div className="no-applications">Þú átt engar umsóknir.</div>}
                </div>
            </div>
            )
        } else {
            return (
                isDeletingApplication || isFetchingMembers || !applicationWindow.results || applicationWindow.isFetching ?
                    <div className="loader"></div> :
                    <div className={this.props.applicationWindow.results.opid ? "col-12 col-lg-7 applications open" : "col-12 col-lg-7 applications closed"}>
                        <div className="row">
                            <h2 className="col-12 pl-0 pr-0">Umsóknir</h2>
                            <div className="col-12 explanation pl-0">
                                {this.props.applicationWindow.results.opid ? "Til að forgangsraða þarf að smella á táknið vinstra megin við númerið fremst í línunni, halda því inni, draga línuna á þann stað sem óskað er og sleppa." : "Lokað er fyrir umsóknir, hvorki er hægt að breyta né eyða umsóknum."}
                            </div>
                            {applications.length > 0 ?
                                <div className="col-12 application-body">
                                    <div className="row application-row header d-none d-md-flex">
                                        <div className="col-1">#</div>
                                        <div className="col-4">Sótt um</div>
                                        <div className={this.props.applicationWindow.results.opid ? "col-2" : "col-3"}>Dags.</div>
                                        <div className={this.props.applicationWindow.results.opid ? "col-3 text-right" : "col-4 text-right"}>Upphæð tilboðs</div>
                                        {this.props.applicationWindow.results.opid ? <div className="col-2 text-right">Aðgerðir</div> : null}
                                        
                                    </div>
                                    <SortableApplications applications={applications} applicationOrder={applicationOrder} store={this.props.store} applicationWindow={this.props.applicationWindow.results.opid} />
                                    {applications.map((application) => {
                                        return (
                                            [
                                                <ApplicationEditModal id={"ApplicationEditModal" + application.recordId} maxBuseturettur={application.maxPrice} newApartment={application.newApartment} recordId={application.recordId} offer={application.offer} store={this.props.store} />,
                                                <ApplicationDeleteModal id={"ApplicationDeleteModal" + application.recordId} recordId={application.recordId} store={this.props.store} />]
                                        )
                                    })}
                                </div> : <div className="no-applications">Þú átt engar umsóknir.</div>}
    
                        </div>
                    </div>
            )
        }
    }
}

Applications.defaultProps = {
}


export default Applications;
