import React from 'react';

class ProgressBarEx extends React.Component {

    constructor(props) {
        super(props)
      }
      
      render() {
        return (
          <div className="col-12 col-md-10 col-lg-7 progress-bar-container">
            <div className="row progress-headings">
                <div className={this.props.percentage == 0 ? "col-4 active" : "col-4"}>Umsækjandi</div>
                <div className={this.props.percentage == 50 ? "col-4 text-center pl-5 active" : "col-4 text-center pl-5"}>Greiðsla</div>
                <div className={this.props.percentage == 100 ? "col-4 text-right pr-4 active" : "col-4 text-right pr-4"}>Kvittun</div>
            </div>
            <ProgressBar percentage={this.props.percentage}/>
            <div style={{ marginTop: '20px' }}>  
            </div>   
            
          </div>
        )
      }  
    }
    
    const ProgressBar = (props, allThis) => {
      return (
          <div className="progress-bar">
            <Filler percentage={props.percentage}/>
          </div>
        )
    }

    
    
    const Filler = (props) => {
      return <div><div className="filler" style={{ width: `${props.percentage}%` }} ><div className={`circle ${props.percentage == 0 ? 'active' : ''} ${props.percentage > 0 ? 'success ' : ''}`}></div><div className={`circle second ${props.percentage == 50 ? 'active' : ''} ${props.percentage > 50 ? 'success ' : ''}`}></div><div className={`circle third ${props.percentage == 100 ? 'active' : ''} ${props.percentage > 100 ? 'success ' : ''}`}></div></div></div>
    }

ProgressBarEx.defaultProps = {
}


export default ProgressBarEx;