import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import { apartments, mapApartments } from './api/apartments/reducers'
import { pages } from './api/pages/reducers'
import { apartmentSearch } from './api/search/reducers'
import { postalCodes } from './api/postalCodes/reducers'
import { members } from './api/members/reducers'
import { deletingApplication, editingApplication,reorderingApplications } from './api/applications/reducers'
import { applicationWindow } from './api/applicationWindow/reducers'
import { childPayment } from './api/childPayment/reducers'
import { updatePassword } from './api/updatePassword/reducers'
import { mailinglist } from './api/mailinglist/reducers'
import { lfbApplications, lfbDeletingApplication, lfbCreatingApplication, housemates } from './api/lfbApplications/reducers'
import { pdf } from './api/pdf_signing/reducers'
 
import { composeWithDevTools } from 'redux-devtools-extension';

//import TokenStorage from './storages/token-storage'

const loggerMiddleware = createLogger()

const reducers = combineReducers({
    apartments,
    pages,
    apartmentSearch,
    postalCodes,
    members,
    deletingApplication,
    editingApplication,
    reorderingApplications,
    applicationWindow,
    mapApartments,
    childPayment,
    updatePassword,
    lfbApplications,
    lfbDeletingApplication,
    lfbCreatingApplication,
    housemates,
    pdf,
    mailinglist
})


let middleware = null
// if(process.env.NODE_ENV === 'production') {
//     middleware = applyMiddleware(
//         thunkMiddleware,
        
//     )
// } else {
    middleware = composeWithDevTools(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    ))
// }

export const store = createStore(reducers, middleware)


// const token = TokenStorage.getItem('token');
// if (token !== null) {
//     store.dispatch(userLoginSuccess(token));
// }