import React from 'react';
import { connect } from 'react-redux'
import { getPrettierDateFormat, Spinner } from '../../utils';
import { SvgEditIcon } from '../svg'
import MyPaymentInformation from "../../components/my-payment-information";

const kennitala = require('kennitala');
import { cancelSubscription, fetchMemberInfo } from '../../api/members/actions'
import { fetchPDF } from '../../api/pdf_signing/actions';

class WithdrawCancellation extends React.Component {
    
    constructor(props) {
        super(props)

        this.state = {
            withdrawComplete: false,
            withdrawingCancelation: 'NOT_STARTED',

        }

        this.withdrawCancelation = this.withdrawCancelation.bind(this)
        this.withdrawingComplete = this.withdrawingComplete.bind(this)

    }



    componentDidUpdate(prevProps){
        console.log(prevProps)
        console.log(this.props)
        if(JSON.stringify(this.props.subscriptionCancel) !== JSON.stringify(prevProps.subscriptionCancel) && this.props.subscriptionCancel.status == 200 && this.props.subscriptionCancel.message === 'Úrsögn dregin til baka.') {
            this.setState({withdrawingCancelation: 'COMPLETED'});
            this.withdrawingComplete();
            this.props.dispatch(fetchMemberInfo())
        }
    }

    
    withdrawCancelation(processStep){
        this.props.dispatch(cancelSubscription(processStep))
    }

    withdrawingComplete(){
        this.setState({
            withdrawComplete: !this.state.withdrawComplete
        })
    }



    getCancelModalContent(pdf){
        switch(this.state.withdrawingCancelation) {
            case 'NOT_STARTED':
                return(<>
                    <div className="col-12 title">Viltu draga úrsögn þína úr Búseta til baka?</div>
                <div className="col-12">Þú getur endurheimt Búseta númerið þitt. Við minnum á að:</div>
                <ul>
                    <li>Það felast verðmæti í lágu félagsnúmeri - tíminn vinnur með þér.</li>
                    <li>Gott er að eiga þennan möguleika á lífsleiðinni.</li>
                    <li>Félagsmenn geta eignast fallegt heimili í fjölbreyttri flóru fasteigna.</li>
                </ul>
                <a className="btn btn-primary btn-red" onClick={() => this.withdrawCancelation('withdraw')} >Ég vil draga úrsögn mína úr Búseta til baka</a>
                </>)

            case 'COMPLETED':
                return(<>
                <div className="col-12 title">Uppsögnin hefur verið dregin til baka</div>
                <div className="col-12">Velkomin/n aftur!</div></>)

            default: 
                return(<></>)
        }
        
    }

    render() {

        return (
            <div className="col-12 col-lg-7" id="cancel-subscription-modal">
                <div className="row">
                    <h2 className="col-12 pl-0 pr-0">Hætta við uppsögn</h2>
                </div>
                <div className="row content">
                    <div className="col-12 col-md-6 offset-md-3">
                        {this.getCancelModalContent(this.props.pdf)}
                    </div>
                </div>
            </div>
        )
        
    }
}


function mapStateToProps(state) {

    var f = {
        pdf: {},
    }
    f.pdf = (state.pdf === undefined) ? {} : state.pdf

    return f;
}


export default connect(mapStateToProps)(WithdrawCancellation);