import React from 'react';

import { deleteApplicationTypes, editApplicationTypes, reorderApplicationsTypes } from './actions'

const initialState = {
    isFetching: false,
}


export function deletingApplication(state = initialState, action) {


    switch(action.type) {

        case deleteApplicationTypes.REQUEST:
            state = {...state, isFetching: true,}
            state.isFetching = true
            return state

        case deleteApplicationTypes.RECEIVE:
            state = {...state, isFetching: false}

            return state

        default:
            return state
    }
} 


export function editingApplication(state = initialState, action) {


    switch(action.type) {

        case editApplicationTypes.REQUEST:
            state = {...state, isFetching: true,}
            state.isFetching = true
            return state

        case editApplicationTypes.RECEIVE:
            state = {...state, isFetching: false}

            return state

        default:
            return state
    }
} 

export function reorderingApplications(state = initialState, action) {


    switch(action.type) {

        case reorderApplicationsTypes.REQUEST:
            state = {...state, isFetching: true,}
            state.isFetching = true
            return state

        case reorderApplicationsTypes.RECEIVE:
            state = {...state, isFetching: false}

            return state

        default:
            return state
    }
} 