import React from "react";
import ReactDOM from "react-dom";
import { fetchApartments } from '../../api/apartments/actions'
import { fetchApplicationWindowForApartment } from '../../api/applicationWindow/actions'
import { fetchMemberInfo } from '../../api/members/actions'
import { connect } from 'react-redux'
import Apartment from "../../components/apartment";
import GoogleMap from "../../components/map";

class SingleApartment extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.dispatch(fetchApartments(this.props.ibudId))
    this.props.dispatch(fetchApplicationWindowForApartment(this.props.ibudId))
    this.props.dispatch(fetchMemberInfo())
  }

  getApplicationWindow(){
    this.props.dispatch(fetchApplicationWindowForApartment(this.props.ibudId));
  }

  render() {
    const { apartments, applicationWindow, members } = this.props;
    if (!apartments.isFetching && apartments.status == 500){
      return(
        <div className="container">
          <div className="row no-apartment justify-content-center">
            <div className="col-2">Engin íbúð fannst</div>
          </div>
        </div>
      )
    }
    return (
      <>
        <div className="container">
          <div className="single-apartment">
            {apartments.isFetching  ?
              <div className="spinner"></div>
              :
              <Apartment apartments={apartments} userStatus={this.props.userStatus} applicationWindow={applicationWindow} members={members.results} store={this.props.store} fetchApplicationWindow={this.getApplicationWindow.bind(this)} dispatch={this.props.dispatch} leiga={this.props.leiga}/>
            }
          </div>
        </div>

        <div className="single-map d-none d-md-block">
          {apartments.isFetching && apartments.results.length > 0 ?
            <div className="spinner"></div>
            : 
            <GoogleMap store={this.props.store} singleCoordinate={true} apartmentId={this.props.ibudId} leiga={this.props.leiga}/>
          }
        </div>
      </>
    )

  }

}

SingleApartment.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    apartments: {},
    applicationWindow: {},
    members: {},
  }
  f.apartments = (state.apartments === undefined) ? {} : state.apartments
  f.applicationWindow = (state.applicationWindow === undefined) ? {} : state.applicationWindow
  f.members = (state.members === undefined) ? {} : state.members
  return f;
}

export default connect(mapStateToProps)(SingleApartment);