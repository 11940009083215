import React from 'react';

class PromotionCard extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        let promotionPages = this.props.promotionPages;
        
        return (promotionPages.map((promotionPage, index) => {
            return (
            <div className="col-12 col-md-6 col-lg-4 card-box" key={index}>
                <a href={"https://" + promotionPage.value.promotion_page_url} className="card apartment-card promotion-card">
                    <div className="img-box">
                        <img className="card-img-top" src={promotionPage.value.image.url} alt="Apartment card image"/>
                        <div className="promotion-page-title">{promotionPage.value.name}</div>
                    </div>
                    <div className="card-body">
                        <div className="card-description" dangerouslySetInnerHTML={{__html: promotionPage.value.short_description ? promotionPage.value.short_description.replace(/(?:\r\n|\r|\n)/g, '<p>'): ""}}></div>
                        <div className="promotion-card-btn">Skoða kynningarvef
                        <span>
                            <svg className="arrow-right-icon" xmlns="http://www.w3.org/2000/svg" width="53" height="53">
                                <g fill="none" fillRule="evenodd" stroke="#BDC1C4">
                                    <path strokeLinecap="square" d="M14.5 27.5h21"/>
                                    <path d="M31 22l5.312 5.267L31 32.535"/>
                                </g>
                            </svg>
                        </span>
                        </div>
                    </div>
                </a>
            </div>
            )}
          ))
    }
}

PromotionCard.defaultProps = {
}

export default PromotionCard;