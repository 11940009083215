import React from 'react';

import { lfbApplicationsTypes } from './actions'
import { lfbDeleteApplicationTypes } from './actions'
import { lfbCreateApplicationTypes } from './actions'
import { housemateTypes } from './actions'


const initialState = {
    isFetching: false,
    results: [],
}



export function lfbApplications(state = initialState, action) {

    switch (action.type) {

        case lfbApplicationsTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            return state

        case lfbApplicationsTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.results = action.data
            return state

        default:
            return state
    }
}


export function lfbDeletingApplication(state = initialState, action) {


    switch (action.type) {

        case lfbDeleteApplicationTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            return state

        case lfbDeleteApplicationTypes.RECEIVE:
            state = { ...state, isFetching: false }

            return state

        default:
            return state
    }
}

export function lfbCreatingApplication(state = initialState, action) {


    switch (action.type) {

        case lfbCreateApplicationTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            return state

        case lfbCreateApplicationTypes.RECEIVE:
            state = { ...state, isFetching: false, responseCode: action.data.code, reponseMessage: action.data.message }

            return state

        default:
            return state
    }
}

export function housemates(state = initialState, action) {
    switch (action.type) {

        case housemateTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            return state

        case housemateTypes.RECEIVE:
            if (action.data.status === "error") {
                state = { ...state, isFetching: false, results: {'errors': action.data.errors} }
            } else {
                state = { ...state, isFetching: false, results: {'name': action.data.Nafn, 'ssn': action.data.Kennitala, 'address': action.data.Heimili, 'postalcode': action.data.Póstnúmer} }
            }

            return state

        default:
            return state
    }
}