import React from 'react';

class ApartmentsList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            allApartmentsOpen: false,
            emptyApartmentsOpen: false,
        }
    }
    availableApartments(otherApartments) {
        if (otherApartments && Object.keys(otherApartments).length > 0) {
            var counts = Object.values(otherApartments).filter((apartment) => {
                return apartment.ibud_laus === true
            })
            if (counts.length > 1) {
                return counts.length + " lausar íbúðir"
            }
            else if (counts.length == 0 ){
                return "Engin laus íbúð" 
            }
            else {
                return counts.length + " laus íbúð" 
            }
        }
    }

    allApartments(otherApartments){
        if (otherApartments) {
            var counts = Object.values(otherApartments).length;
            if (counts > 1) {
                return counts + " íbúðir"
            }
            else if (counts == 0 ){
                return "Engar íbúðir" 
            }
            else {
                return counts + " íbúð" 
            }
        }
    }

    toggleAllApartmentsList(){
        if (!this.state.emptyApartmentsOpen) {
            this.setState({
                allApartmentsOpen: !this.state.allApartmentsOpen
            })
        } else {
            this.setState({
                emptyApartmentsOpen: !this.state.emptyApartmentsOpen,
                allApartmentsOpen: !this.state.allApartmentsOpen
            })
        }
        
    }

    toggleEmptyApartmentsList(){
        if (!this.state.allApartmentsOpen) {
            this.setState({
                emptyApartmentsOpen: !this.state.emptyApartmentsOpen,
            })
        } else {
            this.setState({
                emptyApartmentsOpen: !this.state.emptyApartmentsOpen,
                allApartmentsOpen: !this.state.allApartmentsOpen

            })
        }
    }
    
    showAllApartments(otherApartments){
        let sortedOtherApartments = Object.values(otherApartments).sort((a, b) => {
            return a.numer_ibudar - b.numer_ibudar;
          });
        return(Object.values(sortedOtherApartments).map((apartment, key)=> {
            return(<>
            <a className="row other-apartments-info" href={"/ibudir/" + apartment.id_ibud} id={apartment.id_ibud} >
                <div className={apartment.ibud_laus ? "col-4 info-tile empty-apartment" : "col-4 info-tile"}>
                    {apartment.numer_ibudar}
                </div>
                <div className={apartment.ibud_laus ? "col-4 info-tile text-center empty-apartment" : "col-4 info-tile text-center"}>
                    {apartment.herbergi}
                </div>
                <div className={apartment.ibud_laus ? "col-4 info-tile text-right empty-apartment" : "col-4 info-tile text-right"}>
                    {apartment.stadsetning}
                </div>
            </a>
            </>)
        }))}

    showEmptyApartments(otherApartments){
        const emptyApartments = Object.values(otherApartments).filter(apartment => apartment.ibud_laus === true);
        return(Object.values(emptyApartments).map((apartment)=> {
            return(<>
            <a className="row empty-apartments-info" href={"/ibudir/" + apartment.id_ibud} id={apartment.id_ibud}>
                <div className="col-4 info-tile">
                    {apartment.numer_ibudar}
                </div>
                <div className="col-4 info-tile text-center">
                    {apartment.herbergi}
                </div>
                <div className="col-4 info-tile text-right">
                    {apartment.stadsetning}
                </div>
            </a>
            </>)
        }))}

    getExpandableList(otherApartments){
        if (this.state.allApartmentsOpen) {
            return(<div className="show-apartments">
                <div className="row other-apartments-header">
                    <div className="col-4 header-tile">
                        Nr.
                    </div>
                    <div className="col-4 header-tile text-center">
                        Herbergi
                    </div>
                    <div className="col-4 header-tile text-right">
                        Staðsetning
                    </div>
                </div>
                {this.showAllApartments(otherApartments)}
            </div>)
        }
        else if (this.state.emptyApartmentsOpen) {
            return(<div className="show-apartments">
                <div className="row empty-apartments-header">
                    <div className="col-4 header-tile">
                        Nr.
                    </div>
                    <div className="col-4 header-tile text-center">
                        Herbergi
                    </div>
                    <div className="col-4 header-tile text-right">
                        Staðsetning
                    </div>
                </div>
                {this.showEmptyApartments(otherApartments)}
            </div>) 
        } else {return(<div></div>)}
    }

    getAllApartmentsHeaderStyle(){
        let style = "";
        if (this.state.emptyApartmentsOpen) {
            style = "col-6 all-apartments empty-open"
        } else if (this.state.allApartmentsOpen) {
            style = "col-6 all-apartments empty-closed all-open"
        } else {
            style = "col-6 all-apartments empty-closed"
        }
        return style
    }

    getEmptyApartmentsHeaderStyle(){
        let style = "";
        if (this.state.allApartmentsOpen) {
            style = "col-6 green empty-apartments all-open"
        } else if (this.state.emptyApartmentsOpen) {
            style = "col-6 green empty-apartments all-closed empty-open"
        } else {
            style = "col-6 green empty-apartments all-closed"
        }
        return style
    }

    render() {
        let otherApartments = this.props.otherApartments;
        return(
            <>
                <div className="row">   
                    <div className={this.getAllApartmentsHeaderStyle()} onClick={() => this.toggleAllApartmentsList()}>
                        {this.state.allApartmentsOpen ? 
                        <span className="col-12">{this.allApartments(otherApartments)}
                            <svg xmlns="http://www.w3.org/2000/svg" className="arrow-up-icon" viewBox="0 0 8.7 5.5">
                                <path d="M4.2.4l3.9 3.9-.7.7-3.2-3.2L1 5l-.7-.7z"/>
                            </svg>
                        </span> : 
                        <span className="col-12">{this.allApartments(otherApartments)} - sjá yfirlit
                            <svg xmlns="http://www.w3.org/2000/svg" className="arrow-down-icon" viewBox="0 0 8.7 5.5">
                                <path d="M4.2 5L.3 1.1 1 .4l3.2 3.2L7.4.4l.7.7z"/>
                            </svg>
                            
                        </span>}                        
                    </div>
                    <div className={this.getEmptyApartmentsHeaderStyle()} onClick={() => this.toggleEmptyApartmentsList()}>
                    {this.state.emptyApartmentsOpen ? 
                        <span>{this.availableApartments(otherApartments)}
                            <svg xmlns="http://www.w3.org/2000/svg" className="arrow-up-icon" viewBox="0 0 8.7 5.5">
                                <path d="M4.2.4l3.9 3.9-.7.7-3.2-3.2L1 5l-.7-.7z"/>
                            </svg>
                        </span> :
                        <span>{this.availableApartments(otherApartments)}
                            <svg xmlns="http://www.w3.org/2000/svg" className="arrow-down-icon" viewBox="0 0 8.7 5.5">
                                <path d="M4.2 5L.3 1.1 1 .4l3.2 3.2L7.4.4l.7.7z"/>
                            </svg>
                        </span>}
                    </div>
                </div>
                {this.getExpandableList(otherApartments)}
        </>)
    }
}

ApartmentsList.defaultProps = {
}


export default ApartmentsList;
