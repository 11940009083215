import React from "react";
import { connect } from 'react-redux'
import { fetchChildPaymentInfo } from '../../api/childPayment/actions'

import ProgressBarEx from "../../components/progress-bar";
import Step1 from "../../components/step1";
import Step2 from "../../components/step2-child-payment";
import Step3 from "../../components/step3";


class ChildPayment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentStep: 2,
            percentage: 50,
            mainKennitala: '',
            mainName: '',
            membershipRequest: false,
            allChildren: [],
            singleChildName: '',
            payerKennitala: '',
            paymentInformation : {
                cardHolder: '',
                cardNumber: '',
                validUntil: '',
                cvc: '',
            },
            email: '',
            phone: '',
            approveConditions: false,
            receiptInformation: {
                cardHolder: '',
                cardNumber: '',
                email: '',
            } 
        }

        this._next = this._next.bind(this);
        this._prev = this._prev.bind(this);
    }

    componentDidMount() {
        slideLabelsUp();
        Promise.resolve(this.props.dispatch(fetchChildPaymentInfo(this.props.childpayUuid)))
            .then(() => {
                setTimeout(() => {
                    let allChildren = this.props.childPayment.results.child_information.map((item) => {
                        return {
                            kennitala: item.id,
                            name: item.name,
                            membership: true
                        }
                    })
                    this.setState({
                        email: this.props.childPayment.results.payer_email,
                        payerKennitala: this.props.childPayment.results.payer_id,
                        allChildren: allChildren,

                    })
                }, 500)
                }
            )
    }

    _next() {
        let currentStep = this.state.currentStep

        currentStep = currentStep >= 2 ? 3 : currentStep + 1;
        this.setState({
            currentStep: currentStep
        })
        if (currentStep == 2) {
            this.setState({
                percentage: 50
            })
        } else if (currentStep == 3) {
            this.setState({
                percentage: 100
            })
        }
    }

    _prev() {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        })
        if (currentStep == 1) {
            this.setState({
                percentage: 0
            })
        } else if (currentStep == 2) {
            this.setState({
                percentage: 50
            })
        }
    }

    handlePaymentInputChange(event) {
        const { name, value } = event.target;
        this.setState({
            paymentInformation: {
                ...this.state.paymentInformation,
                [name]: value
            }
        })
    }

    handleChange(event) {
        const { name, value, checked } = event.target;
        if (event.target.type == "checkbox") {
            this.setState({
                [name]: checked
            })
        } else {
            this.setState({
                [name]: value
            })
        }
    }

    onAddChild(kennitala, name) {
        this.setState(prevState => ({
            allChildren: [...prevState.allChildren, {
                kennitala: kennitala,
                name: name,
                membership: true
            }]
        }))
    }

    onAddParent(name) {
        this.setState(prevState => ({
                ...prevState,
                mainName: name,
            })
        )
    }

    onAddSingleChild(name) {
        this.setState(prevState => ({
                ...prevState,
                singleChildName: name,
            })
        )
    }

    updateChildMembership(kennitala, shouldBecomeMember) {
        this.setState(prevState => {
            const allChildren = prevState.allChildren.map((child) => {
                if (child.kennitala === kennitala) {
                    return { ...child, membership: shouldBecomeMember };
                } else {
                    return child;
                }
            });

            return {
                allChildren,
            };
        });
    }

    storeReceiptInfo(firstname, lastname, cardnumber, email) {
        this.setState({
            receiptInformation: {
                cardHolder: firstname + " " + lastname,
                cardNumber: cardnumber,
                email: email,
            }
        })
    }

    isAdult(kennitala) {
        let comparisonDate = new Date();

        let splitKt = kennitala.substring(0, 6).match(/.{1,2}/g);
        let lastChar = kennitala[kennitala.length - 1];

        let year = "";
        if (lastChar === "0") {
            year = "20" + splitKt[2];
        } else if (lastChar === "9") {
            year = "19" + splitKt[2];
        }

        let birthDateStr = year + "-" + splitKt[1] + "-" + splitKt[0] + "T00:00:00.000Z";
        let birthDate = new Date(birthDateStr);
        let mismatch = comparisonDate - birthDate;
        let yearsBetween = mismatch / 1000 / (60 * 60 * 24) / 365.25;

        if (yearsBetween > 18) {
            return true
        } else {
            return false
        }
    }
    
    shouldDisplayInformation(){
        if (this.state.mainName === "" && (this.props.information !== '<div class="rich-text"></div>' || this.props.subtitle !== 'None')){
            return (
                <>
                <hr></hr>
                <div>
                    {this.props.subtitle !== 'None' ? <p className="intro-text">{this.props.subtitle}</p> : <></>}
                    <div className="signup-information" dangerouslySetInnerHTML={{ __html: this.props.information ? this.props.information.replace(/(?:\r\n|\r|\n)/g, '<br>') : "" }}></div>
                </div>
                
                </>
            )

        }
    }

    render() {
        if (!this.props.childPayment.isFetching && this.props.childPayment.results.length !== 0) {
            let allChildren = this.props.childPayment.results.child_information.map((item) => {
                return {
                    kennitala: item.id,
                    name: item.name,
                    membership: true
                }
            })
        }
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <h1 className="col-12 col-md-10 col-lg-7 text-center">Greiða félagsgjöld</h1>
                    <ProgressBarEx
                        currentStep={this.state.currentStep}
                        percentage={this.state.percentage}
                    />
                    <form id="payment-form" onSubmit={e => e.preventDefault()} className="col-12 col-md-10 col-lg-7">
                        <div className="row justify-content-center">
                            <Step2
                                currentStep={this.state.currentStep}
                                handleChange={this.handleChange.bind(this)}
                                handlePaymentInputChange={this.handlePaymentInputChange.bind(this)}
                                nextStep={this._next.bind(this)}
                                storeReceiptInfo={this.storeReceiptInfo.bind(this)}
                                mainKennitala={this.state.mainKennitala}
                                mainName={this.state.mainName}
                                membershipRequest={this.state.membershipRequest}
                                allChildren={this.state.allChildren}
                                paymentInformation={this.state.paymentInformation}
                                email={this.state.email}
                                phone={this.state.phone}
                                approveConditions={this.state.approveConditions}
                                mainIsAdult={this.state.mainIsAdult}
                                isAdult={this.isAdult}
                                singleChildName={this.state.singleChildName}
                                payerKennitala={this.state.payerKennitala}
                                dispatch={this.props.store.dispatch}
                                members={this.props.members}
                            />
                            <Step3
                                currentStep={this.state.currentStep}
                                mainName={this.state.mainName}
                                membershipRequest={this.state.membershipRequest}
                                allChildren={this.state.allChildren}
                                receiptInformation={this.state.receiptInformation}
                                mainIsAdult={this.state.mainIsAdult}
                                singleChildName={this.state.singleChildName}
                            />
                        </div>
                        {this.shouldDisplayInformation()}
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {

    var f = {
        childPayment: {},
        members: {},
    }
    f.childPayment = (state.childPayment === undefined) ? {} : state.childPayment
    f.members = (state.members === undefined) ? {} : state.members
    return f;
}

export default connect(mapStateToProps)(ChildPayment);