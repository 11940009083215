import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'
import { fetchMemberInfo } from '../members/actions'

export const applicationTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'APPLICATIONS');
export const deleteApplicationTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'DELETE_APPLICATION');
export const editApplicationTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'EDIT_APPLICATION');
export const reorderApplicationsTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'REORDER_APPLICATION');



export const requestApplications = () => {
    return {
        type: apartmentTypes.REQUEST,
    };
};

export const requestDeleteApplication = () => {
    return {
        type: deleteApplicationTypes.REQUEST,
    };
};

export const receiveDeleteApplication = (response, query={}) => {
    return {
        type: deleteApplicationTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveDeleteApplicationsError = () => {
    return {
        type: deleteApplicationTypes.ERROR,
    };
};

export const requestEditApplication = () => {
    return {
        type: editApplicationTypes.REQUEST,
    };
};

export const receiveEditApplication = (response, query={}) => {
    return {
        type: editApplicationTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveEditApplicationsError = () => {
    return {
        type: editApplicationTypes.ERROR,
    };
};

export const requestReorderApplications = () => {
    return {
        type: reorderApplicationsTypes.REQUEST,
    };
};

export const receiveReorderApplications = (response, query={}) => {
    return {
        type: reorderApplicationsTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveReorderApplicationsError = () => {
    return {
        type: reorderApplicationsTypes.ERROR,
    };
};

export const fetchApplications = () => {

};

export const deleteApplication = (recordId) => {
    
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }
        dispatch(requestDeleteApplication());
        fetch('/api/v1/umsokn/'+recordId+'/', {
            method: 'DELETE',
            headers: headers,
        })
        .then(() => {
            setTimeout(() => {
                dispatch(fetchMemberInfo())
            }, 500)
            }
        )
        .then(dispatch(receiveDeleteApplication()));    
    }
}

export const editApplication = (recordId, offer) => {
    
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }
        dispatch(requestEditApplication());
        fetch('/api/v1/umsokn/'+recordId+'/', {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({ "verd": offer })
        })
        .then(() => {
            setTimeout(() => {
                dispatch(fetchMemberInfo())
            }, 500)
            }
        )
        .then(dispatch(receiveEditApplication()));    
    }
}

export const reorderApplications = (newOrderedList) => {
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }
        dispatch(requestReorderApplications());
        fetch('/api/v1/umsokn/order/', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({"order": newOrderedList})
        })
        .then(() => {
            setTimeout(() => {
                dispatch(fetchMemberInfo())
            }, 500)
            }
        )
        .then(dispatch(receiveReorderApplications()));    
    }
}