import React from "react";
import { connect } from 'react-redux'
import { fetchApartments } from '../../api/apartments/actions'
import MyApartment from "../../components/my-apartment";
import Applications from "../../components/applications";
import MyInformation from "../../components/my-information";
import ApplicationProcess from "../../components/application-process";
import ApplicationOverview from "../../components/application-overview";
import LfbFinishSignup from "../../components/lfb-finish-signup"
import { updatePassword } from '../../api/updatePassword/actions'
import { fetchLfbApplications, deleteLfbApplication, createLfbApplication } from '../../api/lfbApplications/actions'
import { fetchMemberInfo } from "../../api/members/actions";


class LfbMyPages extends React.Component {
    constructor(props) {
        super(props)

        if (window.location.hash == "") {
            window.history.pushState({ "tab": `#minar-upplysingar` }, "tab", `#minar-upplysingar`)
        }

        this.state = {
            showMyApartment: window.location.hash == "#ibudin-min",
            showApplications: window.location.hash == "#umsoknir" || window.location.hash.includes("#umsokn/"),
            showMyInformation: window.location.hash == "#minar-upplysingar"
        };

        $('.my-pages li a').removeClass('active');
        if (window.location.hash == "#minar-upplysingar") {
            $('.my-pages #mobile-menu-my-information a').addClass('active');
        } else if (window.location.hash == "#ibudin-min") {
            $('.my-pages #mobile-menu-my-apartment a').addClass('active');
        } else if (window.location.hash == "#umsoknir") {
            $('.my-pages #mobile-menu-my-applications a').addClass('active');
        }
        this.showApplications = this.showApplications.bind(this)
        this.showApplication = this.showApplication.bind(this)
    }

    async componentDidMount() {
        if (this.props.members.results.code !== "403") {
            let leiga = true;
            const member = await this.props.store.dispatch(fetchMemberInfo(leiga));
            if (this.props.members.results.length > 0) {
                // if (this.props.members.results[0].Membership.phone === "0000000" | this.props.members.results[0].Membership.phone === "" || this.props.members.results[0].Membership.email === "" ) {
                //     $('#UpdatePhoneModal').modal('show');
                // }
                if (this.props.members.results[0].CurrentApartment.length > 0 && !window.location.hash.includes("#umsokn/")) {
                    const apart = await this.props.store.dispatch(fetchApartments(this.props.members.results[0].CurrentApartment[0].ibudId))
                } else {
                    const apart = [];
                }
            }
        }
    }

    // componentDidMount() {
    //     this.props.dispatch(fetchLfbApplications())
    //     let leiga = true;
    //     this.props.dispatch(fetchMemberInfo(leiga))
    // }

    deleteApplication(recId) {
        let leiga = true;
        Promise.resolve(this.props.dispatch(deleteLfbApplication(recId)))
            .then(this.props.dispatch(fetchMemberInfo(leiga)))
    }

    updatePassword(pw, leiga){
        this.props.store.dispatch(updatePassword(pw, leiga));
    }

    getCorrectPage(apartments, members, isDeletingApplication) {
        if (members.results.length > 0) {
            const applications = [];
            if (members.results[0].Applications.length > 0) {
                members.results[0].Applications.map((application) => {
                    applications.push(application)
                    return applications;
                })
            }
            if (window.location.hash == "#ibudin-min") {
                return (<MyApartment apartments={apartments} members={members.results} dispatch={this.props.dispatch} lfb={true} />)
            } else if (window.location.hash == "#umsoknir") {
                return (<Applications lfb={true} applications={applications} onDelete={this.deleteApplication.bind(this)} store={this.props.store} isDeletingApplication={isDeletingApplication} isFetchingMembers={members.isFetching} showApplication={this.showApplication} />)
            } else if (window.location.hash == "#minar-upplysingar") {
                return (<MyInformation store={this.props.store} members={members.results} updatePassword={this.updatePassword.bind(this)} passwordUpdate={this.props.updatePassword} leiga={true} />)
            } else if (window.location.hash.includes("#umsokn/")) {
                return (<ApplicationProcess dispatch={this.props.dispatch} apartment={this.props.apartments} members={members} showApplications={this.showApplications} lfbCreatingApplication={this.props.lfbCreatingApplication} housemates={this.props.housemates}/>)
            } else if (window.location.hash.includes("#min_umsokn/")) {
                return (<ApplicationOverview dispatch={this.props.dispatch} apartment={this.props.apartments} members={members} showApplications={this.showApplications} lfbCreatingApplication={this.props.lfbCreatingApplication} housemates={this.props.housemates}/>)
            }
        } else if (members.results.message) {
            if (window.location.hash == "#minar-upplysingar") {
              // return (<div className="text-center">Henlo: {members.results.message}<br /><lfbFinishSignup></lfbFinishSignup></div>)
              // Should not appear ever, now we create a new user on first login, however we keep this for now until further notice
                return (<LfbFinishSignup></LfbFinishSignup>)
            } else if (window.location.hash.includes("#umsokn/")) {
                return (<ApplicationProcess dispatch={this.props.dispatch} apartment={this.props.apartments} members={members} showApplications={this.showApplications} lfbCreatingApplication={this.props.lfbCreatingApplication} housemates={this.props.housemates} user={{'Kennitala': this.props.userid, 'name': this.props.userfirstname + ' ' + this.props.userlastname, 'town': '', 'phone': '', 'address': '', 'email': '', 'postal_code': ''}}/>)
            }
        }
    }

    hasApartment(members) {
        if (!members.isFetching && members.results.length > 0) {
            if (members.results[0].CurrentApartment.length > 0) {
                return (
                    <div className="col-3 offset-1 side-nav d-none d-lg-block">
                        <div className="my-page-options-wrapper">
                            <div className="nav-wrapper">
                                <a href="#ibudin-min" className={this.state.showMyApartment ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showMyApartment()}>Íbúðin mín</a>
                            </div>
                            <div className="nav-wrapper">
                                <a href="#umsoknir" className={this.state.showApplications ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showApplications()}>Umsóknir</a>
                            </div>
                            <div className="nav-wrapper">
                                <a href="#minar-upplysingar" className={this.state.showMyInformation ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showMyInformation()}>Mínar upplýsingar</a>
                            </div>
                        </div>
                        <div className="logout-wrapper">
                            <div className="nav-wrapper">
                                <a href="/minarsidur/utskraning?next=/" className="align-text-bottom" >Útskrá
                                        <svg className="logout-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 16">
                                        <path d="M18.3 8l-4.8 4.8-.7-.7 3.6-3.6H7v-1h9.4L12.8 4l.7-.7z" />
                                        <path d="M9 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h7c1.1 0 2-.9 2-2v-3.3h-1V14c0 .5-.5 1-1 1H2c-.5 0-1-.5-1-1V2c0-.5.5-1 1-1h7c.5 0 1 .5 1 1v3h1V2c0-1.1-.9-2-2-2zm1 8.5h1v-1h-1v1z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                )
            } else {
                $('#mobile-menu-my-apartment').hide();
                return (<div className="col-3 offset-1 side-nav d-none d-lg-block">
                    <div className="my-page-options-wrapper">
                        <div className="nav-wrapper">
                            <a href="#umsoknir" className={this.state.showApplications ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showApplications()}>Umsóknir</a>
                        </div>
                        <div className="nav-wrapper">
                            <a href="#minar-upplysingar" className={this.state.showMyInformation ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showMyInformation()}>Mínar upplýsingar</a>
                        </div>
                    </div>
                    <div className="logout-wrapper">
                        <div className="nav-wrapper">
                            <a href="/minarsidur/utskraning?next=/" className="align-text-bottom" >Útskrá
                            <svg className="logout-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 16">
                                    <path d="M18.3 8l-4.8 4.8-.7-.7 3.6-3.6H7v-1h9.4L12.8 4l.7-.7z" />
                                    <path d="M9 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h7c1.1 0 2-.9 2-2v-3.3h-1V14c0 .5-.5 1-1 1H2c-.5 0-1-.5-1-1V2c0-.5.5-1 1-1h7c.5 0 1 .5 1 1v3h1V2c0-1.1-.9-2-2-2zm1 8.5h1v-1h-1v1z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>)
            }
        }
    }

    showMyApartment() {
        window.history.pushState({ "tab": `#ibudin-min` }, "tab", `#ibudin-min`);
        this.setState({
            showMyApartment: true,
            showApplications: false,
            showVIPClub: false,
            showMyInformation: false
        })
    }

    showApplications() {
        window.history.pushState({ "tab": `#umsoknir` }, "tab", `#umsoknir`);
        this.setState({
            showMyApartment: false,
            showApplications: true,
            showVIPClub: false,
            showMyInformation: false
        })
    }

    showApplication(ibudId) {
        window.history.pushState({ "tab": `#min_umsokn/` + ibudId }, "tab", `#min_umsokn/` + ibudId);
        this.setState({
            showMyApartment: false,
            showApplications: true,
            showVIPClub: false,
            showMyInformation: false
        })
    }

    showMyInformation() {
        window.history.pushState({ "tab": `#minar-upplysingar` }, "tab", `#minar-upplysingar`);
        this.setState({
            showMyApartment: false,
            showApplications: false,
            showVIPClub: false,
            showMyInformation: true
        })
    }

    render() {
        const { members, lfbDeletingApplication, apartments } = this.props;

        return (
            <div className="container">
                <div className="row justify-content-center">
                    {members.isFetching && <div className="loader"></div>}
                    {!members.isFetching &&
                        [this.getCorrectPage(apartments.results, members, lfbDeletingApplication.isFetching),
                        this.hasApartment(members)]
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {

    var f = {
        lfbApplications: {},
        lfbCreatingApplication: {},
        lfbDeletingApplication: {},
        members: {},
        apartments: {},
        // deletingApplication: {},
        updatePassword: {},
        housemates: {},
        // applicationWindow: {},
    }
    f.lfbApplications = (state.lfbApplications === undefined) ? {} : state.lfbApplications
    f.lfbCreatingApplication = (state.lfbCreatingApplication === undefined) ? {} : state.lfbCreatingApplication
    f.lfbDeletingApplication = (state.lfbDeletingApplication === undefined) ? {} : state.lfbDeletingApplication
    f.members = (state.members === undefined) ? {} : state.members
    f.apartments = (state.apartments === undefined) ? {} : state.apartments
    // f.deletingApplication = (state.deletingApplication === undefined) ? {} : state.deletingApplication
    f.updatePassword = (state.updatePassword === undefined) ? {} : state.updatePassword
    f.housemates = (state.housemates === undefined) ? {} : state.housemates
    // f.applicationWindow = (state.applicationWindow === undefined) ? {} : state.applicationWindow
    return f;
}

export default connect(mapStateToProps)(LfbMyPages);
