import React from 'react';

class UpdatePhoneModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            mobile: this.props.members.results[0].Membership.phone,
            email: this.props.members.results[0].Membership.email,
            validEmail: true,
            validMobile: true,
        }
    }

    validateEmail(value) {
        let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let isEmailValid = true;
        if (value) {
            isEmailValid = re.test(String(value).toLowerCase());
        } else {
            isEmailValid = re.test(String(this.state.email).toLowerCase());
        }

        this.setState({
            validEmail: isEmailValid
        })
    }

    validateMobileNumber(noLength) {
        if (noLength < 7 || noLength > 7) {
            this.setState({
                validMobile: false
            })
        } else {
            this.setState({
                validMobile: true
            })
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        if (name === 'email') {
            this.validateEmail(value);
        }
        if (name === 'mobile') {
            this.validateMobileNumber(value.length);
        }

        this.setState({
            [name]: value
        })
    }

    updateContactInformation() {
        this.validateEmail();
        this.validateMobileNumber(this.state.mobile.length);

        if (this.state.validEmail && this.state.validMobile) {
            fetch('/api/v1/minarsidur/', {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': window.csrf_token,
                },
                body: JSON.stringify({ "email": this.state.email, "phone_no": this.state.mobile }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {

                        $('#UpdatePhoneModal').modal('hide');
                        this.props.fetchMember();
                    }
                });
        }
    }

    getCorrectModal() {
        if (this.state.responseCode === "403") {
            return (<div className="application-text">Ekki tókst að uppfæra samskiptaupplýsingar.</div>)
        } else if (this.state.responseCode === 200) {
            return (
                <div className="application-text">Samskiptaupplýsingar hafa verið uppfærðar.</div>
            )

        } else {
            if ((this.props.members.results[0].Membership.phone === "0000000" || this.props.members.results[0].Membership.phone === "") && this.props.members.results[0].Membership.email === "") {
                return (
                    <>
                        <div className="application-text">Vinsamlegast skráðu gilt símanúmer og netfang.</div>
                        <form id="contact-info-update" className="row justify-content-center">
                            <div className="phone-input col-11">
                                <label htmlFor="mobile" className="text-left">Símanúmer</label>
                                <input className="text-left" type="number" id="mobile" name="mobile" onChange={this.handleChange.bind(this)} value={this.state.mobile}></input>
                            </div>
                            <div className="email-input col-11">
                                <label htmlFor="email" className="text-left">Netfang</label>
                                <input className="text-left" type="text" id="email" name="email" onChange={this.handleChange.bind(this)} value={this.state.email}></input>
                            </div>
                            <div className="button-wrapper col-12">
                                <input type="submit" className="btn-primary btn-green" value="Vista" onClick={this.updateContactInformation.bind(this)}></input>
                            </div>
                        </form>
                    </>
                )
            } else if (this.props.members.results[0].Membership.phone === "0000000" || this.props.members.results[0].Membership.phone === "") {
                return (
                    <>
                        <div className="application-text">Vinsamlegast skráðu gilt símanúmer.</div>
                        <form id="contact-info-update" className="row justify-content-center">
                            <div className="phone-input col-6">
                                <label htmlFor="mobile" className="text-left">Símanúmer</label>
                                <input className="text-left" type="number" id="mobile" name="mobile" onChange={this.handleChange.bind(this)} value={this.state.mobile}></input>
                            </div>
                            <div className="button-wrapper large-gap col-12">
                                <input type="submit" className="btn-primary btn-green" value="Vista" onClick={this.updateContactInformation.bind(this)}></input>
                            </div>
                        </form>
                    </>
                )
            } else if (this.props.members.results[0].Membership.email === "") {
                return (
                    <>
                        <div className="application-text">Vinsamlegast skráðu gilt netfang.</div>
                        <form id="contact-info-update" className="row justify-content-center">
                            <div className="email-input col-11">
                                <label htmlFor="email" className="text-left">Netfang</label>
                                <input className="text-left" type="text" id="email" name="email" onChange={this.handleChange.bind(this)} value={this.state.email}></input>
                            </div>
                            <div className="button-wrapper large-gap col-12">
                                <input type="submit" className="btn-primary btn-green" value="Vista" onClick={this.updateContactInformation.bind(this)}></input>
                            </div>
                        </form>
                    </>
                )
            }
        }
    }

    render() {
        return (
            <div className="modal aem" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true" id={this.props.id}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body smaller">
                            {this.getCorrectModal()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


UpdatePhoneModal.defaultProps = {
}


export default UpdatePhoneModal;