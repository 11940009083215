import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const signupTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'LFB_SIGNUP');


export const requestCreateLFBUser = () => {
    return {
        type: signupTypes.REQUEST,
    };
};

export const receiveCreateLFBUser = (response, query={}) => {
    return {
        type: signupTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveCreateLFBUserError = () => {
    return {
        type: signupTypes.ERROR,
    };
};


export const createLFBUser = (ssn, email) => {
    return dispatch => {
        dispatch(requestCreateLFBUser())
        const apiUrl = "/api/v1/leigufelag_nyskraning/"

        // dispatch(requestApartments());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }

        return fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            data: JSON.stringify({ 'member_object': {ssn: ssn, email: email }}),
            // body: JSON.stringify({ 'member_object': {ssn: ssn, email: email }}),

        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveCreateLFBUser(response));
                } catch (e) {
                    dispatch(receiveCreateLFBUserError(response))
                }


            })
            .catch(handleNetworkError);
    };
};

