import React from 'react';
import ChildMember from "../child-member";


class Step1 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            displayKennitalaField: false,
            membershipRequest: false,
            name: "",
            payerName: "",
            responseMessage: "",
            responseStatus: "",
            visibleChildren: 1,
            actualChildren: 0,
            validPayerSsn: true,
        }
    }

    componentDidUpdate() {
        slideLabelsUp();
    }

    onAddChild(kennitala, name, actualChildren) {
        this.setState({
            visibleChildren: this.state.visibleChildren + 1,
            actualChildren: actualChildren + 1,
        });
        this.props.addChild(kennitala, name);
    }

    updateChildMembership(kennitala, checked) {
        if (checked === false) {
            this.setState({
                actualChildren: this.state.actualChildren - 1,
            })
        } else if (checked === true) {
            this.setState({
                actualChildren: this.state.actualChildren + 1,
            })
        }
        this.props.updateChildMembership(kennitala, checked);
    }

    isAdult() {
        let comparisonDate = new Date();

        let kennitala = this.props.payerKennitala;
        let splitKt = kennitala.substring(0, 6).match(/.{1,2}/g);
        let lastChar = kennitala[kennitala.length - 1];

        let year = "";
        if (lastChar === "0") {
            year = "20" + splitKt[2];
        } else if (lastChar === "9") {
            year = "19" + splitKt[2];
        }

        let birthDateStr = year + "-" + splitKt[1] + "-" + splitKt[0] + "T00:00:00.000Z";
        let birthDate = new Date(birthDateStr);
        let mismatch = comparisonDate - birthDate;
        let yearsBetween = mismatch / 1000 / (60 * 60 * 24) / 365.25;

        if (yearsBetween > 18) {
            return true
        } else {
            return false
        }
    }

    lookUpKennitala() {
        this.props.isMainAdult();
        this.setState({
            responseMessage: ""
        })
        fetch('/api/v1/nafn/?ssn=' + this.props.mainKennitala, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': window.csrf_token,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === "success") {
                    this.setState({ name: data.name, responseStatus: data.status });
                    {this.props.mainIsAdult ? this.props.addParent(data.name) : this.props.addSingleChild(data.name)}
                } else if (data.status === "error") {
                    this.setState({ responseMessage: data.message, responseStatus: data.status });
                }
            })
    }

    lookUpPayerKennitala() {
        this.setState({
            validPayerSsn: true,
            responseMessage: ""
        })
        if (this.isAdult()) {
            fetch('/api/v1/nafnauppfletting/?ssn=' + this.props.payerKennitala, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': window.csrf_token,
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status === "success") {
                        this.setState({ payerName: data.name, responseStatus: data.status });
                        this.props.addParent(data.name);
                    } else if (data.status === "error") {
                        this.setState({ responseMessage: data.message, responseStatus: data.status });
                    }
                })
        } else {
            this.setState({
                validPayerSsn: false
            })
        }

    }

    displayContinueButton() {
        if (this.props.mainIsAdult) {
            if (this.state.actualChildren > 0 || this.props.membershipRequest === true) {
                return (
                    <div className="col-12 continue-button-wrapper text-center">
                        <button
                            className="btn-primary btn-red"
                            type="button" onClick={this.props.nextStep}>
                            Áfram
                        </button>
                    </div>
                )
            }
        } else {
            if (this.props.payerKennitala.length === 10 && this.state.payerName !== "") {
                return (
                    <div className="col-12 continue-button-wrapper text-center">
                        <button
                            className="btn-primary btn-red"
                            type="button" onClick={this.props.nextStep}>
                            Áfram
                            </button>
                    </div>
                )
            }
        }

    }

    getApplicationFields(children) {
        if (this.state.name === "") {
            return (<>
                <div className="col-2"></div>
                <div className="form-group col-8">
                    <label className="col-form-label" htmlFor="mainKennitala">Kennitala</label>
                    <input
                        className="form-control"
                        id="mainKennitala"
                        name="mainKennitala"
                        type="number"
                        value={this.props.mainKennitala} // Prop: The kennitala input data
                        onChange={this.props.handleChange} // Prop: Puts data into state
                    />
                    {this.props.mainKennitala.length === 10 ?
                        <button
                            className="btn btn-proceed"
                            type="button" onClick={this.lookUpKennitala.bind(this)}
                        >
                        </button>
                        : null}
                    {this.state.responseStatus === "error" && this.state.responseMessage !== "" ? <div className="warning">{this.state.responseMessage}</div> : null}
                </div> 
                </>)
        } else {
            if (this.props.mainIsAdult) {
                return (<> <div className="member-info col-12">{this.state.name}</div>
                    <div className="form-group parent type-checkbox col-12">
                        <input
                            id="membership-request"
                            name="membershipRequest"
                            type="checkbox"
                            onChange={this.props.handleChange}
                            checked={this.props.membershipRequest} />
                        <span className="checkmark"></span>
                        <label htmlFor="membership-request">Aðild fyrir mig (5.500kr.)</label>
                    </div>
                    {children}
                    {this.displayContinueButton()}
                </>)
            } else {
                return (<>
                    <div className="member-info col-12">{this.state.name}</div>
                    <div className="form-group parent type-checkbox col-12">
                        <input
                            id="membership-request"
                            name="membershipRequest"
                            type="checkbox"
                            onChange={this.props.handleChange}
                            checked={this.props.membershipRequest} />
                        <span className="checkmark"></span>
                        <label htmlFor="membership-request">Aðild fyrir barn yngra en 18 ára (2.750kr.)</label>
                        {!this.props.mainIsAdult ? <div className="warning">Einstaklingur er yngri en 18 ára, til að halda skráningu áfram þarf fjárráða einstaklingur að ganga frá skráningu og greiða félagsgjald.</div> : null}
                    </div>
                    <div className="col-2"></div>
                    {this.state.payerName === "" ?
                        <div className="form-group col-8">
                            <label className="col-form-label" htmlFor="payerKennitala">Kennitala greiðanda</label>
                            <input
                                className="form-control"
                                id="payerKennitala"
                                name="payerKennitala"
                                type="number"
                                value={this.props.payerKennitala} // Prop: The kennitala input data
                                onChange={this.props.handleChange} // Prop: Puts data into state
                            />
                            {this.props.payerKennitala.length === 10 ?
                                <button
                                    className="btn btn-proceed"
                                    type="button" onClick={this.lookUpPayerKennitala.bind(this)}
                                >
                                </button>
                                : null}
                            {this.state.responseStatus === "error" && this.state.responseMessage !== "" ? <div className="warning">{this.state.responseMessage}</div> : null}
                            {!this.state.validPayerSsn ? <div className="warning">Einstaklingur er yngri en 18 ára.</div> : null}
                        </div> : <>
                            <div className="member-info-label col-12 float-left text-left">Greiðandi</div>
                            <div className="member-info col-12 float-left text-left">{this.state.payerName}</div>
                        </>
                    }

                    {this.displayContinueButton()}
                </>)
            }

        }
    }

    render() {
        if (this.props.currentStep !== 1) {
            return null
        }

        const children = [];

        for (var i = 0; i < this.state.visibleChildren; i += 1) {
            children.push(<ChildMember key={i} number={i} addChild={this.onAddChild.bind(this)} handleChange={this.props.handleChange} updateChildMembership={this.updateChildMembership.bind(this)} />);
        };

        return (
            <div className="col-10 col-md-8 col-xl-6">
                <div className="row">
                    {this.getApplicationFields(children)}
                </div>
            </div>

        )

    }
}

Step1.defaultProps = {
}


export default Step1;