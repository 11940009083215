import React from "react";
import { connect } from 'react-redux'
import { fetchApartments } from '../../api/apartments/actions'
import MyApartment from "../../components/my-apartment";
import Applications from "../../components/applications";
import VIPClub from "../../components/vip-club";
import MyInformation from "../../components/my-information";
import Cancellation from "../../components/my-information-cancellation";
import WithdrawCancellation from "../../components/my-information-withdraw-cancellation";
import MyPaymentInformation from "../../components/my-payment-information";
import { fetchMemberInfo } from '../../api/members/actions'
import { updatePassword } from '../../api/updatePassword/actions'
import UpdatePhoneModal from '../../components/update-phone-modal'
import { fetchApplicationWindow } from '../../api/applicationWindow/actions'
import { updateCreditCard, updatePaymentEmail, createTempToken, cancelSubscription, fetchTokenStatus } from '../../api/members/actions'


class MyPages extends React.Component {
    constructor(props) {
        super(props)
        if (window.location.hash == "") {
            window.history.pushState({ "tab": `#minar-upplysingar` }, "tab", `#minar-upplysingar`)
        }

        this.timer = null

        this.state = {
            showMyApartment: window.location.hash == "#ibudin-min",
            showApplications: window.location.hash == "#umsoknir",
            showVIPClub: window.location.hash == "#vildarklubbur",
            showMyInformation: window.location.hash == "#minar-upplysingar",
            showCancellation: window.location.hash == '#ursogn',
            paymentBody: {},
            display: 'cardform',
        };

        $('.my-pages li a').removeClass('active');
        if (window.location.hash == "#minar-upplysingar") {
            $('.my-pages #mobile-menu-my-information a').addClass('active');
        } else if (window.location.hash == "#ibudin-min") {
            $('.my-pages #mobile-menu-my-apartment a').addClass('active');
        } else if (window.location.hash == "#umsoknir") {
            $('.my-pages #mobile-menu-my-applications a').addClass('active');
        } else if (window.location.hash == "#ursogn") {
            $('.my-pages #mobile-menu-ursogn a').addClass('active');
        }

        this.updateDisplay = this.updateDisplay.bind(this)
    }

    async componentDidMount() {
        const member = await this.props.store.dispatch(fetchMemberInfo());
        if (this.props.members.results.length > 0) {
            if (this.props.members.results[0].Membership.phone === "0000000" | this.props.members.results[0].Membership.phone === "" || this.props.members.results[0].Membership.email === "" ) {
                $('#UpdatePhoneModal').modal('show');
            }
            if (this.props.members.results[0].Current_apartment.length > 0) {
                const apart = await this.props.store.dispatch(fetchApartments(this.props.members.results[0].Current_apartment[0].ibudId))
            } else {
                const apart = [];
            }
        }
        this.props.store.dispatch(fetchApplicationWindow());
    }

    fetchMemberInfo() {
        this.props.store.dispatch(fetchMemberInfo());
    }

    updatePassword(pw, leiga){
        this.props.store.dispatch(updatePassword(pw, leiga));
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.members.tokenStatus === 'tokencreated' && (this.props.members.tokenStatus !== prevProps.members.tokenStatus)) {
            let body = {
                ...this.state.paymentBody,
                token: this.props.members.tempToken
            }
            Promise.resolve(this.props.store.dispatch(updateCreditCard(body)))
            .then(response => {
              clearInterval(this.timer)
              this.setState({display: 'done'})
            })
        }
        if (this.props.members.tokenStatus === 'failed' && (this.props.members.tokenStatus !== prevProps.members.tokenStatus)) {
          this.setState({display: 'error'})
          clearInterval(this.timer)
        }
      }
    
    updatePaymentInformation(info){
        let card_change = true;
        let expDate = info.validUntil.split("/");
        let expMonth = expDate[0];
        let expYear = expDate[1];
        let body = {}
        Object.assign(body, info)
        delete body['validUntil']
        body['expiration_year'] = expYear;
        body['expiration_month'] = expMonth;
        this.setState({paymentBody: body})

        Promise.resolve(this.props.dispatch(createTempToken(body, card_change))).then((res) => {
            delete body['cardHolder'];
            delete body['cardNumber'];
            delete body['cvc'];
            if ( this.timer === null) {
                this.timer = setInterval(() => this.props.dispatch(fetchTokenStatus(this.props.members.tempToken)), 1000)
            }
        })
    }

    updateDisplay(newState) {
        this.setState({display: newState})
    }

    updateEmailAskell(email) {
        this.props.store.dispatch(updatePaymentEmail(email))
    }

    cancelSubscription(file_obj) {
        this.props.store.dispatch(cancelSubscription(file_obj))
    }

    getCorrectPage(apartments, members, isDeletingApplication) {
        if (!members.isFetching && (members.results.length > 0 || members.results.askell_data)) {
            const applications = [];
            if (members.results.length > 0) {
                if (members.results[0].Applications.length > 0) {
                    members.results[0].Applications.map((application) => {
                        applications.push(application)
                        return applications;
                    })
                }
            }
            if (window.location.hash == "#ibudin-min") {
                return (<MyApartment apartments={apartments} members={members.results}/>)
            } else if (window.location.hash == "#umsoknir") {
                return (<Applications applications={applications} onDelete={this.fetchMemberInfo.bind(this)} store={this.props.store} isDeletingApplication={isDeletingApplication} isFetchingMembers={members.isFetching} applicationOrder={members.applicationOrder} applicationWindow={this.props.applicationWindow} />)
            }
            else if (window.location.hash == "#ursogn") {
                if (members.results[0].Membership.member_status === 'Í uppsögn') {
                    return (<WithdrawCancellation members={members.results} subscriptionCancel={members.subscriptionCancel}  leiga={false} dispatch={this.props.store.dispatch} store={this.props.store} />)
                } else {
                    return (<Cancellation members={members.results} subscriptionCancel={members.subscriptionCancel}  leiga={false} dispatch={this.props.store.dispatch} store={this.props.store} />)
                }
            }
            // else if (window.location.hash == "#vildarklubbur") {
            //     return (<VIPClub />)
            // } 
            else if (window.location.hash == "#minar-upplysingar") {
                if (members.results.length > 0) {
                    return (<MyInformation updateDisplay={this.updateDisplay} display={this.state.display} memberObject={members} members={members.results} creditCardUpdate={members.creditCardUpdate} updatePaymentInformation={this.updatePaymentInformation.bind(this)} updatePassword={this.updatePassword.bind(this)} updateEmailAskell={this.updateEmailAskell.bind(this)} cancelSubscription={this.cancelSubscription.bind(this)} passwordUpdate={this.props.updatePassword} leiga={false} dispatch={this.props.store.dispatch} store={this.props.store}/>)
                } else if (Object.keys(members.results).length > 0 && members.results.askell_data){
                    return (<MyPaymentInformation askellData={members.results.askell_data} creditCardUpdate={members.creditCardUpdate} updatePaymentInformation={this.updatePaymentInformation.bind(this)}/>)
                }
            }
        } else if (!members.isFetching && members.results.message) {
            return (
                <div className="text-center">{members.results.message}<br /><a href="/minarsidur/utskraning?next=/" className="align-text-bottom" >Útskráning</a></div>

            )
        }
    }


    showMyApartment() {
        window.history.pushState({ "tab": `#ibudin-min` }, "tab", `#ibudin-min`);
        this.setState({
            showMyApartment: true,
            showApplications: false,
            showVIPClub: false,
            showMyInformation: false,
            showCancellation: false,
        })
    }

    showApplications() {
        window.history.pushState({ "tab": `#umsoknir` }, "tab", `#umsoknir`);
        this.setState({
            showMyApartment: false,
            showApplications: true,
            showVIPClub: false,
            showMyInformation: false,
            showCancellation: false,
        })
    }

    showVIPClub() {
        window.history.pushState({ "tab": `#vildarklubbur` }, "tab", `#vildarklubbur`);
        this.setState({
            showMyApartment: false,
            showApplications: false,
            showVIPClub: true,
            showMyInformation: false,
            showCancellation: false,
        })
    }

    showMyInformation() {
        window.history.pushState({ "tab": `#minar-upplysingar` }, "tab", `#minar-upplysingar`);
        this.setState({
            showMyApartment: false,
            showApplications: false,
            showVIPClub: false,
            showMyInformation: true,
            showCancellation: false,
        })
    }

    showCancellation() {
        window.history.pushState({ "tab": `#ursogn` }, "tab", `#ursogn`);
        this.setState({
            showMyApartment: false,
            showApplications: false,
            showVIPClub: false,
            showMyInformation: false,
            showCancellation: true,
        })
    }

    hasApartment(members) {
        if (!members.isFetching && members.results.length > 0) {
            if (members.results[0].Current_apartment.length > 0) {
                return (
                    <div className="col-3 offset-1 side-nav d-none d-lg-block">
                        <div className="my-page-options-wrapper">
                            <div className="nav-wrapper">
                                <a href="#ibudin-min" className={this.state.showMyApartment ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showMyApartment()}>Íbúðin mín</a>
                            </div>
                            <div className="nav-wrapper">
                                <a href="#umsoknir" className={this.state.showApplications ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showApplications()}>Umsóknir</a>
                            </div>
                            {/* <div className="nav-wrapper">
                            <a href="#vildarklubbur" className={this.state.showVIPClub ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showVIPClub()}>Vildarklúbbur</a>
                        </div> */}
                            <div className="nav-wrapper">
                                <a href="#minar-upplysingar" className={this.state.showMyInformation ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showMyInformation()}>Mínar upplýsingar</a>
                            </div>
                            <div className="nav-wrapper">
                                <a href="#ursogn" className={this.state.showCancellation ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showCancellation()}>Breyta félagsaðild</a>
                            </div>
                        </div>
                        <div className="logout-wrapper">
                            <div className="nav-wrapper">
                                <a href="/minarsidur/utskraning?next=/" className="align-text-bottom" >Útskrá
                                <svg className="logout-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 16">
                                        <path d="M18.3 8l-4.8 4.8-.7-.7 3.6-3.6H7v-1h9.4L12.8 4l.7-.7z" />
                                        <path d="M9 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h7c1.1 0 2-.9 2-2v-3.3h-1V14c0 .5-.5 1-1 1H2c-.5 0-1-.5-1-1V2c0-.5.5-1 1-1h7c.5 0 1 .5 1 1v3h1V2c0-1.1-.9-2-2-2zm1 8.5h1v-1h-1v1z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                )
            } else {
                $('#mobile-menu-my-apartment').hide();
                return (<div className="col-3 offset-1 side-nav d-none d-lg-block">
                    <div className="my-page-options-wrapper">
                        <div className="nav-wrapper">
                            <a href="#umsoknir" className={this.state.showApplications ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showApplications()}>Umsóknir</a>
                        </div>
                        {/* <div className="nav-wrapper">
                            <a href="#vildarklubbur" className={this.state.showVIPClub ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showVIPClub()}>Vildarklúbbur</a>
                        </div> */}
                        <div className="nav-wrapper">
                            <a href="#minar-upplysingar" className={this.state.showMyInformation ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showMyInformation()}>Mínar upplýsingar</a>
                        </div>
                        <div className="nav-wrapper">
                            <a href="#ursogn" className={this.state.showCancellation ? "align-text-bottom active" : "align-text-bottom"} onClick={() => this.showCancellation()}>Breyta félagsaðild</a>
                        </div>
                    </div>
                    <div className="logout-wrapper">
                        <div className="nav-wrapper">
                            <a href="/minarsidur/utskraning?next=/" className="align-text-bottom" >Útskrá
                                <svg className="logout-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 16">
                                    <path d="M18.3 8l-4.8 4.8-.7-.7 3.6-3.6H7v-1h9.4L12.8 4l.7-.7z" />
                                    <path d="M9 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h7c1.1 0 2-.9 2-2v-3.3h-1V14c0 .5-.5 1-1 1H2c-.5 0-1-.5-1-1V2c0-.5.5-1 1-1h7c.5 0 1 .5 1 1v3h1V2c0-1.1-.9-2-2-2zm1 8.5h1v-1h-1v1z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>)
            }
        }

    }

    render() {
        const { apartments, members, deletingApplication } = this.props;
        return (
            <>
                <div className="container">
                    <div className="row justify-content-center">
                        {apartments.isFetching || members.isFetching ?
                            <div className="loader"></div>
                            : this.getCorrectPage(apartments.results, members, deletingApplication.isFetching)
                        }
                        {apartments.isFetching || members.isFetching ?
                            null
                            : this.hasApartment(members)}
                    </div>
                </div>
                {!members.isFetching && members.results.length > 0 ? 
                <UpdatePhoneModal id="UpdatePhoneModal" members={members} fetchMember={this.fetchMemberInfo.bind(this)}/> : null
                }
            </>
        )
    }
}

function mapStateToProps(state) {

    var f = {
        apartments: {},
        members: {},
        deletingApplication: {},
        updatePassword: {},
        applicationWindow: {},
    }
    f.apartments = (state.apartments === undefined) ? {} : state.apartments
    f.members = (state.members === undefined) ? {} : state.members
    f.deletingApplication = (state.deletingApplication === undefined) ? {} : state.deletingApplication
    f.updatePassword = (state.updatePassword === undefined) ? {} : state.updatePassword
    f.applicationWindow = (state.applicationWindow === undefined) ? {} : state.applicationWindow
    return f;
}

export default connect(mapStateToProps)(MyPages);
