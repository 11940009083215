import React from 'react';
import { getPrettierDateFormat } from '../../utils';
import { throws } from 'assert';
import { SvgEditIcon } from '../svg'
const kennitala = require('kennitala');

class MyPaymentInformation extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isCardHolderValid: true,
            isValidUntilValid: true,
            isCardNumberValid: true,
            isCvcNumberValid: true,
            paymentInformation: {
                cardHolder: '',
                validUntil: '',
                cardNumber: '',
                cvc: '',
            },
        }
        this.isPaymentInformationValid = this.isPaymentInformationValid.bind(this)
    }
    componentDidMount() {
        $('.edit-icon').click(function () {
            $(this).prev('input').focus();
        })
        slideLabelsUp();
        updateValidUntil();
    }


    handleChange(event) {
        const { name, value } = event.target;

        this.setState({
            [name]: value
        })
    }

    focusOnInput(id) {
      document.getElementById(id).select();
    }

    updatePaymentInformation() {
        this.props.updatePaymentInformation(this.state.paymentInformation)   
    }

    isPaymentInformationValid() {
        return this.state.paymentInformation.cardNumber.length > 10 && this.state.paymentInformation.cardHolder.length > 3 && this.state.paymentInformation.validUntil.length === 5 && this.state.paymentInformation.cvc.length >= 3
    }


    handlePaymentInputChange(event) {
        const { name, value } = event.target;
        this.setState({
            paymentInformation: {
                ...this.state.paymentInformation,
                [name]: value
            }
        })
        
    }

    render() {
        let askellData = this.props.askellData;

        return (
            <div className="col-12 col-lg-7 my-information">
                <div className="row payment-info">
                    <h2 className="col-12 pl-0 pr-0">Mínar greiðsluupplýsingar</h2>
                    <div className="col-12 section-title">Virkar áskriftir</div>
                    {askellData.map((subscription) => {
                            return <div className="col-12">{subscription.reference}</div>
                        })}
                </div>
                
                    <div className="col-12 info-section"> 
                    <div className="row">
                        <div className="col-12 pl-0 pr-0 section-title">Greiðsluupplýsingar fyrir félagsaðild</div>
                        { this.props.creditCardUpdate.isFetching &&
                            <div class="alert alert-info">Vista upplýsingar, augnablik</div>
                        }
                        { this.props.creditCardUpdate.status === "success" && !this.props.creditCardUpdate.isFetching &&
                            <div class="alert alert-success">Nýjar greiðsluupplýsingar hafa verið vistaðar</div>
                        }
                        { this.props.creditCardUpdate.status === "error" && !this.props.creditCardUpdate.isFetching &&
                            <div class="alert alert-danger">Villa kom upp við að vista greiðslukort. {this.props.creditCardUpdate.errorMsg }</div>
                        }

                        <div className="col-12">
                            <form className="row" action="{% pageurl page %}" method="POST">
                                {!this.state.formvalid ? <div className="pl-0 validation-warning col-12">Fylla verður rétt út í alla reiti í greiðsluforminu.</div> : null}
                                {this.state.responseStatus === "error" ? <div className="validation-warning col-12">{this.state.responseMessage}</div> : null}
                                <div className="col-12 col-md-7 form-group">
                                    <input
                                        id="cardHolder"
                                        name="cardHolder"
                                        type="text"
                                        value={this.state.paymentInformation.cardHolder} // Prop: The kennitala input data
                                        onChange={this.handlePaymentInputChange.bind(this)}
                                        required // Prop: Puts data into state
                                        
                                    />
                                    <label className="col-form-label animate-label" htmlFor="cardHolder">Korthafi</label>
                                    <span className="edit-icon" onClick={() => this.focusOnInput('cardHolder')}><SvgEditIcon/></span>
                                    {!this.state.isCardHolderValid ? <div className="validation-warning pl-0 col-12">Slá þarf inn fullt nafn.</div> : null}
                                </div>
                                <div className="col-12 col-md-4 form-group">
                                    <input
                                        id="validUntil"
                                        name="validUntil"
                                        type="text"
                                        maxLength="5"
                                        value={this.state.paymentInformation.validUntil} // Prop: The kennitala input data
                                        onChange={this.handlePaymentInputChange.bind(this)}
                                        required // Prop: Puts data into state
                                    />
                                    <label className="col-form-label animate-label" htmlFor="validUntil">Gildistími (MM/ÁÁ)</label>
                                    <span className="edit-icon" onClick={() => this.focusOnInput('validUntil')}><SvgEditIcon/></span>
                                    {!this.state.isValidUntilValid ? <div className="validation-warning pl-0 col-12">Gildistími skal vera á forminu MM/ÁÁ.</div> : null}

                                </div>
                                <div className="col-12 col-md-7 form-group">
                                    <input
                                        id="cardNumber"
                                        name="cardNumber"
                                        type="number"
                                        value={this.state.paymentInformation.cardNumber} // Prop: The kennitala input data
                                        onChange={this.handlePaymentInputChange.bind(this)}
                                        required // Prop: Puts data into state
                                    />
                                    <label className="col-form-label animate-label" htmlFor="cardNumber">Kortanúmer</label>
                                    <span className="edit-icon" onClick={() => this.focusOnInput('cardNumber')}><SvgEditIcon/></span>
                                    {!this.state.isCardNumberValid ? <div className="validation-warning pl-0 col-12">Slá þarf inn gilt kortanúmer.</div> : null}
                                </div>
                                <div className="form-group col-3 col-md-2">
                                    <input
                                        id="cvc"
                                        name="cvc"
                                        type="number"
                                        value={this.state.paymentInformation.cvc} // Prop: The kennitala input data
                                        onChange={this.handlePaymentInputChange.bind(this)}
                                        required // Prop: Puts data into state
                                    />
                                    <label className="col-form-label animate-label" htmlFor="cvc">CVC</label>
                                    <span className="edit-icon" onClick={() => this.focusOnInput('cvc')}><SvgEditIcon/></span>
                                    {!this.state.isCvcNumberValid ? <div className="validation-warning pl-0 col-12">CVC of stutt.</div> : null}
                                </div>

                                <div className="col-12 text-center">
                                {!this.props.creditCardUpdate.isFetching ?
                                    <a className={this.isPaymentInformationValid() ? "btn-primary btn-red" : "btn-primary btn-red disabled"} onClick={this.updatePaymentInformation.bind(this)}>Vista</a>
                                    :
                                    <a className={"btn-primary btn-red disabled"}>Vista<div className="spinner-border"></div></a>
                                }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

MyPaymentInformation.defaultProps = { }


export default MyPaymentInformation;