import React from 'react';

import { fetchApartmentSearch, setSearchParams, clearSearch, removeSearchParams, setPostalCode } from '../../api/search/actions'
import { fetchPostalCodes } from '../../api/postalCodes/actions'
import { connect } from 'react-redux'
import ApartmentCard from '../apartment-card';


import { Range } from 'rc-slider';

class SearchBox extends React.Component {

    constructor(props) {
        super(props)
        this.searchRef = React.createRef()
        this.modifySearchRef = React.createRef()
        if (window.location.search !== "") {
            this.state = {
                Reykjavík: false,
                Kópavogur: false,
                Hafnarfjörður: false,
                Seltjarnarnes: false,
                Garðabær: false,
                Mosfellsbær: false,
                checked: {
                    Reykjavík: false,
                    Kópavogur: false,
                    Hafnarfjörður: false,
                    Seltjarnarnes: false,
                    Garðabær: false,
                    Mosfellsbær: false,
                },
                hasBeenSearched: true,
                apartmentSize: [30, 300],
                nrOfRooms: [1, 5],
                streetname: "",
            }
            var queryString = window.location.search.slice(1);
            if (queryString) {
                var arr = queryString.split('&');
                for (var i = 0; i < arr.length; i++) {
                    var a = arr[i].split('=');
                    var paramName = a[0];
                    var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
                    if (paramName == "sf") {
                        let state = { ...this.state }
                        state.apartmentSize[0] = parseInt(paramValue);
                    } else if (paramName == "st") {
                        let state = { ...this.state }
                        state.apartmentSize[1] = parseInt(paramValue);
                    } else if (paramName == "hf") {
                        let state = { ...this.state }
                        state.nrOfRooms[0] = parseInt(paramValue);
                    } else if (paramName == "ht") {
                        let state = { ...this.state }
                        state.nrOfRooms[1] = parseInt(paramValue);
                    }
                }
            }
        } else {
            this.state = {
                Reykjavík: false,
                Kópavogur: false,
                Hafnarfjörður: false,
                Seltjarnarnes: false,
                Garðabær: false,
                Mosfellsbær: false,
                checked: {
                    Reykjavík: false,
                    Kópavogur: false,
                    Hafnarfjörður: false,
                    Seltjarnarnes: false,
                    Garðabær: false,
                    Mosfellsbær: false,
                },
                hasBeenSearched: false,
                apartmentSize: [30, 300],
                nrOfRooms: [1, 5]
            }
        }

        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(fetchPostalCodes(this.props.lfb));
        this.props.hasBeenSearched(this.state.hasBeenSearched);
        if (window.location.search !== "") {
            var queryString = window.location.search.slice(1);
            var searchObj = {};
            if (queryString) {
                var arr = queryString.split('&');
                for (var i = 0; i < arr.length; i++) {
                    var a = arr[i].split('=');
                    var paramName = a[0];
                    var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
                    searchObj[paramName] = paramValue;
                    if (paramName == "pn") {
                        let pnrs = paramValue.split(",");
                        pnrs.map((activePnr) => {
                            this.props.store.dispatch(setPostalCode({ key: activePnr, value: true }))
                            this.props.apartmentSearch.postalCodes.map((area) => {
                                area.postalCodes.map((pnr) => {
                                    if (pnr == activePnr) {
                                        let state = { ...this.state }
                                        state.checked[area.name] = true;
                                        this.setState(state);
                                        this.setState({
                                            [area.name]: true
                                        });
                                    }
                                })
                            })
                        })
                    } else if (paramName == "gh") {
                        this.setState({
                            streetname: paramValue
                        })
                    }
                    Promise.resolve(this.props.store.dispatch(setSearchParams({ key: paramName, value: paramValue })))
                }
                Promise.resolve(this.props.dispatch(fetchApartmentSearch(this.props.lfb, this.props.apartmentSearch.searchParams)));
            }
            this.props.hasBeenSearched(true);
            window.scrollTo(0, this.searchRef.current.offsetTop);
        }
    }

    onChange(key, event) {
        if (key == 'gh') {
            this.setState({
                streetname: event.currentTarget.value
            })
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: key, value: event.currentTarget.value })))
        } else {
            if (event.currentTarget.checked) {
                Promise.resolve(this.props.store.dispatch(setSearchParams({ key: key, value: event.currentTarget.checked })))
            } else {
                Promise.resolve(this.props.store.dispatch(removeSearchParams({ key: key, value: event.currentTarget.checked })))
            }

        }
    }

    onSliderChange(key1, key2, value) {
        if (key1 == 'sf' && key2 == 'st') {
            this.setState({
                apartmentSize: [value[0], value[1]]
            })
        } else if (key1 == 'hf' && key2 == 'ht') {
            this.setState({
                nrOfRooms: [value[0], value[1]]
            })
        }

        Promise.resolve(this.props.store.dispatch(setSearchParams({ key: key1, value: value[0] })))
        if (key2 == 'st' && value[1] == 200) {
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: key2, value: 300 })))
        } else {
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: key2, value: value[1] })))
        }
    }

    onSearch() {
        if (Object.keys(this.props.apartmentSearch.searchParams).length > 0) {
            let params = this.props.apartmentSearch.searchParams;
            var querystring = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            Promise.resolve(this.props.dispatch(fetchApartmentSearch(this.props.lfb, this.props.apartmentSearch.searchParams))).then(window.location.search = querystring);
        } else if (Object.keys(this.props.apartmentSearch.searchParams).length === 0 && window.location.search !== "")
            Promise.resolve(this.props.dispatch(fetchApartmentSearch(this.props.lfb, this.props.apartmentSearch.searchParams))).then(window.location.search = "");
        else {
            Promise.resolve(this.props.dispatch(fetchApartmentSearch(this.props.lfb, this.props.apartmentSearch.searchParams)));
        }
        this.props.hasBeenSearched(true);
        this.setState({
            hasBeenSearched: true,
        })
        window.scrollTo(0, this.searchRef.current.offsetTop);
    }

    showAmountOfApartments(amount) {
        if (this.props.apartmentSearch.isFetching) {
            return (<div className="col-6 col-md-4 col-lg-6 search-results-count"><span className="count-span">Verið er að sækja allar íbúðir Búseta...</span></div>)
        } else {
            if (amount > 1) {
                return (<div className="col-6 col-md-4 col-lg-6 search-results-count"><span>{amount}</span> <span className="count-span">íbúðir fundust</span></div>)
            } else if (amount == 1) {
                return (<div className="col-6 col-md-4 col-lg-6 search-results-count"><span>{amount}</span> <span className="count-span">íbúð fannst</span></div>)
            } else if (amount == 0) {
                return (<div className="col-6 col-md-4 col-lg-6 search-results-count"><span className="count-span">Engin íbúð fannst</span></div>)
            }
        }   
    }

    showChosenApartments(apartments, searchParams) {
        if (apartments) {
            return (<ApartmentCard apartments={apartments} searchParams={searchParams} lfb={this.props.lfb} />)
        }
    }

    clearSearch() {
        Object.entries(this.props.apartmentSearch.searchParams).map((entry) => {
            if (entry[0] == 'pn') {
                let pnrs = entry[1].split(",");
                pnrs.map((pnr) => {
                    Promise.resolve(this.props.store.dispatch(setPostalCode({ key: pnr, value: false })));
                })
            };
            Promise.resolve(this.props.store.dispatch(removeSearchParams({ key: entry[0], value: entry[1] })))
        })
        this.setState({
            Reykjavík: false,
            Kópavogur: false,
            Hafnarfjörður: false,
            Seltjarnarnes: false,
            Garðabær: false,
            Mosfellsbær: false,
            checked: {
                Reykjavík: false,
                Kópavogur: false,
                Hafnarfjörður: false,
                Seltjarnarnes: false,
                Garðabær: false,
                Mosfellsbær: false,
            },
            hasBeenSearched: false,
            apartmentSize: [30, 300],
            nrOfRooms: [1, 5],
            streetname: "",
        })
        this.streetnameInput.value = "";

    }

    buildPnList(pnr, event) {
        var activePns = [];
        if (event) {
            if (event.currentTarget.checked) {
                this.props.store.dispatch(setPostalCode({ key: pnr, value: true }))
            } else if (!event.currentTarget.checked) {
                this.props.store.dispatch(setPostalCode({ key: pnr, value: false }))
                { this.shouldRemoveCheckFromCity(pnr) }
            }
        }

        let pns = this.props.apartmentSearch.postCodes;
        activePns = Object.keys(pns).filter(function (k) { return pns[k] }).map(Number).join()
        if (activePns == "") {
            Promise.resolve(this.props.store.dispatch(removeSearchParams({ key: 'pn', value: activePns })))
        } else {
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: 'pn', value: activePns })))
        }
    }

    buildPnListFromCityLabelClick(pnr, val) {
        var activePns = [];
        this.props.store.dispatch(setPostalCode({ key: pnr, value: val }))
        if (val === false) {
            this.shouldRemoveCheckFromCity(pnr)
        }

        let pns = this.props.apartmentSearch.postCodes;
        activePns = Object.keys(pns).filter(function (k) { return pns[k] }).map(Number).join()
        if (activePns == "") {
            Promise.resolve(this.props.store.dispatch(removeSearchParams({ key: 'pn', value: activePns })))
        } else {
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: 'pn', value: activePns })))
        } 
    }

    shouldRemoveCheckFromCity(pnr) {
        let removeCity = ""
        this.props.apartmentSearch.postalCodes.map((area) => {
            area.postalCodes.find((el) => {
                if (el == pnr) {
                    removeCity = area.name
                }
            })
            return removeCity
        })
        this.setState(prevState => ({
            checked: {
                ...prevState.checked,
                [removeCity]: false
            }
        }))
    }

    shouldAreaBeChecked(area) {
        let activePns = [];
        let pns = this.props.apartmentSearch.postCodes;
        activePns = Object.keys(pns).filter(function (k) { return pns[k] }).map(Number)

        let shouldBeChecked = false;
        let count = 0;


        activePns.map((activePn) => {
            Object.values(area.postalCodes).map((pn) => {
                if (pn == activePn) {
                    count = count + 1
                    if (count == Object.values(area.postalCodes).length) {
                        shouldBeChecked = true;
                    }
                }
            })
        })
        return shouldBeChecked;
    }

    shouldPnrBeChecked(postCode) {
        let postCodes = this.props.apartmentSearch.postCodes;
        let shouldPnrBeChecked = false;
        Object.keys(postCodes).filter(function (k) {
            return postCodes[k]
        })
            .map(Number)
            .find((el) => {
                if (el == postCode) {
                    shouldPnrBeChecked = true;
                }
            })
        return (shouldPnrBeChecked);
    }

    showAllPns(area) {
        if (this.state[area.name]) {
            return (area.postalCodes.map((postCode) => {
                return (
                    <li className={this.shouldPnrBeChecked(postCode) ? "town-checkbox selected" : "town-checkbox"} key={postCode}>
                        <input type="checkbox" id={postCode} checked={this.shouldPnrBeChecked(postCode)} defaultChecked={this.state.checked[area.name]} onChange={this.buildPnList.bind(this, postCode)} />
                        <span className="checkmark"></span>
                        <label htmlFor={postCode}>{postCode} - {area.name}</label>
                    </li>)
            }))
        }
    }

    pnrCheckboxClick(area, event) {
        let state = { ...this.state }
        state.checked[area.name] = event.currentTarget.checked;
        this.setState(state)

        this.setState({
            [area.name]: event.currentTarget.checked
        })

        return (area.postalCodes.map((pnr) => {
            { this.buildPnList(pnr, event) }
        }))
    }


    pnrLabelClick(area, e) {
        let state = { ...this.state }
        state.checked[area.name] = !state.checked[area.name];
        this.setState(state)

        return (area.postalCodes.map((pnr) => {
            { this.buildPnListFromCityLabelClick(pnr, state.checked[area.name]) }
        }))    
    }

    showPnsWithAvalableApartments(postCodeAreas) {
        let hasApartments = this.props.postalCodes.results;
        let visiblePostalCodes = {};
        postCodeAreas.map((area) => {
            area.postalCodes.map((postCode) => {
                if (hasApartments.indexOf(postCode.toString()) > -1) {
                    if (Object.keys(visiblePostalCodes).indexOf(area.name) > -1) {
                        visiblePostalCodes[area.name]["postalCodes"].push(postCode);
                    } else {
                        visiblePostalCodes[area.name] = { name: area.name, postalCodes: [postCode] }
                    }
                }
            })
        })
        return Object.values(visiblePostalCodes)
    }

    modifySearch() {
        window.scrollTo(0, 0);
    }

    render() {
        let postCodeAreas = this.props.apartmentSearch.postalCodes;
        let visiblePostCodeAreas = this.showPnsWithAvalableApartments(postCodeAreas);
        let apartments = this.props.apartmentSearch.results;
        return (
            <>
                <div className={this.state.hasBeenSearched ? "row clear-search justify-content-end d-none d-lg-flex" : "row clear-search justify-content-end d-none d-lg-flex hidden"}>
                    <div className="col-2 text-right" onClick={this.clearSearch.bind(this)} >
                        <span>Hreinsa val</span>
                        <svg className="clear-search-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 14">
                            <path fill="#686c6e" d="M8.2 11.2V3.7c0-.2-.1-.3-.3-.3-.2 0-.3.1-.3.3v7.6c0 .2.1.3.3.3.2-.1.3-.2.3-.4zm-2.8 0V3.7c0-.2-.1-.3-.3-.3-.2 0-.3.1-.3.3v7.6c0 .2.1.3.3.3.2-.1.3-.2.3-.4zm5.9-7.8c-.2 0-.3.1-.3.3v9.1c0 .4-.3.7-.8.7H2.8c-.4 0-.8-.3-.8-.7V3.7c0-.2-.1-.3-.3-.3s-.3.1-.3.3v9.1c0 .6.6 1.2 1.4 1.2h7.5c.8 0 1.4-.6 1.4-1.3v-9c-.1-.2-.2-.3-.4-.3zM12.7 2H9.6V.3c0-.2-.2-.3-.3-.3H3.7c-.1 0-.3.1-.3.3V2H.3c-.2 0-.3.1-.3.2 0 .2.1.3.3.3h12.4c.2 0 .3-.1.3-.3 0-.1-.1-.2-.3-.2zM9 2H4V.6h5V2z" />
                        </svg>
                    </div>
                </div>
                <div className="row search-box" ref={this.modifySearchRef}>
                    <div className="col-12 col-lg-3 town-selector">
                        <div className={this.state.hasBeenSearched ? "clear-search d-lg-none" : "clear-search d-lg-none hidden"} onClick={this.clearSearch.bind(this)}>
                            <span>Hreinsa val</span>
                            <svg className="clear-search-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 14">
                                <path fill="#686c6e" d="M8.2 11.2V3.7c0-.2-.1-.3-.3-.3-.2 0-.3.1-.3.3v7.6c0 .2.1.3.3.3.2-.1.3-.2.3-.4zm-2.8 0V3.7c0-.2-.1-.3-.3-.3-.2 0-.3.1-.3.3v7.6c0 .2.1.3.3.3.2-.1.3-.2.3-.4zm5.9-7.8c-.2 0-.3.1-.3.3v9.1c0 .4-.3.7-.8.7H2.8c-.4 0-.8-.3-.8-.7V3.7c0-.2-.1-.3-.3-.3s-.3.1-.3.3v9.1c0 .6.6 1.2 1.4 1.2h7.5c.8 0 1.4-.6 1.4-1.3v-9c-.1-.2-.2-.3-.4-.3zM12.7 2H9.6V.3c0-.2-.2-.3-.3-.3H3.7c-.1 0-.3.1-.3.3V2H.3c-.2 0-.3.1-.3.2 0 .2.1.3.3.3h12.4c.2 0 .3-.1.3-.3 0-.1-.1-.2-.3-.2zM9 2H4V.6h5V2z" />
                            </svg>
                        </div>
                        <ul>
                            {visiblePostCodeAreas.map((area) => {
                                return (
                                    <li className={(this.state.checked[area.name] || this.shouldAreaBeChecked(area)) ? "town-checkbox selected" : "town-checkbox"} key={area.name}>
                                        <input type="checkbox" id={area.name} onChange={this.pnrCheckboxClick.bind(this, area)} checked={this.state.checked[area.name] || this.shouldAreaBeChecked(area)} />
                                        <span className="checkmark"></span>
                                        <label onClick={this.pnrLabelClick.bind(this, area)}>{area.name}</label>
                                        <ul className={"pnr-list"}>
                                            {this.showAllPns(area)}
                                        </ul>
                                    </li>)
                            })
                            }
                        </ul>
                    </div>
                    <div className="col-12 col-lg-6 size-selector">
                        <div className="row form-group">
                            <div className="col-3 text-right d-none d-md-block">
                                <label className="address-name">Götuheiti</label>
                                <label>Stærð</label>
                                <label>Herbergi</label>
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 sliders">
                                <input className="address-input d-none d-md-block" type="text" name="houseInput" value={this.props.apartmentSearch.searchParams.gh ? this.props.apartmentSearch.searchParams.gh : this.state.streetname} onChange={this.onChange.bind(this, 'gh')} ref={el => this.streetnameInput = el} />
                                <input className="address-input d-md-none" type="text" name="houseInput" placeholder="Götuheiti" value={this.props.apartmentSearch.searchParams.gh ? this.props.apartmentSearch.searchParams.gh : this.state.streetname} onChange={this.onChange.bind(this, 'gh')} />
                                <label className="col-12 d-block d-md-none">Stærð</label>
                                <Range className="range" value={this.state.apartmentSize} allowCross={false} min={30} max={200} onChange={this.onSliderChange.bind(this, 'sf', 'st')} marks={{ 30: 30, 60: 60, 90: 90, 120: 120, 150: 150, 180: 180, 200: "200 +" }} />
                                <label className="col-12 d-block d-md-none">Herbergi</label>
                                <Range className="range" value={this.state.nrOfRooms} allowCross={false} min={1} max={5} onChange={this.onSliderChange.bind(this, 'hf', 'ht')} marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }} />
                            </div>
                            <div className="col-3"></div>
                            <div className="col-9 pl-0 text-center d-none d-lg-block">
                                <div className="type-checkbox">
                                    <input id="isApartmentFree" type="checkbox" onChange={this.onChange.bind(this, 'laust')} checked={this.props.apartmentSearch.searchParams.laust} />
                                    <span className="checkmark"></span>
                                    <label className="show-available-apartments" htmlFor="isApartmentFree">Sýna eingöngu lausar íbúðir</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 type-selector">
                        <div className="row">
                            <div className="col-6">
                                <div className="type-checkbox">
                                    <input id="isApartment" type="checkbox" onChange={this.onChange.bind(this, 'fb')} checked={this.props.apartmentSearch.searchParams.fb} />
                                    <span className="checkmark"></span>
                                    <label htmlFor="isApartment">Fjölbýli</label>
                                </div>
                                <div className="type-checkbox">
                                    <input id="hasElevator" type="checkbox" onChange={this.onChange.bind(this, 'l')} checked={this.props.apartmentSearch.searchParams.l} />
                                    <span className="checkmark"></span>
                                    <label htmlFor="hasElevator">Lyfta</label>
                                </div>
                                <div className="type-checkbox">
                                    <input id="hasGarage" type="checkbox" onChange={this.onChange.bind(this, 'bs')} checked={this.props.apartmentSearch.searchParams.bs} />
                                    <span className="checkmark"></span>
                                    <label htmlFor="hasGarage">Bílskúr</label>
                                </div>
                                <div className="type-checkbox">
                                    <input id="hasSeparateEntrance" type="checkbox" onChange={this.onChange.bind(this, 'si')} checked={this.props.apartmentSearch.searchParams.si} />
                                    <span className="checkmark"></span>
                                    <label htmlFor="hasSeparateEntrance">Sérinngangur</label>
                                </div>
                                <div className="type-checkbox">
                                    <input id="hasGarageSpace" type="checkbox" onChange={this.onChange.bind(this, 'bg')} checked={this.props.apartmentSearch.searchParams.bg} />
                                    <span className="checkmark"></span>
                                    <label htmlFor="hasGarageSpace">Bílageymsla</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center d-lg-none show-available">
                        <div className="row justify-content-center">
                            <div className="col-6 type-checkbox">
                                <input id="isFree" type="checkbox" onChange={this.onChange.bind(this, 'laust')} checked={this.props.apartmentSearch.searchParams.laust} />
                                <span className="checkmark"></span>
                                <label htmlFor="isFree">Sýna eingöngu lausar íbúðir</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 search-button text-center">
                        <a className="btn-primary btn-red" onClick={this.onSearch.bind(this)}>Leita</a>
                    </div>
                </div>
                <div className={this.state.hasBeenSearched ? "row search-results" : "row search-results hidden"} ref={this.searchRef}>
                    {this.showAmountOfApartments(apartments.length)}
                    <div className="col-12 edit-search" onClick={this.modifySearch.bind(this)}>
                        <svg className="edit-icon" xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 13 13" width="16" height="16">
                            <path id="Path" d="M4.8 13c-.1 0-.1 0-.2-.1s-.1-.3 0-.4l5.6-5.7-4-4L.6 8.4c-.1.1-.3.1-.4 0-.1-.1-.1-.3 0-.4L6 2.2c.1-.1.3-.1.4 0l4.4 4.5c.1.1.1.3 0 .4L5 12.9c-.1.1-.1.1-.2.1z" className="st0" />
                            <path id="Shape" d="M10.6 7.1c-.1 0-.1 0-.2-.1L6 2.6c-.1-.1-.1-.3 0-.4l1-1c.1-.1.1-.1.2-.1s.1 0 .2.1l4.4 4.5c.2 0 .2.2.1.3l-1 1c-.1.1-.2.1-.3.1zm-4-4.7l4.1 4.1.6-.6-4.1-4.2-.6.7z" className="st0" />
                            <path d="M11.7 6.1c-.1 0-.1 0-.2-.1L7 1.5c-.1-.1-.1-.2 0-.3l.6-.6c.7-.7 1.7-.7 2.4 0L12.5 3c.3.3.5.7.5 1.2s-.2.8-.5 1.2l-.6.6c-.1.1-.2.1-.2.1zM7.6 1.3l4.1 4.1.4-.4c.4-.4.4-1.2 0-1.6L9.7.9C9.3.5 8.5.5 8.1.9l-.5.4zM4.8 13H.3c-.1 0-.1 0-.2-.1s-.1-.2-.1-.2l.1-4.4c0-.1 0-.2.1-.2.1-.1.2-.1.3-.1l1.8.8c.1 0 .1.1.2.2l.3 1.2 1.5.3c.1 0 .2.1.2.2l.6 1.9v.2c-.1.2-.2.2-.3.2zm-4.2-.6h3.9L4 11l-1.5-.3c-.1 0-.2-.1-.2-.2l-.4-1.3-1.3-.5v3.7z" className="st0" />
                            <path d="M2.1 12.7L.4 11v1.7h1.7zm0-3.4s-.1 0-.1-.1c-.2-.1-.2-.2 0-.3l5.2-5.2c.1-.1.3-.1.4 0 .1.1.1.3 0 .4L2.3 9.2c0 .1-.1.1-.2.1zm2 1.8c-.1 0-.1 0-.2-.1s-.1-.3 0-.4l5.2-5.2c.1-.1.3-.1.4 0 .1.1.1.3 0 .4L4.3 11c0 .1-.1.1-.2.1z" className="st0" />
                        </svg>
                        <div className="edit-icon-text">Breyta leit</div>
                    </div>
                </div>
                <div className="row">
                    {this.showChosenApartments(apartments, this.props.apartmentSearch)}
                </div>
            </>
        )
    }
}

SearchBox.defaultProps = {
}

function mapStateToProps(state) {

    var f = {
        apartmentSearch: {},
        postalCodes: {},
        searchParams: {}
    }
    f.apartmentSearch = (state.apartmentSearch === undefined) ? {} : state.apartmentSearch
    f.postalCodes = (state.postalCodes === undefined) ? {} : state.postalCodes
    f.searchParams = (state.searchParams === undefined) ? {} : state.searchParams
    return f;
}

export default connect(mapStateToProps)(SearchBox);