import React from 'react';
var lookup = require('binlookup')()

const cardBlackList = ['45398600', '45398601']


class CardForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            paymentObj: {
                cardHolder: '',
                cardNumber: '',
                validUntil: '',
                cvc: '',
            },
            errors: {
                cardHolder: '',
                cardNumber: '',
                validUntil: '',
                cvc: '',
            },
            paymentButtonText: "Greiða",
            isPaying: false,
        }

        this.handleChange = this.handleChange.bind(this)

        this.cardHolder = React.createRef()
        this.cardNumber = React.createRef()
        this.validUntil = React.createRef()
        this.cvc = React.createRef()
    }

    componentDidUpdate() {
        animateLabels();
        slideLabelsUp();
        updateValidUntil();
    }

    isFormValid() {
        let errors = this.state.errors
        let valid = true;

        let paymentInfo = this.state.paymentObj;
        let nameIncludesSpace = /\s/.test(paymentInfo.cardHolder);
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isEmailValid = re.test(String(this.props.email).toLowerCase());
        let validUntilContainsSlash = paymentInfo.validUntil.includes("/");

        Object.keys(errors).forEach(key => {
            switch (key) {
                case 'cardHolder':
                    let cardHolderVal = this.state.paymentObj.cardHolder;
                    if (cardHolderVal === '' || !nameIncludesSpace) {
                        errors.cardHolder = 'Slá þarf inn fullt nafn.'
                        valid = false;
                    } else {
                        errors.cardHolder = ''
                    }
                    break;

                case 'cardNumber':
                    let cardNumberVal = this.state.paymentObj.cardNumber.replace(" ", "");
                    if (cardNumberVal === '' || cardNumberVal.length < 16) {
                        errors.cardNumber = 'Slá þarf inn gilt kortanúmer.'
                        valid = false;
                    } else {
                        errors.cardNumber = ''
                    }
                    break;

                case 'validUntil':
                    let validUntilVal = this.state.paymentObj.validUntil;
                    ;
                    if (validUntilVal.length < 5 || !validUntilContainsSlash) {
                        errors.validUntil = 'Gildistími skal vera á forminu MM/ÁÁ.'
                        valid = false;
                    } else {
                        errors.validUntil = ''
                    }
                    break;
                case 'cvc':
                    let cvcVal = this.state.paymentObj.cvc;
                    if (cvcVal.length < 3) {
                        errors.cvc = 'CVC of stutt.'
                        valid = false;
                    } else {
                        errors.cvc = ''
                    }
                    break;

                default:
                    break;

            }
        });
        this.setState({ errors: errors })
        return valid;
    }
    
    submitPayment() {
        this.setState({
            paymentButtonText: 'Augnablik...',
            isPaying: true,
        })
    
        let isFormValid = this.isFormValid();
        let cardNumber = this.state.paymentObj.cardNumber;
        let firstEight = cardNumber.substr(0, 8)
    
        lookup(firstEight)
            .then(data => {
                this.setState({
                    cardType: data.type,
                });
                
                if (data.type === "debit" && !cardBlackList.includes(firstEight) && isFormValid) {
                    //here we have to submit payment and return any errors, if payment goes through we have to show a confirmation - if not we have to raise the errors
                    if (this.state.cardTypeError) {
                        delete this.state.cardTypeError
                    }
                }
    
                if (cardBlackList.includes(firstEight) || data.type !== "debit") {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            cardNumber: 'Við greiðslu á staðfestingargjaldi eru einungis leyfðar kortafærslur með debitkorti.'
                        },
                    })
                }
                this.setState({
                    isPaying: false,
                    paymentButtonText: "Greiða"
                })
            })
            .catch(err => {
                this.setState({
                    cardTypeError: err,
                    paymentButtonText: "Greiða",
                    isPaying: false,
                    errors: {
                        ...this.state.errors,
                        cardNumber: 'Við greiðslu á staðfestingargjaldi eru einungis leyfðar kortafærslur með debitkorti.'
                    }
                })
                if (this.state.cardType) {
                    delete this.state.cardType
                }
            })
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            paymentObj: { ...this.state.paymentObj, [name]: value }
        })
    }

    displayContinueButton() {
        return (
            <div className="col-12 continue-button-wrapper text-center">
                <button
                    className="btn-primary btn-red"
                    type="button"
                    onClick={this.submitPayment.bind(this)}
                    disabled={this.state.isPaying}>
                    {this.state.paymentButtonText}
                </button>
            </div>
        )
    }

    render() {

        return (<div className="payment-information col-11">
                    <div className="row">
                        <div className="col-12 payment-info-label">Greiðslukort</div>
                        {this.state.responseStatus === "error" ? <div className="validation-warning col-12">{this.state.responseMessage}</div> : null}
                        <div className="form-group col-8">
                            <label className="col-form-label" htmlFor="cardHolder">Korthafi</label>
                            <input
                                className="form-control"
                                id="cardHolder"
                                name="cardHolder"
                                type="text"
                                value={this.state.paymentObj.cardHolder} // Prop: The kennitala input data
                                onChange={this.handleChange}
                                required // Prop: Puts data into state
                                autoFocus
                                ref={this.cardHolder}
                                disabled={this.state.transactionPayment}
                            />
                            {this.state.errors.cardHolder.length > 0 &&
                                <div className="validation-warning pl-0 col-12"> {this.state.errors.cardHolder} </div>
                            }
                        </div>
                        <div className="form-group col-8 col-md-4">
                            <label className="col-form-label" htmlFor="validUntil">Gildistími MM/ÁÁ</label>
                            <input
                                className="form-control"
                                id="validUntil"
                                name="validUntil"
                                type="text"
                                value={this.state.paymentObj.validUntil} // Prop: The kennitala input data
                                onChange={this.handleChange}
                                required // Prop: Puts data into state
                                ref={this.validUntil}
                                disabled={this.state.transactionPayment}
                            />
                            {this.state.errors.validUntil.length > 0 &&
                                <div className="validation-warning pl-0 col-12"> {this.state.errors.validUntil} </div>
                            }
                        </div>
                        <div className="form-group col-8">
                            <label className="col-form-label" htmlFor="cardNumber">Kortanúmer</label>
                            <input
                                className="form-control"
                                id="cardNumber"
                                name="cardNumber"
                                type="number"
                                value={this.state.paymentObj.cardNumber} // Prop: The kennitala input data
                                onChange={this.handleChange}
                                required // Prop: Puts data into state
                                ref={this.cardNumber}
                                disabled={this.state.transactionPayment}
                            />
                            {this.state.errors.cardNumber.length > 0 &&
                                <div className="validation-warning pl-0 col-12"> {this.state.errors.cardNumber} </div>
                            }
                        </div>
                        <div className="form-group col-3 col-md-2">
                            <label className="col-form-label" htmlFor="cvc">CVC</label>
                            <input
                                className="form-control"
                                id="cvc"
                                name="cvc"
                                type="number"
                                value={this.state.paymentObj.cvc} // Prop: The kennitala input data
                                onChange={this.handleChange}
                                required // Prop: Puts data into state
                                ref={this.cvc}
                                disabled={this.state.transactionPayment}
                            />
                            {this.state.errors.cvc.length > 0 &&
                                <div className="validation-warning pl-0 col-12"> {this.state.errors.cvc} </div>
                            }
                        </div>
                    </div>
                    {this.displayContinueButton()}
                </div>)
    }
}

CardForm.defaultProps = {
}


export default CardForm;