import React from "react";
import ReactDOM from "react-dom";
import { fetchEmptyApartments } from '../../api/apartments/actions'
import { connect } from 'react-redux'
import ApartmentCard from "../../components/apartment-card";


class ThreeEmptyApartments extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ibudOverwrites: "" }
  }

  componentDidMount() {
    let override = "laust=true";
    if (this.props.ibudOverwrites !== "") {
      override += "&overrides=" + this.props.ibudOverwrites;
    }
    Promise.resolve(this.props.dispatch(fetchEmptyApartments(this.props.lfb, override, 3)))
      .then(() => {
            if (this.props.apartments.results.length == 0) {
              $('.empty-apartments-block').addClass('d-none');
            }
      })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ibudOverwrites !== this.state.ibudOverwrites) {
      this.setState({ ibudOverwrites: this.props.ibudOverwrites })
    }
  }

  renderThreeApartments() {
    let singleOverwriteIds = [];
    let apartments = this.props.apartments.results
    let apartmentList = [];
    if (this.props.ibudOverwrites) {
      singleOverwriteIds = this.props.ibudOverwrites.toString().split(",");
      if (apartments.length > 0) {
        singleOverwriteIds.map((overwriteId) => {
          apartments.map((apartment) => {
            let apartmentId = apartment.id_ibud.toString();
            if (overwriteId === apartmentId && apartmentList.length < 3) {
              { apartmentList.push(apartment) }
            }
          })
        })
        while (apartmentList.length < 3) {
          let apart = apartments.pop();
          if (!apartmentList.includes(apart)) {
            apartmentList.push(apart)
          }
          if (apartments.length === 0) {
            break;
          }
        }
      } 
    } else {
      apartmentList = apartments.slice(0, 3);
    }

    if (this.props.apartments.isFetching) {
      return (<div className="spinner"></div>)
    } else {

      return (
        <div className={`${this.props.lfb === 'True' ? 'lfb' : ''} row three-apartments justify-content-center`}>
          <ApartmentCard apartments={apartmentList} prependUrl={'/ibudir/'} lfb={this.props.lfb} threeEmptyApart={true}/>
          <div className="col-12">
            <div className="text-center apartments-button">
              {apartmentList.length > 0 ?
                <a className="btn-primary btn-red" href="/ibudir/">Skoða allar lausar íbúðir</a> :
                <a className="btn-primary btn-red" href="/ibudir/#allar-ibudir">Skoða allar íbúðir Búseta</a>
              }
            </div>
          </div>
        </div>
      )
    }
  }



  render() {
    return (
      this.renderThreeApartments()
    )

  }
}

ThreeEmptyApartments.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    apartments: {},
  }
  f.apartments = (state.apartments === undefined) ? {} : state.apartments
  return f;
}

export default connect(mapStateToProps)(ThreeEmptyApartments);