import React from 'react';
import GoogleMap from '../map';


const modal = (props) => {
    
    return (
        props.store ? 
            <div className="modal-wrapper d-md-none"
                style={{
                    display: props.show ? 'block' : 'none'
                }}>
                <div className="modal-header">
                    <span className="close-modal-btn" onClick={props.close}>×</span>
                </div>
                <div className="modal-body">
                    <GoogleMap store={props.store}  singleCoordinate={true} apartmentId={props.apartmentId} leiga={props.leiga}/>
                </div>
            </div> : none
    )
}

export default modal;