import React from 'react'


export const SvgEditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 13 13">
      <path id="Path" d="M4.8 13c-.1 0-.1 0-.2-.1s-.1-.3 0-.4l5.6-5.7-4-4L.6 8.4c-.1.1-.3.1-.4 0-.1-.1-.1-.3 0-.4L6 2.2c.1-.1.3-.1.4 0l4.4 4.5c.1.1.1.3 0 .4L5 12.9c-.1.1-.1.1-.2.1z" className="st0" />
      <path id="Shape" d="M10.6 7.1c-.1 0-.1 0-.2-.1L6 2.6c-.1-.1-.1-.3 0-.4l1-1c.1-.1.1-.1.2-.1s.1 0 .2.1l4.4 4.5c.2 0 .2.2.1.3l-1 1c-.1.1-.2.1-.3.1zm-4-4.7l4.1 4.1.6-.6-4.1-4.2-.6.7z" className="st0" />
      <path d="M11.7 6.1c-.1 0-.1 0-.2-.1L7 1.5c-.1-.1-.1-.2 0-.3l.6-.6c.7-.7 1.7-.7 2.4 0L12.5 3c.3.3.5.7.5 1.2s-.2.8-.5 1.2l-.6.6c-.1.1-.2.1-.2.1zM7.6 1.3l4.1 4.1.4-.4c.4-.4.4-1.2 0-1.6L9.7.9C9.3.5 8.5.5 8.1.9l-.5.4zM4.8 13H.3c-.1 0-.1 0-.2-.1s-.1-.2-.1-.2l.1-4.4c0-.1 0-.2.1-.2.1-.1.2-.1.3-.1l1.8.8c.1 0 .1.1.2.2l.3 1.2 1.5.3c.1 0 .2.1.2.2l.6 1.9v.2c-.1.2-.2.2-.3.2zm-4.2-.6h3.9L4 11l-1.5-.3c-.1 0-.2-.1-.2-.2l-.4-1.3-1.3-.5v3.7z" className="st0" />
      <path d="M2.1 12.7L.4 11v1.7h1.7zm0-3.4s-.1 0-.1-.1c-.2-.1-.2-.2 0-.3l5.2-5.2c.1-.1.3-.1.4 0 .1.1.1.3 0 .4L2.3 9.2c0 .1-.1.1-.2.1zm2 1.8c-.1 0-.1 0-.2-.1s-.1-.3 0-.4l5.2-5.2c.1-.1.3-.1.4 0 .1.1.1.3 0 .4L4.3 11c0 .1-.1.1-.2.1z" className="st0" />
    </svg>
    )
}


export const SvgMobileIcon = () => {
  return (
    <svg className="mobile-icon" xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 15 27">
      <path id="Combined-Shape" d="M3 0h9c1.7 0 3 1.3 3 3v21c0 1.7-1.3 3-3 3H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3zM1 5v19c0 1.1.9 2 2 2h9c1.1 0 2-.9 2-2V5H1zm2-4c-1.1 0-2 .9-2 2v1h13V3c0-1.1-.9-2-2-2H3z" className="st0"/>
      <circle id="Oval" cx="7.8" cy="23.8" r="1.2" className="st0"/>
      <path id="Line_1_" d="M6 2.5h3"/>
      <path d="M9 2.9H6c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h3c.2 0 .4.2.4.4s-.2.4-.4.4z"/>
    </svg>
  )
}