import React from 'react';

import { updatePasswordTypes } from './actions'

const initialState = {
    isFetching: false,
    results: [],
}



export function updatePassword(state = initialState, action) {


    switch(action.type) {

        case updatePasswordTypes.REQUEST:
            state = {...state, isFetching: true,}
            state.isFetching = true

            return state

        case updatePasswordTypes.RECEIVE:
            state = {...state, isFetching: false}
            state.results = action.data

            return state

        default:
            return state
    }
}