import React from 'react';
import ReactDOM from 'react-dom'
import { getLocalDateFormat, currencyFormatter } from '../../utils';


class ApplicationRow extends React.Component {

    constructor(props) {
        super(props)

        this.state = {

        }

        this.navigateToApplication = this.navigateToApplication.bind(this)
    }

    navigateToApplication(ibudId, e) {
        window.history.pushState({ "tab": `#min_umsokn/`+ibudId }, "tab", `#min_umsokn/`+ibudId );
    }

    render() {
        const { index, item_index, application, applicationWindowOpen, lfb } = this.props
        if (lfb) {
            return (
                <div className="row application-row header my-applications" key={index}>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 c-2 apartment-reference" onClick={() => this.props.showApplication(application.ibudId)}>{application.address}</div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 c-2">{getLocalDateFormat(application.createad)}</div>
                    <div className="col-2 p-6">{ application.status }</div>
                    <div className="col-2 text-center pr-0 delete-btn-container">
                        <button data-toggle="modal" data-target={"#ApplicationDeleteModal" + application.recordId}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 14">
                                <path fill="#686c6e" d="M8.2 11.2V3.7c0-.2-.1-.3-.3-.3-.2 0-.3.1-.3.3v7.6c0 .2.1.3.3.3.2-.1.3-.2.3-.4zm-2.8 0V3.7c0-.2-.1-.3-.3-.3-.2 0-.3.1-.3.3v7.6c0 .2.1.3.3.3.2-.1.3-.2.3-.4zm5.9-7.8c-.2 0-.3.1-.3.3v9.1c0 .4-.3.7-.8.7H2.8c-.4 0-.8-.3-.8-.7V3.7c0-.2-.1-.3-.3-.3s-.3.1-.3.3v9.1c0 .6.6 1.2 1.4 1.2h7.5c.8 0 1.4-.6 1.4-1.3v-9c-.1-.2-.2-.3-.4-.3zM12.7 2H9.6V.3c0-.2-.2-.3-.3-.3H3.7c-.1 0-.3.1-.3.3V2H.3c-.2 0-.3.1-.3.2 0 .2.1.3.3.3h12.4c.2 0 .3-.1.3-.3 0-.1-.1-.2-.3-.2zM9 2H4V.6h5V2z" />
                            </svg>
                        </button>
                    </div>
                </div>
            )
        } else {
            return ([
                <div className="row application-row mx-0" key={index} index={index}>
                    <div className="col-2 col-sm-1">{item_index + 1 }</div>
                    <div className="col-10 col-sm-4">{application.apartment}</div>
                    <div className={applicationWindowOpen ? "col-3 col-sm-2" : "col-5 col-sm-3"}>{getLocalDateFormat(application.date)}</div>
                    <div className={applicationWindowOpen ? "col-5 col-sm-3 text-right p-0" : "col-7 col-sm-4 text-right p-0"}>{currencyFormatter(application.offer)} kr.</div>
                    {applicationWindowOpen &&
                        <div className="col-4 col-sm-2 text-center pr-0">
                            <button data-toggle="modal" data-target={"#ApplicationEditModal" + application.recordId}>
                                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 13 13">
                                    <path id="Path" d="M4.8 13c-.1 0-.1 0-.2-.1s-.1-.3 0-.4l5.6-5.7-4-4L.6 8.4c-.1.1-.3.1-.4 0-.1-.1-.1-.3 0-.4L6 2.2c.1-.1.3-.1.4 0l4.4 4.5c.1.1.1.3 0 .4L5 12.9c-.1.1-.1.1-.2.1z" className="st0" />
                                    <path id="Shape" d="M10.6 7.1c-.1 0-.1 0-.2-.1L6 2.6c-.1-.1-.1-.3 0-.4l1-1c.1-.1.1-.1.2-.1s.1 0 .2.1l4.4 4.5c.2 0 .2.2.1.3l-1 1c-.1.1-.2.1-.3.1zm-4-4.7l4.1 4.1.6-.6-4.1-4.2-.6.7z" className="st0" />
                                    <path d="M11.7 6.1c-.1 0-.1 0-.2-.1L7 1.5c-.1-.1-.1-.2 0-.3l.6-.6c.7-.7 1.7-.7 2.4 0L12.5 3c.3.3.5.7.5 1.2s-.2.8-.5 1.2l-.6.6c-.1.1-.2.1-.2.1zM7.6 1.3l4.1 4.1.4-.4c.4-.4.4-1.2 0-1.6L9.7.9C9.3.5 8.5.5 8.1.9l-.5.4zM4.8 13H.3c-.1 0-.1 0-.2-.1s-.1-.2-.1-.2l.1-4.4c0-.1 0-.2.1-.2.1-.1.2-.1.3-.1l1.8.8c.1 0 .1.1.2.2l.3 1.2 1.5.3c.1 0 .2.1.2.2l.6 1.9v.2c-.1.2-.2.2-.3.2zm-4.2-.6h3.9L4 11l-1.5-.3c-.1 0-.2-.1-.2-.2l-.4-1.3-1.3-.5v3.7z" className="st0" />
                                    <path d="M2.1 12.7L.4 11v1.7h1.7zm0-3.4s-.1 0-.1-.1c-.2-.1-.2-.2 0-.3l5.2-5.2c.1-.1.3-.1.4 0 .1.1.1.3 0 .4L2.3 9.2c0 .1-.1.1-.2.1zm2 1.8c-.1 0-.1 0-.2-.1s-.1-.3 0-.4l5.2-5.2c.1-.1.3-.1.4 0 .1.1.1.3 0 .4L4.3 11c0 .1-.1.1-.2.1z" className="st0" />
                                </svg>
                            </button>
                            <button data-toggle="modal" data-target={"#ApplicationDeleteModal" + application.recordId}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 14">
                                    <path fill="#686c6e" d="M8.2 11.2V3.7c0-.2-.1-.3-.3-.3-.2 0-.3.1-.3.3v7.6c0 .2.1.3.3.3.2-.1.3-.2.3-.4zm-2.8 0V3.7c0-.2-.1-.3-.3-.3-.2 0-.3.1-.3.3v7.6c0 .2.1.3.3.3.2-.1.3-.2.3-.4zm5.9-7.8c-.2 0-.3.1-.3.3v9.1c0 .4-.3.7-.8.7H2.8c-.4 0-.8-.3-.8-.7V3.7c0-.2-.1-.3-.3-.3s-.3.1-.3.3v9.1c0 .6.6 1.2 1.4 1.2h7.5c.8 0 1.4-.6 1.4-1.3v-9c-.1-.2-.2-.3-.4-.3zM12.7 2H9.6V.3c0-.2-.2-.3-.3-.3H3.7c-.1 0-.3.1-.3.3V2H.3c-.2 0-.3.1-.3.2 0 .2.1.3.3.3h12.4c.2 0 .3-.1.3-.3 0-.1-.1-.2-.3-.2zM9 2H4V.6h5V2z" />
                                </svg>
                            </button>
                        </div>
                    }
                </div>
            ])
        }
    }
}


ApplicationRow.defaultProps = {
}


export default ApplicationRow;