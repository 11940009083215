import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const childPaymentTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'CHILD_PAYMENT');

export const requestChildPaymentInfo = () => {
    return {
        type: childPaymentTypes.REQUEST,
    };
};

export const receiveChildPaymentInfo = (response, query={}) => {
    return {
        type: childPaymentTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveChildPaymentInfoError = () => {
    return {
        type: childPaymentTypes.ERROR,
    };
};

export const fetchChildPaymentInfo = (uuid) => {
    return dispatch => {
        dispatch(requestChildPaymentInfo());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        

        return fetch(`/api/v1/childpaymentinfo?uuid=`+ uuid, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveChildPaymentInfo(response));
                } catch (e) {
                    dispatch(receiveChildPaymentInfoError(response))
                }


            })
            .catch(handleNetworkError);
    }
}