import React from 'react';

import { memberTypes, creditCardTypes, cancelSubscriptionTypes } from './actions'
import { getPriority } from 'os';

const initialState = {
    isFetching: false,
    results: [],
    applicationOrder: [],
    creditCardUpdate: {
        isFetching: false,
        status: null,
    },
    tempToken: null, 
    cardVerificationUrl: null,
    tokenStatus: 'initial',
    subscriptionCancel: {
        isFetching: false,
        status: null
    }
}


export function members(state = initialState, action) {


    switch(action.type) {

        case memberTypes.REQUEST:
            state = {...state, isFetching: true,}
            state.isFetching = true
            return state


        case memberTypes.RECEIVE:
            state = {...state, isFetching: false}
            state.results = action.data

            if (state.results.length !== undefined && action.leiga === false) {

                state.applicationOrder = [state.results[0].Applications.map(application => {
                    application.priority = parseInt(application.priority)
                    let applicationOrder = {
                        recordId: application.recordId,
                        priority: application.priority,
                    }
                    return applicationOrder
                })]
            }
            return state
        
        case creditCardTypes.REQUEST:

            state = {...state}
            state.creditCardUpdate = {...state.creditCardUpdate, isFetching: true}

            return state


        case creditCardTypes.RECEIVE:

            state = {...state}
            state.creditCardUpdate = {...state.creditCardUpdate, isFetching: false, status: action.data.status, errorMsg: action.data.message}

            return state
    
        
        case creditCardTypes.RECEIVE_TEMP_TOKEN:
            state = {
                ...state,
                error: null,
                isFetching: false, 
                tempToken: action.data.token,
                cardVerificationUrl: action.data.card_verification_url
            }
        case cancelSubscriptionTypes.REQUEST:

            state = {...state}
            state.subscriptionCancel = {...state.subscriptionCancel, isFetching: true}

            return state


        case cancelSubscriptionTypes.RECEIVE:

            state = {...state}
            state.subscriptionCancel = {...state.subscriptionCancel, isFetching: false, status: action.data.code, message: action.data.message, type: action.data.type}

            return state


        case creditCardTypes.RECEIVE_TOKEN_STATUS:
            state = {
                ...state,
                tokenStatus: action.data.status
            }
            return state

        default:
            return state
    }
}