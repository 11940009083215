import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'
import { fetchMemberInfo } from '../members/actions'

export const lfbApplicationsTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'LFB_APPLICATIONS');
export const lfbDeleteApplicationTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'LFB_DELETE_APPLICATION');
export const lfbCreateApplicationTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'LFB_CREATE_APPLICATION');
export const housemateTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'HOUSEMATE');

export const requestLfbApplications = () => {
    return {
        type: lfbApplicationsTypes.REQUEST,
    };
};

export const receiveLfbApplications = (response, query={}) => {
    return {
        type: lfbApplicationsTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveLfbApplicationsError = () => {
    return {
        type: lfbApplicationsTypes.ERROR,
    };
};

export const requestDeleteApplication = () => {
    return {
        type: lfbDeleteApplicationTypes.REQUEST,
    };
};

export const receiveDeleteApplication = (response, query={}) => {
    return {
        type: lfbDeleteApplicationTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveDeleteApplicationsError = () => {
    return {
        type: lfbDeleteApplicationTypes.ERROR,
    };
};

export const requestCreateApplication = () => {
    return {
        type: lfbCreateApplicationTypes.REQUEST,
    };
};

export const receiveCreateApplication = (response, query={}) => {
    return {
        type: lfbCreateApplicationTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveCreateApplicationError = () => {
    return {
        type: lfbCreateApplicationTypes.ERROR,
    };
};

export const requestHousemate = () => {
    return {
        type: housemateTypes.REQUEST,
    };
};

export const receiveHousemate = (response, query={}) => {
    return {
        type: housemateTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveHousemateError = () => {
    return {
        type: housemateTypes.ERROR,
    };
};

export const fetchLfbApplications = () => {
    return dispatch => {
        dispatch(requestLfbApplications());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        

        return fetch(`/api/v1/leiguumsokn`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveLfbApplications(response));
                } catch (e) {
                    dispatch(receiveLfbApplicationsError(response))
                }


            })
            .catch(handleNetworkError);
    }
}

export const deleteLfbApplication = (recordId) => {
    let leiga = true;
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }
        dispatch(requestDeleteApplication());
        fetch('/api/v1/leiguumsokn/'+recordId+'/', {
            method: 'DELETE',
            headers: headers,
        })
        .then(() => {
            setTimeout(() => {
                dispatch(fetchMemberInfo(leiga))
            }, 500)
            }
        )
        .then(dispatch(receiveDeleteApplication()));    
    }
}

export const createLfbApplication = (ibud_id, deliveryDate, comment="", housemateSsn, files, memberInfo) => {
    
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }
        dispatch(requestCreateApplication());
        fetch('/api/v1/leiguumsokn/', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({"ID_IBUD": ibud_id, "delivery_date": deliveryDate, "comment": comment, "housemates": housemateSsn, "files": files, "memberInfo": memberInfo}),
        })
        .then(response => response.json())
        .then(data => dispatch(receiveCreateApplication(data)))    
        .then(() => {
            setTimeout(() => {
                dispatch(fetchMemberInfo(true))
            }, 500)
            }
        )
    }
}

export const fetchHousemate = (ssn) => {
    
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }
        dispatch(requestHousemate());
        fetch('/api/v1/ssn/', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({"ssn": ssn}),
        })
        .then(response => response.json())
        .then(data => dispatch(receiveHousemate(data)))    
    }
}