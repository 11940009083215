import React from 'react';

class ApplicationElectricalContract extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    // componentDidUpdate() {
    //     animateLabels();
    //     slideLabelsUp();
    // }

    render() {
        return (
            <div className="row">
                <div className="col-12">Hér kemur samningur á pdf formi sem hægt er að undirrita rafrænt</div>
            </div>
        )
    }
}

ApplicationElectricalContract.defaultProps = {
}


export default ApplicationElectricalContract;