import React from 'react';


class ChildMember extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            displayKennitalaField: false,
            kennitala: "",
            membershipRequest: true,
            name: "",
            responseMessage: "",
            responseStatus: "",
            kennitalaValid: true,
        }
    }

    componentDidUpdate() {
        slideLabelsUp();
    }

    shouldDisplayKennitala() {
        this.setState({ displayKennitalaField: true })
    }

    handleChange(event) {
        const { name, value, checked } = event.target;
        if (event.target.type == "checkbox") {
            this.setState({
                [name]: checked
            })
            this.props.updateChildMembership(this.state.kennitala, checked);
        } else {
            this.setState({
                [name]: value
            })
        }
    }

    isChildKennitalaValid(){
        let currentYear = new Date().getFullYear();
        let comparisonDate = new Date("Jan 01, " + currentYear + " 01:00:00")

        let kennitala = this.state.kennitala;
        let splitKt = kennitala.substring(0,6).match(/.{1,2}/g);
        let lastChar = kennitala[kennitala.length -1];

        let year = "";
        if (lastChar === "0") {
            year = "20" + splitKt[2];
        } else if (lastChar === "9") {
            year = "19" + splitKt[2];
        }

        let birthDateStr = year + "-" + splitKt[1] + "-" + splitKt[0] + "T00:00:00.000Z";
        let birthDate = new Date(birthDateStr);      
        let mismatch = comparisonDate-birthDate;
        let yearsBetween = mismatch/1000/(60*60*24)/365.25;

        if (yearsBetween < 18) {
            return true
        } else {
            return false
        }
    }

    handleAddKennitala() {
        this.setState({
            kennitalaValid: true,
            responseMessage: "",
        })
        if (this.isChildKennitalaValid()) {
            fetch('/api/v1/nafn/?ssn=' + this.state.kennitala, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': window.csrf_token,
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status === "success") {
                        this.setState({ name: data.name, responseStatus: data.status });
                        this.props.addChild(this.state.kennitala, this.state.name, this.props.number);
                    } else if (data.status === "error") {
                        this.setState({ responseMessage: data.message, responseStatus: data.status })
                    }
                })
        } else {
            this.setState({
                kennitalaValid: false
            })
        }

    }

    getCorrectKennitalaField() {
        if (this.state.displayKennitalaField) {
            if (this.state.name !== "") {
                return (<div className="col-12 child">
                    <div className="row">
                        <div className="member-info col-12">{this.state.name}</div>
                        <div className="form-group type-checkbox col-12">
                            <input
                                id={"child-membership-request-" + this.props.number}
                                name="membershipRequest"
                                type="checkbox"
                                onChange={this.handleChange.bind(this)}
                                checked={this.state.membershipRequest} />
                            <span className="checkmark"></span>
                            <label htmlFor={"child-membership-request-" + this.props.number}>Aðild fyrir barn (Árgjald 2.750kr.)</label>
                        </div>
                    </div>
                </div>
                )
            } else {
                return (<>
                    <div className="col-2"></div>
                    <div className="form-group col-8">
                        <label className="col-form-label" htmlFor={"child-kennitala-" + this.props.number}>Kennitala barns</label>
                        <input
                            className="form-control"
                            id={"child-kennitala-" + this.props.number}
                            name="kennitala"
                            type="number"
                            value={this.state.kennitala} // Prop: The kennitala input data
                            onChange={this.handleChange.bind(this)} // Prop: Puts data into state
                        />
                        {this.state.kennitala.length === 10 ?
                            <button
                                className="btn btn-proceed"
                                type="button" onClick={this.handleAddKennitala.bind(this)}
                            >
                            </button> 
                            : null}
                        {this.state.responseStatus === "error" && this.state.responseMessage !== "" ? <div className="warning">{this.state.responseMessage}</div> : null}
                        {!this.state.kennitalaValid ? <div className="warning">Einstaklingur er eldri en 18 ára.</div> : null}
                    </div>
                </>)
            }
        } else {
            return (null)
        }
    }

    render() {
        return (
            <>
                {this.state.displayKennitalaField ? null : <div className="col-12 add-child" onClick={this.shouldDisplayKennitala.bind(this)}><div className="add-child-text"><span className="box"></span>Aðild fyrir barn yngra en 18 ára (2.750kr.)</div></div>}
                {this.getCorrectKennitalaField()}
            </>
        )
    }


}

ChildMember.defaultProps = {
}


export default ChildMember;