import React from 'react';

class VIPClub extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        
        return (<div>THIS IS THE VIP PAGE</div>)
    }
}

VIPClub.defaultProps = {
}


export default VIPClub;