import React from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import is from 'date-fns/locale/is';
registerLocale('is', is)
import Application from "../../components/application";
import ApplicationApproved from "../../components/application-approved";
import ApplicationElectricalContract from "../../components/application-electrical-contract";

import { fetchApartments } from '../../api/apartments/actions'
import { currencyFormatter } from '../../utils'
import ApplicationInfoHeader from '../application-info-header';

class ApplicationProcess extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            customer: {},
            applying: false,
            housemateName: '',
            housemateKennitala: '',
            children: "0",
            acceptedTerms: false,
            files: [],
            image: '',
            newDeliveryDate: null,
        }
        moment.locale('is')
        this.handleDateChange = this.handleDateChange.bind(this)
    }

    componentDidMount() {
        let n = window.location.hash.lastIndexOf('/');
        let id_ibud = window.location.hash.substring(n + 1);
        this.props.dispatch(fetchApartments(id_ibud));
    }

    componentDidUpdate(prevState, prevProps) {
        animateLabels();
        slideLabelsUp();

        if (!this.props.members.isFetching && !this.props.apartment.isFetching && this.props.members.results && this.props.members.results[0] && this.props.apartment) {
          if(this.props.members.results[0].Applications){
            let apart = this.props.members.results[0].Applications.find(item => item.ibudId == this.props.apartment.id_ibud)
            if (apart && apart.deliveryDate) {
                let splitDate = apart.deliveryDate.split('/')
  
                deliveryDate = new Date(splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2])
                this.setState({newDeliveryDate: deliveryDate})
            }
          }
          // application = this.props.members.results[0].Applications.find(item => item.ibudId == apartment.id_ibud)
      }

        // Check if apartments.results has changed and set state newDeliveryDate = apartment.afhending
    }

    handleDateChange(date) {
        let d = moment(date).format('DD.MM.YYYY');
        this.setState({
            newDeliveryDate: date
        });
    };

    doesApplicationExist(idIbud) {
      let hasApplication = false;
      let members = this.props.members.results;
      if (members && members[0]) {
          if (members[0].Applications.length > 0) {
              members[0].Applications.map((application) => {
                hasApplication = (parseInt(application.ibudId) === idIbud) ? true : hasApplication;
              })
          }
      }
      return hasApplication
  }

    render() {
        let apartment = this.props.apartment.results;
        let responseCode = this.props.lfbCreatingApplication.responseCode;
        let application = {}
        if (!this.props.members.isFetching && !this.props.apartment.isFetching && this.props.members.results && this.props.members.results[0] && apartment) {
          if(this.props.members.results[0].Applications){
            application = this.props.members.results[0].Applications.find(item => item.ibudId == apartment.id_ibud)
          }
        }

        return (
            <div className="col-12 col-lg-7 my-information">
                {this.props.members.isFetching && <div className="loader"></div>}
                {!this.props.members.isFetching && !this.props.apartment.isFetching &&
                    <>
                      <ApplicationInfoHeader title={application ? "Umsókn móttekin" : "Umsókn um leiguíbúð"} subtitle={ application ? <><a className="all-applications" href="#umsoknir" onClick={() => this.props.showApplications()}> Hér</a> <span>getur þú séð allar umsóknirnar þínar.</span></> : null} apartment={apartment} application={application} handleDateChange={this.handleDateChange} deliveryDate={this.state.newDeliveryDate}></ApplicationInfoHeader>
                      <Application
                        dispatch={this.props.dispatch}
                        lfbCreatingApplication={this.props.lfbCreatingApplication}
                        responseCode={this.props.lfbCreatingApplication.responseCode}
                        responseMessage={this.props.lfbCreatingApplication.responseMessage}
                        apartment={apartment}
                        members={this.props.members}
                        housemates={this.props.housemates}
                        showApplications={this.props.showApplications}
                        newDeliveryDate={this.state.newDeliveryDate}
                        user={{ 'Kennitala': this.props.userid, 'name': this.props.userfirstname + ' ' + this.props.userlastname, 'town': '', 'phone': '', 'address': '', 'email': '', 'postal_code': '' }}/>
                      </>
                }
            </div>
        )
    }
}

ApplicationProcess.defaultProps = { }

export default ApplicationProcess;