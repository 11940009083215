import { searchTypes, SET_STATE, REMOVE_FROM_STATE, RESET, SET_POSTAL_CODE } from './actions'

let initialSearchState = {
    results: [],
    searchParams: {},
    postCodes: {},
    postalCodes: [
        {
            name: "Reykjavík",
            postalCodes: [
                "101",
                "103",
                "104",
                "105",
                "107",
                "108",
                "109",
                "110",
                "111",
                "112",
                "113",
                "116",
                "162",
            ]
        },
        {
            name: "Kópavogur",
            postalCodes: [
                "200",
                "201",
                "203",
            ]
        },
        {
            name: "Hafnarfjörður",
            postalCodes: [
                "220",
                "221",
            ]
        },
        {
            name: "Seltjarnarnes",
            postalCodes: [
                "170",
            ]
        },
        {
            name: "Garðabær",
            postalCodes: [
                "210",
                "225",
            ]
        },
        {
            name: "Mosfellsbær",
            postalCodes: [
                "270",
                "271",
                "276",
            ]
        },
    ]
}

export function apartmentSearch(state = initialSearchState, action) {


    switch(action.type) {

        case SET_STATE:
            state = {...state}
            state.searchParams[action.data.key]= action.data.value;            
            return state;
        
        case REMOVE_FROM_STATE:
            state = {...state}
            delete state.searchParams[action.data.key]
            return state;
        
        case RESET:
            state = {...initialSearchState}
            return state;

        case SET_POSTAL_CODE:
            state = {...state}
            state.postCodes[action.data.key]= action.data.value;            
            return state;

        case searchTypes.REQUEST:
            state = {...state, isFetching: true,}
            state.isFetching = true
            return state

        case searchTypes.RECEIVE:
            state = {...state, isFetching: false}
            state.results = action.results
            return state

        default:
            return state
    }
}