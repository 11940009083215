import React from 'react';

import { postalCodesTypes } from './actions'


const initialState = {
    isFetching: false,
    results: [],
}



export function postalCodes(state = initialState, action) {

    switch(action.type) {

        case postalCodesTypes.REQUEST:
            state = {...state, isFetching: true,}
            state.isFetching = true
            return state

        case postalCodesTypes.RECEIVE:
            state = {...state, isFetching: false}
            state.results = action.data
            return state

        default:
            return state
    }
}