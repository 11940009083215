import React from 'react';
import moment from 'moment';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import is from 'date-fns/locale/is';
registerLocale('is', is)

import CardForm from '../card-form'

class ApplicationApproved extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
        moment.locale('is')
    }

    componentDidUpdate() {
        animateLabels();
        slideLabelsUp();
    }

    render() {
        let apartment = this.props.apartment.results;
        return (
            <>
                <div className="row">
                    {this.props.paymentCase === "application-approved" &&
                        <>
                            <div className="col-12 application-process-header">Umsóknin hefur verið samþykkt</div>
                            <div className="col-12">Þú þarft að borga fullt af peningum með debetkortinu þínu eða millifæra. Ef þú millifærir þarftu að senda kvittuna á leigufelag@buseti.is. <br />
                                Ef þú vilt borga með debitkorti - sláðu þá inn upplýsingar hér að neðan og ýttu á "Greiða".
                            </div>
                        </>
                    }
                    {this.props.paymentCase === "confirmation-payed" &&
                        <>
                            <div className="col-12 application-process-header">Greiða eftirstöðvar mánaðarleigu og bókunargjald</div>
                            <div className="col-12">Nú ertu búin/ að borga staðfestingargjaldið og þá færðu email og eftirst0ðvarnar eru settar í kröfu með eindaga 7 dögum eftir úthlutun.</div>
                        </>
                    }
                    {this.props.paymentCase === "reamaining-amount-payed" &&
                        <>
                            <div className="col-12 application-process-header">Greiða eftirstöðvar mánaðarleigu</div>
                            <div className="col-12">Nú ertu búin/n að greiða eftirstöðvar mánaðarleigu og bókunargjaldið og þá er bara eftir að borga eftirstöðvar af leigunni. Þá færðu sendan rafrænann samning til undirritunar.</div>
                        </>
                    }
                    <form id="payment-form" onSubmit={e => e.preventDefault()} className="col-12">
                        <div className="row">
                            <CardForm paymentCase={this.props.paymentCase} />
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

ApplicationApproved.defaultProps = {
}


export default ApplicationApproved;