import React from 'react';
import moment from 'moment';
import ApartmentsList from "../apartments-list";
import Modal from '../map-modal/Modal';
import EmailSignupModal from "../email-signup-modal";
import ApplicationModal from "../application-modal";
import { currencyFormatter } from '../../utils';

import $ from 'jquery';

class Apartment extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isMapOpen: false,
            email: "",
            signupMessage: "Skráðu þig á póstlista og fáðu reglulega upplýsingar um lausar íbúðir.",
            applicationCheck: false,
            applicationExists: false
        }
    }

    componentDidUpdate(prevProps, prevState){
      if(this.props.members.length > prevProps.members.length ){ // has members been populated?
        return this.doesApplicationExist(this.props.apartments.results.id_ibud).then((resolve) => {
          this.setState({
            applicationExists: resolve,
            applicationCheck: true
          })
        })
      }
      
    }

    hasStorage() {
        let apartment = { ...this.props.apartments.results }
        let storageFacts = []
        if (apartment.geymsla && apartment.geymslu_staerd !== undefined && apartment.geymslu_staerd !== "") {
            { storageFacts.push(<div className="card-info">Geymslustærð <span className="float-right">{apartment.geymslu_staerd} m<sup>2</sup></span></div>) }
        } else if (apartment.geymsla && (apartment.geymslu_staerd === undefined || apartment.geymslu_staerd === "")) {
            { storageFacts.push(<div className="card-info">Geymsla <span className="float-right">Já</span></div>) }
        } else {
            { storageFacts.push(<div className="card-info">Geymsla <span className="float-right">Nei</span></div>) }
        }
        return storageFacts
    }

    renderImageLightBox(allImages) {
        if (allImages && Object.keys(allImages).length > 0) {
            return (Object.values(allImages).map((image, index) => {
                return (
                    <a className="lightbox-image" data-rel="lightcase:myImageGallery" href={image}>
                        <div className="img" style={{ backgroundImage: "url(" + image + ")" }}></div>
                    </a>
                )
            }))
        }
    }

    render3DImages(iframeurl, caption) {
        if (iframeurl && Object.keys(iframeurl).length > 0) {
            return (
                <a className="lightbox-image" data-rel="lightbox:myIframeGallery" href={iframeurl} data-lc-caption={caption !== "" ? caption : ""}>
                </a>
            )
        }
    }

    renderImages(images, allImages, smallerImages, apartment) {
        let imageList = []
        if (images && Object.keys(images).length > 0) {
            if (Object.keys(images).length >= 3) {
                imageList.push(
                    <div className="row">
                        <div className="col-lg-8 col-md-12 apart-img-large pl-0 pr-0">
                            <img className="img-fluid lightbox-img d-block d-lg-none" id={Object.keys(smallerImages)[0]} src={smallerImages[0]} alt="Large apartment image" />
                            <img className="img-fluid lightbox-img lg-screen d-none d-lg-block" id={Object.keys(images)[0]} src={images[0]} alt="Large apartment image" />
                            {apartment.nybygging ? <div className="new-apartment">Nýbygging</div> : <></>}
                            {(apartment.tegund_ibudar === "Leiguíbúð" && apartment.ibud_laus) && <div className="rental-available">Til leigu</div>}
                            {Object.keys(allImages).length > 0 ?
                                <a id="img-large-wrapper">
                                    {apartment.kuula && <div className="d-none d-md-block d-lg-none three-d-walk">Tölvumynd 360°</div>}
                                    <div className="d-none d-md-block d-lg-none all-img-large">Allar myndir stórar</div>
                                    <div className="d-none d-md-block d-lg-none img-amount">{Object.keys(allImages).length}</div>
                                </a> : <></>}
                        </div>
                        <div className="col-lg-4 d-none d-lg-block apart-img-small-container ">
                            <div className="small-img">
                                <img className="img-fluid lightbox-img lg-screen" id={Object.keys(images)[1]} src={images[1]} alt="Smaller apartment image" />
                            </div>
                            <div className="small-img">
                                <img className="img-fluid lightbox-img lg-screen" id={Object.keys(images)[2]} src={images[2]} alt="Smaller apartment image" />
                            </div>
                            {Object.keys(allImages).length > 0 ?
                                <a id="img-large-wrapper">
                                    {apartment.kuula && <div className="d-none d-lg-block three-d-walk"><span>Tölvumynd 360°</span></div>}
                                    <div className="d-none d-lg-block all-img-large">Allar myndir stórar</div>
                                    <div className="d-none d-lg-block img-amount">{Object.keys(allImages).length}</div>
                                </a> : <></>}
                        </div>
                    </div>
                )
            }
            else if (Object.keys(images).length < 3) {
                imageList.push(
                    <div className="row">
                        <div className="col-lg-12 largest-img pl-0 pr-0">
                            <img className="img-fluid lightbox-img d-block d-lg-none" id={Object.keys(smallerImages)[0]} src={smallerImages[0]} alt="Large apartment image" />
                            <img className="img-fluid lightbox-img d-none d-lg-block" id={Object.keys(images)[0]} src={images[0]} alt="Large apartment image" />
                            {apartment.nybygging ? <div className="new-apartment">Nýbygging</div> : <></>}
                            {(apartment.tegund_ibudar === "Leiguíbúð" && apartment.ibud_laus) && <div className="rental-available">Til leigu</div>}
                            {Object.keys(allImages).length > 1 ?
                                <a id="img-large-wrapper">
                                    {apartment.kuula && <div className="d-none d-md-block three-d-walk">Tölvumynd 360°</div>}
                                    <div className="d-none d-md-block all-img-large">Allar myndir stórar</div>
                                    <div className="d-none d-md-block img-amount">{Object.keys(allImages).length}</div>
                                </a> : null}
                        </div>
                    </div>
                )
            }
        }
        return imageList;
    }

    openMap() {
        if ($('.sticky-buttons.scroll-nav').length > 0) {
            $('.sticky-buttons').removeClass('scroll-nav');
        }
        this.setState({
            isMapOpen: true
        })
    }

    closeMap() {
        this.setState({
            isMapOpen: false
        })
    }

    isEmpty(obj) {
        for (var prop in obj) {
            return false;
        }
        return true;
    }

    isLoggedIn() {
        if (this.props.userStatus.length > 0) {
            return this.props.userStatus === "True"
        }
    }

    getEmailData(email, signupMessage) {
        this.setState({
            email: email ? email : this.state.email,
            signupMessage: signupMessage ? signupMessage : this.state.signupMessage,
        })
    }

    getApplicationData(email, signupMessage) {
        this.setState({
            email: email ? email : this.state.email,
            signupMessage: signupMessage ? signupMessage : this.state.signupMessage,
        })
    }

    isChild() {
        let isChild = false
        if (this.props.members.length > 0 && this.props.members[0].Membership.Kennitala !== "") {
            let comparisonDate = new Date();

            let kennitala = this.props.members[0].Membership.Kennitala;
            let splitKt = kennitala.substring(0, 6).match(/.{1,2}/g);
            let lastChar = kennitala[kennitala.length - 1];

            let year = "";
            if (lastChar === "0") {
                year = "20" + splitKt[2];
            } else if (lastChar === "9") {
                year = "19" + splitKt[2];
            }

            let birthDateStr = year + "-" + splitKt[1] + "-" + splitKt[0] + "T00:00:00.000Z";
            let birthDate = new Date(birthDateStr);
            let mismatch = comparisonDate - birthDate;
            let yearsBetween = mismatch / 1000 / (60 * 60 * 24) / 365.25;

            isChild = yearsBetween < 18
        } 

        return isChild
    }

    // This is done for button display, we do not want to show the button until we have results
    doesApplicationExist(idIbud) {
      let hasApplication = false;
      let members = this.props.members;
      return new Promise (( resolve, reject ) => {
        if (members && members[0]) {
            if (members[0].Applications.length > 0) {
                members[0].Applications.map((application) => {
                  hasApplication = (parseInt(application.ibudId) === idIbud) ? true : hasApplication
                })
              }
          resolve(hasApplication)
        }
      })
  }

    shouldDisplayApplicationButton() {
        if (this.props.apartments.results.tegund_ibudar === "Leiguíbúð") {
            if (!this.isChild()) {
                if (this.isLoggedIn()) {

                    if (this.state.applicationCheck === false) {
                        return (<div className="spinner-border"></div>)
                    } else if (this.props.apartments.results.i_kaupferli){
                        return (<a className="btn-green not-available" href="#">Íbúð í kaupferli</a>)
                    } else if (!this.props.apartments.results.ibud_laus || (this.props.apartments.results.i_vinnslu && !this.props.apartments.i_kaupferli)){
                        return (<a className="btn-green not-available" href="#">Íbúð ekki laus</a>)
                    }

                    return (
                        <>
                            { this.state.applicationCheck && 
                            <>                    
                            { this.state.applicationExists ?
                                <a className="btn-green" href="/minarsidur/#umsoknir">Umsókn virk</a>
                                :
                                <a className="btn-green" href={"/minarsidur/#umsokn/" + this.props.apartments.results.id_ibud} >Sækja um íbúð</a>
                            }
                            </>
                            }
                        </>
                    )
                } else {
                    return (<a className="btn-green" href={"/minarsidur/innskraning/?next=/ibudir/" + this.props.apartments.results.id_ibud}>Sækja um íbúð</a>)                    
                }
            } else {
                return (null)
            }
        } else {
            if (this.props.apartments.results.i_solu_override !== "") {
                return (<a className="btn-green not-available" href="#">{this.props.apartments.results.i_solu_override}</a>)
            } else {
                if (!this.isChild()) {
                    if (this.props.apartments.results.i_kaupferli) {
                        return(<a className="btn-green not-available" href="#">Íbúð í kaupferli</a>)
                    } else if (!this.props.apartments.results.ibud_laus || (this.props.apartments.results.i_vinnslu && !this.props.apartments.i_kaupferli)){
                        return (<a className="btn-green not-available" href="#">Íbúð ekki laus</a>)
                    } else if (this.props.applicationWindow.results.opid) {
                        if (this.isLoggedIn() && this.props.applicationWindow.results.felagi) {
                            if (this.props.applicationWindow.results.umsokn) {
                                return (<a className="btn-green" href="/minarsidur/#umsoknir">Umsókn virk</a>)
                            } else {
                                return (<a className="btn-green" href="#" data-toggle="modal" data-target="#ApplicationModal">Sækja um íbúð</a>)
                            }
                        } else {
                            return (<a className="btn-green" href={"/minarsidur/innskraning/?next=/ibudir/" + this.props.apartments.results.id_ibud}>Sækja um íbúð</a>)
                        }
                    } else {
                        return (<div></div>)
                    }
                } else {
                    return (null)
                }
            }
        }
    }

    renderAllPdfs(pdfs) {
        let allPdfs = pdfs.map((pdf) => {
            return (
                <div className="row pdfs">
                    <div className="col-6 text bold">{pdf.title}</div>
                    <div class="col-6 text-right">
                        <a class="btn-primary btn-red invert small" href={pdf.file} target="_blank">Sækja</a>
                    </div>
                </div>
            )
        })
        return (<div className="col-10">{allPdfs}</div>);
    }

    render() {
        let apartment = { ...this.props.apartments.results }
        let otherApartments = { ...this.props.apartments.results.other_apartments_in_house }
        let images = { ...this.props.apartments.results.cover_images }
        let smallerImages = { ...this.props.apartments.results.cover_images_sm }
        let allImages = { ...this.props.apartments.results.images }
        moment.locale('is');
        return (
            <>
                {this.isEmpty(apartment) === false ?
                    <Modal
                        className="modal"
                        show={this.state.isMapOpen}
                        close={this.closeMap.bind(this)}
                        store={this.props.store}
                        apartmentId={apartment.id_ibud}
                        leiga={this.props.leiga}>
                    </Modal> : null
                }
                {apartment.ibud_laus ?
                    <ApplicationModal id="ApplicationModal" newApartment={apartment.nybygging} maxBuseturettur={apartment.verd_buseturettar} apartmentId={apartment.id_ibud} sendData={this.getApplicationData.bind(this)} members={this.props.members} fetchApplicationWindow={this.props.fetchApplicationWindow} leiguibud={this.props.apartments.results.tegund_ibudar === "Leiguíbúð"}/> : null
                }
                <div>{this.renderImages(images, allImages, smallerImages, apartment)}
                    <div id="lightbox">{this.renderImageLightBox(allImages)}</div>
                    <div id="3D-lightbox">{this.render3DImages(apartment.kuula, apartment.kuula_caption)}</div>
                    <div className="apartment-info" id={apartment.id_ibud}>
                        <div className="row sticky-info">
                            {!apartment.ibud_laus || apartment.i_solu_override !== "" ?
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <div>
                                        <div className="address">
                                            {apartment.heimilisfang}
                                        </div>
                                        <div className="address-small"><span className="apart-postcode">{apartment.postnumer + " " + apartment.sveitarfelag}</span><span className="apart-number">Íbúð nr. {apartment.numer_ibudar}</span></div>
                                    </div>
                                </div>
                                :
                                <div className="col-12 col-md-6 col-xl-3 col-xxl-4">
                                    <div>
                                        <div className="address">
                                            {apartment.heimilisfang}
                                        </div>
                                        <div className="address-small"><span className="apart-postcode">{apartment.postnumer + " " + apartment.sveitarfelag}</span><span className="apart-number">Íbúð nr. {apartment.numer_ibudar}</span></div>
                                    </div>
                                </div>
                            }
                            {!apartment.ibud_laus || apartment.i_solu_override !== "" ?
                                <>
                                    <div className="col-lg-4 col-lg-5 d-none d-lg-block payment-container email">
                                        <form className="row payment-info" id="email-signup-form">
                                            <div className="col-12 pr-0">
                                                <div className="row payment-info-rows">
                                                    <div className="col-12 sign-up">{this.state.signupMessage}</div>
                                                    <input className="email-input" type="email" id="email" placeholder="Tölvupóstfang" data-toggle="modal" data-target="#ApartmentEmailSignupModal" value={this.state.email} />
                                                </div>
                                            </div>
                                        </form>
                                        <EmailSignupModal id="ApartmentEmailSignupModal" modalId={"ApartmentEmailSignupModal"} sendData={this.getEmailData.bind(this)} />
                                    </div>
                                </> :
                                <>
                                    <div className="col-6 d-none d-md-block d-lg-block d-xl-none text-right">
                                        {this.shouldDisplayApplicationButton()}
                                    </div>
                                    {apartment.i_solu_override === "" ?
                                        <div className="col-12 col-xl-5 col-xxl-4 payment-container">
                                            <div className="row payment-info">
                                                {apartment.tegund_ibudar !== 'Leiguíbúð' &&
                                                    <>
                                                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                                                            <div className="row payment-info-rows">
                                                                <div className="col-6 col-lg-12 small-text">Búseturéttur</div>
                                                                <div className="col-6 col-lg-12 large-numbers">{apartment.verd_buseturettar ? currencyFormatter(apartment.verd_buseturettar) : ""}<span>kr.</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-4 right-pos">
                                                            <div className="row payment-info-rows spacing">
                                                                <div className="col-6 col-lg-12 small-text">Lán allt að</div>
                                                                <div className="col-6 col-lg-12 large-numbers">{apartment.mogulegt_lan ? currencyFormatter(apartment.mogulegt_lan) : ""}<span>kr.</span></div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {apartment.umsoknarfrestur &&
                                                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                                                        <div className="row payment-info-rows">
                                                            <div className="col-6 col-lg-12 small-text">Umsóknarfrestur</div>
                                                            <div className="col-6 col-lg-12 large-numbers">{moment(apartment.umsoknarfrestur).format('DD. MMMM')}</div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div> : <div className="col-12 col-xl-5 col-xxl-4 payment-container"></div>
                                    }
                                </>}
                            {apartment.i_solu_override !== "" ?
                                <div className="col-md-6 col-lg-3 d-none d-md-block">
                                    <div className="row">
                                        <div className="col-2"></div>
                                        <div className="col-10 pl-0 text-right">
                                            <a className="btn-green not-available" href="#">{apartment.i_solu_override}</a>
                                        </div>
                                    </div>
                                </div>
                                : <div className="col-md-12 col-lg-12 col-xl-4 col-xxl-4 d-none d-xl-block">
                                        <div className="row">
                                            <div className="col-md-6 col-lg-4 col-xl-6 col-xxl-6 pr-0 right-pos">
                                            </div>
                                            <div className="col-md-6 col-lg-4 col-xl-6 col-xxl-6 pl-0 text-right">
                                                {this.shouldDisplayApplicationButton()}
                                            </div>
                                        </div>
                                    </div>}
                                {apartment.yfirlit_pdf &&
                                    <div className="apartment-pdf d-flex col"><a href={apartment.yfirlit_pdf} className="ml-auto d-block">Sjá yfirlit íbúðar</a></div>
                                }
                            <hr className="d-none d-lg-block"></hr>
                        </div>
                        <div className="col-12 description d-lg-none">
                            {apartment.styttri_lysing}
                        </div>
                        <div className="row justify-content-center other-apart-row">
                            <div className="col-sm-12 col-lg-4  d-lg-none other-apartments higher">
                                {Object.keys(otherApartments).length > 1 && <ApartmentsList otherApartments={otherApartments} />}
                            </div>
                        </div>
                        <div className="row apart-main-info">
                            <div className="col-md-12 col-lg-4 description d-none d-lg-block">
                                {apartment.styttri_lysing}
                            </div>
                            <div className="col-md-6 col-lg-4 upper-info-column">
                                <div className="card-body">
                                    <div className="card-info">Fastanúmer <span className="float-right">{apartment.fastanumer}</span></div>
                                    <div className="card-info">Fjöldi herbergja <span className="float-right">{apartment.herbergi}</span></div>
                                    <div className="card-info">Stærð <span className="float-right">{apartment.fermetrar} m<sup>2</sup></span></div>
                                    <div className="card-info">Staðsetning <span className="float-right">{apartment.stadsetning}</span></div>
                                    <div className="card-info">Lyfta í húsinu <span className="float-right">{apartment.lyfta ? "Já" : "Nei"}</span></div>
                                    <div className="card-info">Sérinngangur <span className="float-right">{apartment.serinngangur ? "Já" : "Nei"}</span></div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 lower-info-column">
                                <div className="card-body">
                                    {this.hasStorage()}
                                    <div className="card-info">Bílageymsla <span className="float-right">{apartment.bilageymsla ? "Já" : "Nei"}</span></div>
                                    <div className="card-info">Bílskúr <span className="float-right">{apartment.bilskur ? "Já" : "Nei"}</span></div>
                                    {apartment.tegund_ibudar === 'Leiguíbúð' && apartment.ibud_laus && <><div className="card-info empty-line"></div><div className="card-info empty-line d-none d-md-block border-0"></div><div className="card-info font-weight-bold">Leiguverð <span className="float-right">{apartment.busetugjald && apartment.ibud_laus ? currencyFormatter(apartment.busetugjald) : ""}</span></div></>}
                                    {apartment.tegund_ibudar !== 'Leiguíbúð' &&
                                        <>
                                            <div className="card-info">Búseturéttur <span className="float-right">{apartment.verd_buseturettar && apartment.ibud_laus ? currencyFormatter(apartment.verd_buseturettar) : ""}</span></div>
                                            <div className="card-info">Mánaðarlegt búsetugjald <span className="float-right">{apartment.busetugjald && apartment.ibud_laus ? currencyFormatter(apartment.busetugjald) : ""}</span></div>
                                            <div className="card-info">Mögulegt lán <span className="float-right">{apartment.mogulegt_lan && apartment.ibud_laus ? currencyFormatter(apartment.mogulegt_lan) : ""}</span></div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-lg-4 other-apartments d-none d-lg-block">
                                {Object.keys(otherApartments).length > 1 && <ApartmentsList otherApartments={otherApartments} />}
                            </div>
                            <div className="col-sm-12 col-lg-8 apart-detailed-info">
                                <div className="deliver-info">
                                    {apartment.ibud_laus && !apartment.i_vinnslu ?
                                        <div>
                                            <div className="title">Laus íbúð</div>
                                        </div>
                                        :
                                        <div className="title">Íbúð ekki laus</div>}
                                    {apartment.ibud_laus ?
                                        <div className="row deliver-info-text">
                                            <div className="col-lg-6">
                                                <div className="subtitle">Afhending<div className="text">{apartment.afhending}</div></div>
                                                <div className="subtitle">Sýning íbúðar<div className="text">{apartment.syning_ibudar}</div></div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="subtitle">Athugasemdir<div className="text">{apartment.athugasemdir}</div></div>
                                            </div>
                                        </div> : <></>}
                                </div>
                                {apartment.pdfs && apartment.pdfs.length > 0 &&
                                    <div className="apartment-pdfs">
                                        <div className="row">
                                            <div className="col-2">
                                                <svg className="pdf-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 35"><path fill="#B90E26" d="M26.6 0H2.4C1.1 0 0 1.1 0 2.4v30.2C0 33.9 1.1 35 2.4 35h24.2c1.3 0 2.4-1.1 2.4-2.4V2.4C29 1.1 27.9 0 26.6 0zm0 .8c.8 0 1.6.6 1.6 1.6v30.2c0 .8-.6 1.6-1.6 1.6H2.4c-.8 0-1.6-.6-1.6-1.6V2.4c0-.8.6-1.6 1.6-1.6h24.2" /><path fill="#B90E26" d="M20.2 14v-2.7h3.4v-.7h-3.4V8.7H24V8h-4.7v6h.9zm-5.3-.7h-1.7V8.7h1.6c.7 0 1.3.2 1.6.6.3.4.5 1 .5 1.7 0 .2 0 .4-.1.6-.1.4-.2.7-.4.9-.2.3-.5.5-.9.6 0 .2-.3.2-.6.2zm.2.7c1.2 0 2-.5 2.5-1.4.3-.5.4-1.1.4-1.7 0-.8-.2-1.5-.7-2-.5-.6-1.3-.9-2.2-.9h-2.8v6h2.8zm-6.3-3.2H6.9V8.7h1.8c.4 0 .6 0 .8.1.5.2.7.5.7.9s-.1.7-.4.8c-.3.2-.6.3-1 .3zM6.9 14v-2.5h2.2c.7 0 1.2-.2 1.5-.5.3-.3.5-.8.5-1.2 0-.5-.2-1-.6-1.3-.3-.3-.8-.5-1.4-.5H6v6h.9z" /><path fill="none" stroke="#B90E26" stroke-width=".75" stroke-miterlimit="10" d="M6 20h17v7.3h-3.1V30H6z" /><path fill="none" stroke="#B90E26" stroke-width=".75" stroke-linecap="square" stroke-miterlimit="10" d="M6 28h6v-8M12 30v-5M12 24h5v-4" /></svg>
                                            </div>
                                            {this.renderAllPdfs(apartment.pdfs)}
                                        </div>
                                    </div>
                                }
                                <div className="apartment-description">
                                    <div className="text" dangerouslySetInnerHTML={{ __html: apartment.lysing_ibudar ? apartment.lysing_ibudar.replace(/(?:\r\n|\r|\n)/g, '<br>') : "" }}></div>
                                </div>
                                <div className="house-description">
                                    <div className="text" dangerouslySetInnerHTML={{ __html: apartment.lysing_huss ? apartment.lysing_huss.replace(/(?:\r\n|\r|\n)/g, '<br>') : "" }}></div>
                                </div>
                            </div>
                            {!apartment.ibud_laus || apartment.i_solu_override !== "" ?
                                <div className="col-12 col-md-8 d-lg-none payment-container email">
                                    <form className="row payment-info" id="email-signup-form">
                                        <div className="col-12">
                                            <div className="row payment-info-rows">
                                                <div className="col-12 sign-up">{this.state.signupMessage}</div>
                                                <input className="email-input" type="email" id="email" placeholder="Tölvupóstfang" data-toggle="modal" value={this.state.email} data-target="#ApartmentEmailSignupModalLower" />
                                            </div>
                                        </div>
                                    </form>
                                    <EmailSignupModal id="ApartmentEmailSignupModalLower" modalId={"ApartmentEmailSignupModalLower"} sendData={this.getEmailData.bind(this)} />
                                </div> :
                                <></>
                            }
                        </div>
                    </div>
                    <div className="row d-md-none sticky-buttons fixed-bottom align-items-center">
                        <div className={`${apartment.kuula ? "col-2" : "col-3"} text-center icon-button sticky-image-button`}>
                            <svg className="icon-wrapper" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.1 29.5">
                                <path d="M24.5 21l4-15.1L20 3.6 19.2 1l-4.5 1.2L9.4.8 8.4 4 .8 6l3.6 13.7-1 3.7 2.1.6 1.2 4.7 8-2.2 7.8 2.1 1.2-4.6 1.5-.4-.7-2.6zM20.2 4.8l6.9 1.9L24 19.1 20.2 4.8zM10 1.8l3.1.8-3.7 1 .6-1.8zm-5.5 21l.4-1.4.3 1.6-.7-.2zm17.4 4.8L16.4 26l6.4-1.8-.9 3.4zm-14.6 0l-5.5-21L18.6 2l5.5 21-16.8 4.6z" />
                                <path d="M2.8 7.2l4.1 15.7 15.4-4.2L18.1 3 2.8 7.2zm14.7-3.1l1.2 4.5-5.3 5.4-3.4-.9L6.2 17 3.8 7.9l13.7-3.8zm-10 17.8l-1-3.9 3.8-3.9 8.8 2.4.2-.8-4.9-1.3L19 9.7l2.2 8.5-13.7 3.7z" />
                                <path d="M6.5 9.9c.2.9 1 1.5 1.9 1.5.2 0 .4 0 .5-.1.8-.2 1.4-.9 1.5-1.7.1-.8-.3-1.6-1-2.1s-1.5-.3-2.2.2-.9 1.4-.7 2.2zm1.6-1.7c.7-.2 1.3.2 1.5.8.2.6-.2 1.3-.8 1.5-.6.2-1.3-.2-1.4-.8-.2-.4-.1-.7 0-1 .2-.2.4-.4.7-.5z" />
                            </svg>
                            <div className="icon-text">Myndir</div>
                        </div>
                        <div className={`${apartment.kuula ? "col-2" : "d-none"} text-center icon-button sticky-3d-image-button`}>
                            <svg className="icon-wrapper" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 32 22">
                                <g id="_x33_60">
                                    <path id="Shape" class="st0" d="M32 6.2V5.9C31.9 2.7 24.8.3 16 .3 7 .3 0 2.8 0 6.1V16.9c0 2.1 2.9 3.9 7.8 4.9h.1c.2 0 .3-.1.4-.3v-.1-8.9c2.3-.4 4.9-.7 7.6-.7 2.8 0 5.4.3 7.7.7v9c0 .2.2.3.4.3h.1c4.9-1 7.8-2.9 7.8-4.9v-.3L32 6.2zm-7.5 5.6v-.9c3.2-.7 5.6-1.8 6.7-3.1v7c-1.2-1.2-3.6-2.3-6.7-3zm-17 0c-3.1.7-5.5 1.7-6.7 3v-7c1.1 1.3 3.5 2.3 6.7 3.1v.9zm0 9C3.4 19.9.8 18.3.8 16.7v-.5l.1-.1c.7-1.4 3.2-2.6 6.6-3.4v8.1zM16 11c-2.8 0-5.4.2-7.6.7v-1c0-.2-.1-.4-.3-.4C3.7 9.3 1 7.8.8 6.2v-.3C1 3.6 7.1 1.1 16 1.1c8.5 0 14.4 2.3 15.1 4.5v.7c-.2 1.6-2.9 3.2-7.2 4-.2 0-.3.2-.3.4v1c-2.2-.5-4.8-.7-7.6-.7zm8.5 9.8v-8.1c3.8.9 6.5 2.3 6.7 3.9v.2c0 1.5-2.6 3.1-6.7 4z"/>
                                    <path id="Path" class="st0" d="M17.5 4.5c-.2-.1-.4-.1-.5 0l-2.6 2.6-1-1c-.1-.1-.4-.1-.6 0L9.4 9.5c-.2.2-.2.4 0 .6.1.1.2.1.3.1s.2 0 .3-.1L13.1 7l1 1 1.6 1.6c.1.1.2.1.3.1.1 0 .2 0 .3-.1.2-.2.2-.4 0-.6L15 7.7l2.3-2.3 4.6 4.5c.2.2.4.1.6 0 .2-.2.1-.4 0-.6l-5-4.8z"/>
                                    <path class="st0" d="M11.4 2.8c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2 1.2-.5 1.2-1.2-.5-1.2-1.2-1.2zm0 1.9c-.4 0-.7-.3-.7-.7s.3-.7.7-.7.7.3.7.7-.3.7-.7.7z"/>
                                </g>
                            </svg>
                            <div className="icon-text">360°</div>
                        </div>
                        <div className={`${apartment.kuula ? "col-2" : "col-3"} text-center icon-button sticky-map-button`} onClick={this.openMap.bind(this)}>
                            <svg className="icon-wrapper" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 24.8">
                                <path d="M27.9 24.4l-4.7-8.1c0-.1-.1-.1-.2-.1h-3.7c1.8-2.5 3.4-5.3 3.4-7.4C22.7 3.9 18.8 0 14 0S5.2 3.9 5.2 8.8c0 2.1 1.6 4.9 3.4 7.4H5c-.1 0-.2.1-.2.1L0 24.4c-.1.2 0 .4.2.4h27.4c.3 0 .4-.2.3-.4zM6.2 8.8C6.2 4.5 9.7 1 14 1s7.7 3.5 7.7 7.8c0 3.8-6.2 10.6-7.7 12.3-1.6-1.7-7.8-8.6-7.8-12.3zm-4.7 15l3.9-6.7H9l-3.7 6.6h.1-3.9zm4.9 0l3.4-6.1c1.9 2.4 3.7 4.2 3.8 4.4l.4.4.4-.4c.2-.2 2.2-2.3 4.2-5h4l1.3 2.3h-5.1c-.5 0-1 .3-1.2.8l-1.7 3.6H6.4zm10.6 0l1.5-3.2c0-.1.2-.2.3-.2h5.7l1.9 3.3H17z" />
                                <path d="M17.6 8.1c0-2-1.6-3.6-3.6-3.6s-3.6 1.6-3.6 3.6 1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6zM14 10.8c-1.4 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6c1.4 0 2.6 1.2 2.6 2.6s-1.2 2.6-2.6 2.6z" />
                            </svg>
                            <div className="icon-text">Kort</div>
                        </div>
                        <div className="col-6 text-center">
                            {this.shouldDisplayApplicationButton()}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

Apartment.defaultProps = {}


export default Apartment;
