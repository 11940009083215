import React from 'react';
import ReactDOM from 'react-dom'
import { currencyFormatter } from '../../utils';


class ApplicationModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            offer: "",
            apartment: "",
            responseCode: "",
            responseMessage: "",
            offerTooHigh: false,
            email: "",
            phone: "",
            validEmail: true,
            validMobile: true,
            notificationText: "",
            applying: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.members !== prevProps.members && this.props.members.length > 0) {
            this.setState({
                email: this.props.members[0].Membership.email,
                phone: this.props.members[0].Membership.phone,
                oldEmail: this.props.members[0].Membership.email,
                oldPhone: this.props.members[0].Membership.phone,
            })
        }
    }

    onChange(event) {
        this.setState({
            offer: event.currentTarget.value
        })
    }

    handleSubmit(e, apartmentId) {
        e.stopPropagation();
        e.preventDefault();
        if (this.state.applying) {
            return;
        }
        this.setState({ applying: true }, () => {
            if (this.state.offer > this.props.maxBuseturettur) {
                this.setState({
                    offerTooHigh: true,
                    applying: false,
                })
            } else {
                if (this.state.offer === "" || this.state.offer === 0) {
                    fetch('/api/v1/umsokn/', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-CSRFToken': window.csrf_token,
                        },
                        body: JSON.stringify({ "ibud": apartmentId, "verd": this.props.maxBuseturettur }),
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({ responseMessage: data.message, responseCode: data.code, applying: false });
                            if (data.code === 200) {
                                this.props.fetchApplicationWindow();
                            }
                        })
                } else {
                    fetch('/api/v1/umsokn/', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-CSRFToken': window.csrf_token,
                        },
                        body: JSON.stringify({ "ibud": apartmentId, "verd": Number(this.state.offer) }),
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({ responseMessage: data.message, responseCode: data.code, applying: false });
                            if (data.code === 200) {
                                this.props.fetchApplicationWindow();
                            }
                        })
                }
            }
        });
    }

    validateEmail(value) {
        let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let isEmailValid = true;
        if (value) {
            isEmailValid = re.test(String(value).toLowerCase());
        } else {
            isEmailValid = re.test(String(this.state.email).toLowerCase());
        }

        this.setState({
            validEmail: isEmailValid
        })
    }

    validateMobileNumber(noLength) {
        if (noLength < 7 || noLength > 7) {
            this.setState({
                validMobile: false
            })
        } else {
            this.setState({
                validMobile: true
            })
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        if (name === 'email') {
            this.validateEmail(value);
        }
        if (name === 'phone') {
            this.validateMobileNumber(value.length);
        }

        this.setState({
            [name]: value
        })
    }

    updateContactInformation(e) {
        e.stopPropagation();
        e.preventDefault();

        this.validateEmail(this.state.email);
        this.validateMobileNumber(this.state.phone.length);

        if (this.state.validEmail && this.state.validMobile) {
            fetch('/api/v1/minarsidur/', {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': window.csrf_token,
                },
                body: JSON.stringify({ "email": this.state.email, "phone_no": this.state.phone }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        this.setState({
                            notificationText: 'Upplýsingarnar hafa verið uppfærðar.',
                            oldEmail: this.state.email,
                            oldPhone: this.state.phone,
                        });
                    } else {
                        this.setState({
                            notificationText: 'Ekki tókst að uppfæra upplýsingarnar.'
                        });
                    }
                });
        }
    }

    handleRentalApartmentApplication(e, apartmentId) {
        e.stopPropagation();
        e.preventDefault();
        if (this.state.applying) {
            return;
        }
        this.setState({ applying: true }, () => {
            fetch('/api/v1/leiguumsokn/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': window.csrf_token,
                },
                body: JSON.stringify({ "ID_IBUD": apartmentId }),
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({ responseMessage: data.message, responseCode: data.code, applying: false });
                    // if (data.code === 200) {
                    //     this.props.fetchApplicationWindow();
                    // }
                })
        });
    }

    getCorrectModal(newApartment) {
        const { applying } = this.state;
        if (this.props.leiguibud) {
            if (this.state.responseCode === "403") {
                return (<div className="application-text">Villa kom upp við umsókn.</div>)
            } else if (this.state.responseCode === 200) {
                return (
                    <>
                        <div className="application-text">Umsókn hefur verið send.</div>
                        <div className="button-wrapper text-center my-applications">
                            <a href="/minarsidur/#umsoknir" className="btn-primary btn-green">Mínar umsóknir</a>
                        </div>
                        {/* <div className="additional-information">Athugið! Ef þú hefur sótt um fleiri en eina íbúð þarftu að fara á <a href="/minarsidur/#umsoknir">þínar umsóknir</a> og forgangsraða umsóknum. Farið er eftir henni í úthlutuninni þegar þú átt kost á fleiri en einni íbúð.
                        <br />Gott er að yfirfara samskiptaupplýsingar og tryggja að við höfum rétt símanúmer og netfang.</div>
                        <form id="contact-info-update" className="row justify-content-center">
                            <div className="notification-text col-11">{this.state.notificationText}</div>
                            <div className="phone-input col-11">
                                <label htmlFor="phone" className="text-left">Símanúmer</label>
                                <input className="text-left" type="number" id="phone" name="phone" onChange={this.handleChange.bind(this)} value={this.state.phone}></input>
                            </div>
                            <div className="email-input col-11">
                                <label htmlFor="email" className="text-left">Netfang</label>
                                <input className="text-left" type="text" id="email" name="email" onChange={this.handleChange.bind(this)} value={this.state.email}></input>
                            </div>
                            <div className="button-wrapper col-12">
                                <input type="submit" className={this.state.validEmail && this.state.validMobile ? "btn-primary btn-green" : "btn-primary btn-green disabled"} value="Staðfesta" onClick={this.updateContactInformation.bind(this)}></input>
                            </div>
                        </form> */}
                    </>
                )

            } else {
                return (
                    <>
                        <div className="application-text">Ýttu á staðfesta til að sækja um íbúð</div>
                        <form id="apartment-apply">
                            <div className="button-wrapper">
                                <input type="submit" className="btn-primary btn-green" value="Samþykkja" onClick={e => this.handleRentalApartmentApplication(e, this.props.apartmentId)} disabled={applying}></input>
                            </div>
                        </form>
                    </>
                )
            }
        } else {
            if (this.state.responseCode === "403") {
                return (<div className="application-text">Villa kom upp við umsókn.</div>)
            } else if (this.state.responseCode === 200) {
                return (
                    <>
                        <div className="application-text">Umsókn hefur verið send.</div>
                        <div className="button-wrapper text-center my-applications">
                            <a href="/minarsidur/#umsoknir" className="btn-primary btn-green">Mínar umsóknir</a>
                        </div>
                        <div className="additional-information">Athugið! Ef þú hefur sótt um fleiri en eina íbúð þarftu að fara á <a href="/minarsidur/#umsoknir">þínar umsóknir</a> og forgangsraða umsóknum. Farið er eftir henni í úthlutuninni þegar þú átt kost á fleiri en einni íbúð.
                        <br />Gott er að yfirfara samskiptaupplýsingar og tryggja að við höfum rétt símanúmer og netfang.</div>
                        <form id="contact-info-update" className="row justify-content-center">
                            <div className="notification-text col-11">{this.state.notificationText}</div>
                            <div className="phone-input col-11">
                                <label htmlFor="phone" className="text-left">Símanúmer</label>
                                <input className="text-left" type="number" id="phone" name="phone" onChange={this.handleChange.bind(this)} value={this.state.phone}></input>
                            </div>
                            <div className="email-input col-11">
                                <label htmlFor="email" className="text-left">Netfang</label>
                                <input className="text-left" type="text" id="email" name="email" onChange={this.handleChange.bind(this)} value={this.state.email}></input>
                            </div>
                            <div className="button-wrapper col-12">
                                <input type="submit" className={this.state.validEmail && this.state.validMobile ? "btn-primary btn-green" : "btn-primary btn-green disabled"} value="Staðfesta" onClick={this.updateContactInformation.bind(this)}></input>
                            </div>
                        </form>
                    </>
                )

            } else {
                if (!newApartment) {
                    return (
                        <>
                            <div className="application-text">Hámarksverð á búseturétti er {currencyFormatter(this.props.maxBuseturettur)}kr.</div>
                            <form id="apartment-apply" className="row justify-content-center">
                                {this.state.offerTooHigh ? <div className="warning">Ekki er hægt að bjóða hærra verð en sem nemur hámarksverði á búseturétti.</div> : null}
                                <div className="amount-input">
                                    <label htmlFor="offer" className="text-left">Mitt boð</label>
                                    <input className="text-left" type="number" id="offer" placeholder={currencyFormatter(this.props.maxBuseturettur)} onChange={this.onChange.bind(this)} min={0} max={this.props.maxBuseturettur} value={this.state.offer}></input>
                                </div>
                                <div className="button-wrapper col-12">
                                    <input type="submit" className="btn-primary btn-green" value="Senda" onClick={e => this.handleSubmit(e, this.props.apartmentId)} disabled={applying}></input>
                                </div>
                            </form>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div className="application-text">Verð á búseturétti er {currencyFormatter(this.props.maxBuseturettur)}kr.</div>
                            <form id="apartment-apply">
                                <div className="button-wrapper">
                                    <input type="submit" className="btn-primary btn-green" value="Senda" onClick={e => this.handleSubmit(e, this.props.apartmentId)} disabled={applying}></input>
                                </div>
                            </form>
                        </>
                    )
                }
            }
        }

    }

    render() {
        return (
            <div className={this.state.responseCode == 200 ? "modal aem large" : "modal aem"} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true" id={this.props.id}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className={!this.props.newApartment ? "modal-body larger" : "modal-body smaller"}>
                            <div className="text-center">
                                <svg className="apply-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51">
                                    <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" d="M33.6 48.1c-5.9 2.2-12.4 2.1-18.5-.7-12-5.6-17.1-19.9-11.5-32s20-17.3 32-11.8c12.1 5.6 17.2 19.9 11.5 32-1.2 2.6-2.9 4.9-4.8 6.8l-.1.1L30.7 31" />
                                    <path fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="1.8" d="M26.3 40.9l3.8-10.5 10.4-3.6-24.7-10.7z" />
                                </svg>
                            </div>
                            <div className="apply">Umsókn </div>
                            {this.getCorrectModal(this.props.newApartment)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


ApplicationModal.defaultProps = {
}


export default ApplicationModal;