import React from 'react';


export function parseJSON(response) {
    return response.json();
  }

export const handleNetworkError = (error) => {
  if (window.Raven !== undefined) {
    Raven.captureException(error)
  } else {
    throw (error)
  }
}

export const Spinner = (props) => <div className="loader"></div>;

export const shortenLongTownName = (longTownName) => {
  let shortTownName = "";

  if (longTownName === "Garðabær") {
    shortTownName = "Gbr."
  } else if (longTownName === "Hafnarfjörður") {
    shortTownName = "Hfj."
  } else if (longTownName === "Kópavogur") {
    shortTownName = "Kóp."
  } else if (longTownName === "Reykjavík") {
    shortTownName = "Rvk."
  } else {
    shortTownName = longTownName.substring(0,3);
  }
  return shortTownName
}

export const connectWithStore = (store, WrappedComponent, ...args) => {
  var ConnectedWrappedComponent = connect(...args)(WrappedComponent)
  return function (props) {
    return <ConnectedWrappedComponent {...props} store={store} />
  }
}

export const getPrettierDateFormat = (date) => {
  let prettyDate = "";
  let splitDate = date.split("/")
  let monthName = "";
  if (splitDate[0] == 1) {
    monthName = "janúar"
  } else if (splitDate[0] == 2) {
    monthName = "febrúar"
  } else if (splitDate[0] == 3) {
    monthName = "mars"
  } else if (splitDate[0] == 4) {
    monthName = "apríl"
  } else if (splitDate[0] == 5) {
    monthName = "maí"
  } else if (splitDate[0] == 6) {
    monthName = "júní"
  } else if (splitDate[0] == 7) {
    monthName = "júlí"
  } else if (splitDate[0] == 8) {
    monthName = "ágúst"
  } else if (splitDate[0] == 9) {
    monthName = "september"
  } else if (splitDate[0] == 10) {
    monthName = "október"
  } else if (splitDate[0] == 11) {
    monthName = "nóvember"
  } else {
    monthName = "desember"
  } 

  let day = "";
  if (splitDate[1].startsWith("0")) {
    day = splitDate[1].slice(1)
  } else {
    day = splitDate[1]
  }
  prettyDate = day + ". " + monthName + " " + splitDate[2];
  return prettyDate
}

export const getLocalDateFormat = (date) => {
  let splitDate = date.split("/")
  let prettyDate = splitDate[1] + "." + splitDate[0] + "." + splitDate[2];
  
  let splitDateAndTime = date.split(" ")
  let splitSmallDate = splitDateAndTime[0].split("/");
  let smallPrettyDate = splitSmallDate[1] + "." + splitSmallDate[0] + "." + splitSmallDate[2];

  if ($(window).width() < 500) {
    return smallPrettyDate
  } else {
    return prettyDate
  }
}

export const currencyFormatter = (amount) => {
  let formattedAmount = amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  
  return formattedAmount
}

export const formatKennitala = (value) => {
  if (value.length < 10) {
    return value
  }
  return `${value.substring(0, 6)}-${value.substring(6, 10)}`
}