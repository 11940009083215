window.leiga = false
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap';
import 'isomorphic-fetch'; 
require('object-assign');
require ('es7-object-polyfill')

import Parsley from 'parsleyjs';
window.Parsley = Parsley;

require('./views/apartment-container')
require('./views/dropdownMenu')
require('./views/singleApartment')
require('./views/three-empty-apartments')
require('./views/becomeMember')
require('./views/myPages')
require('./views/childPayment')
require('./views/lfbMyPages')

import "../../../sass/main.scss"
