import React from 'react';
import ReactDOM from 'react-dom'
import { deleteApplication } from '../../api/applications/actions'
import { deleteLfbApplication } from '../../api/lfbApplications/actions'


class ApplicationDeleteModal extends React.Component {

    constructor(props) {
        super(props)
    }

    handleDelete(recordId) {
        if (this.props.lfb) {
            this.props.store.dispatch(deleteLfbApplication(recordId));
        } else {
            this.props.store.dispatch(deleteApplication(recordId));
        }
    }

    render() {
        return (
            <div className="modal delete-modal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                                    aria-hidden="true" id={this.props.id}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="apply">Eyða umsókn</div>
                            <div className="application-text">Ertu viss um að þú viljir eyða þessari umsókn?</div>
                            <form id="apartment-apply">
                                <div className="button-wrapper two-buttons">
                                    <input type="submit" className="btn-primary btn-red" value="Eyða umsókn" onClick={this.handleDelete.bind(this, this.props.recordId)} data-dismiss="modal"></input>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


ApplicationDeleteModal.defaultProps = {
}


export default ApplicationDeleteModal;