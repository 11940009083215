import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const updatePasswordTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'UPDATE_PASSWORD');


export const requestUpdatePassword = () => {
    return {
        type: updatePasswordTypes.REQUEST,
    };
};

export const receiveUpdatePassword = (response, query={}) => {
    return {
        type: updatePasswordTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveUpdatePasswordError = () => {
    return {
        type: updatePasswordTypes.ERROR,
    };
};

export const updatePassword = (pw, leiga) => {
    return dispatch => {
        dispatch(requestUpdatePassword());
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }

        return fetch("/api/v1/updatepassword/", {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ "pw": pw, "leiga": leiga })
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveUpdatePassword(response));
                } catch (e) {
                    dispatch(receiveUpdatePasswordError(response))
                }


            })
            .catch(handleNetworkError);
    };
};
