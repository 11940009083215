import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'
import moment from 'moment'
import { encode } from 'punycode';

export const SET_STATE = 'SET_STATE';
export const REMOVE_FROM_STATE = 'REMOVE_FROM_STATE';
export const SET_POSTAL_CODE = 'SET_POSTAL_CODE';
export const RESET = 'RESET';
export const searchTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'SEARCH');

export const setSearchParams = (params) => {
  return {
    type: SET_STATE,
    data: params
  }
}

export const removeSearchParams = (params) => {
  return {
    type: REMOVE_FROM_STATE,
    data: params
  }
}

export const clearSearch = () => {
  return {
    type: RESET,
  }
}

export const setPostalCode = (params) => {
  return {
    type: SET_POSTAL_CODE,
    data: params
  }
}

const requestApartments = (params) => {
  return {
    type: searchTypes.REQUEST,
    params: params
  }
}
  
const receiveApartments = (json) => {
  return {
    type: searchTypes.RECEIVE,
    results: json,
    receivedAt: moment()
  }
}
const receiveApartmentsError = () => {
  return {
      type: promotionPagesTypes.ERROR,
  };
};

export const fetchApartmentSearch = (lfb, params={}) => {
  if (params.gh) {
    params.gh = decodeURIComponent(params.gh)
  }

  let apiUrl = `/api/v1/ibudir/`

  if (params !== "" && params !== "None") {
      apiUrl = "/api/v1/ibudir/?" + `${$.param(params)}`
  }

  if (lfb === "True" && (params === "" || params === "None")){
      apiUrl += "?lfb=true"
  } else if (lfb === "True") {
      apiUrl += "&lfb=true"
  }

  return dispatch => {
    dispatch(requestApartments(params))
    return fetch(apiUrl)
      .then(response => response.json())
      .then(json => {

          try {
              dispatch(receiveApartments(json));
          } catch (e) {
              dispatch(receiveApartmentsError(json))
          }


      })
      .catch(handleNetworkError);
  }
}