import React from 'react';
import ReactDOM from 'react-dom'
import { currencyFormatter } from '../../utils';
import { editApplication } from '../../api/applications/actions'



class ApplicationEditModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            offer: "",
            apartment: "",
            offerTooHigh: false,
        }
    }

    onChange(event) {
        this.setState({
            offer: event.currentTarget.value
        })
    }

    handleSubmit(e, recordId) {
        this.props.store.dispatch(editApplication(recordId, Number(this.state.offer)));
    }

    handleIncorrectSubmit(e, recordId) {
        e.stopPropagation();
        e.preventDefault();
        if (parseInt(this.state.offer) > parseInt(this.props.maxBuseturettur)) {
            this.setState({
                offerTooHigh: true
            })
        } else {
            this.props.store.dispatch(editApplication(recordId, Number(this.state.offer)));
        }
    }

    getCorrectModal(newApartment) {

        if (!newApartment) {
            return (
                <>
                    <div className="application-text">Hámarksverð á búseturétti er {currencyFormatter(this.props.maxBuseturettur)}kr.</div>
                    <form id="apartment-apply" className="row justify-content-center">
                        {this.state.offerTooHigh ? <div className="warning">Ekki er hægt að bjóða hærra verð en sem nemur hámarksverði á búseturétti.</div> : null}
                        <div className="amount-input">
                            <label htmlFor="offer" className="text-left">Mitt boð</label>
                            <input className="text-left" type="text" id="offer" placeholder={currencyFormatter(this.props.offer)} onChange={this.onChange.bind(this)} value={this.state.offer}></input>
                        </div>
                        <div className="button-wrapper col-12">
                            {parseInt(this.state.offer) > parseInt(this.props.maxBuseturettur) ?
                            <input type="submit" className="btn-primary btn-green" value="Breyta tilboði" onClick={e => this.handleIncorrectSubmit(e, this.props.recordId)}></input> :
                            <input type="submit" className="btn-primary btn-green" value="Breyta tilboði" data-dismiss="modal" onClick={e => this.handleSubmit(e, this.props.recordId)}></input>
                            }
                        </div>
                    </form>
                </>
            )
        } else {
            return (
                <>
                    <div className="application-text">Ekki er hægt að breyta tilboði í þessa íbúð.</div>
                </>
            )
        }
    }

    render() {
        return (
            <div className="modal aem" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true" id={this.props.id}>
                <div className="modal-dialog">
                    <div className={!this.props.newApartment ? "modal-content larger" : "modal-content smaller"}>
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className={!this.props.newApartment ? "modal-body larger" : "modal-body smaller"}>
                            <div className="text-center">
                                <svg className="apply-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51">
                                    <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" d="M33.6 48.1c-5.9 2.2-12.4 2.1-18.5-.7-12-5.6-17.1-19.9-11.5-32s20-17.3 32-11.8c12.1 5.6 17.2 19.9 11.5 32-1.2 2.6-2.9 4.9-4.8 6.8l-.1.1L30.7 31" />
                                    <path fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="1.8" d="M26.3 40.9l3.8-10.5 10.4-3.6-24.7-10.7z" />
                                </svg>
                            </div>
                            <div className="apply">Umsókn </div>
                            {this.getCorrectModal(this.props.newApartment)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


ApplicationEditModal.defaultProps = {
}


export default ApplicationEditModal;