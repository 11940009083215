import React from 'react';
import { createTempToken, fetchTokenStatus } from '../../api/members/actions';
import { mailingListSignup } from '../../api/mailinglist/actions';

class Step2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formvalid: true,
            isCardHolderValid: true,
            isValidUntilValid: true,
            isCardNumberValid: true,
            isCvcNumberValid: true,
            isEmailValid: true,
            isPhoneNumberValid: true,
            paymentButtonText: "Greiða",
            display: 'cardform',
            paymentObject: null,
        }
        this.timer = null;
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        slideLabelsUp();
        updateValidUntil();
        if (this.props.members.tokenStatus === 'tokencreated' && (this.props.members.tokenStatus !== prevProps.members.tokenStatus)) {
            clearInterval(this.timer)
            let body = {}   
            Object.assign(body, this.state.paymentObject)
            body['payment_object']['payment_method'] = {'token': this.props.members.tempToken}

            fetch('/api/v1/nyskraning/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': window.csrf_token,
                },
                body: JSON.stringify(body),
            })
            .then(response => response.json())
            .then(data => {
                
                if (data.status === "success") {
                    this.setState({ responseStatus: data.status });
                    this.props.storeReceiptInfo(data.response.customer.first_name, data.response.customer.last_name, data.response.subscriptions[0].billing_logs[0].transaction.data.receipt.maskedCardNumber, data.response.customer.email)
                    this.props.nextStep();
                } else if (data.status === "error") {
                    this.setState({ responseMessage: data.message, responseStatus: data.status, paymentButtonText: "Greiða" });
                }
            })
            .catch(err => 
                this.setState({ responseMessage: "Villa kom upp við greiðslu.", responseStatus: "error", paymentButtonText: "Greiða" })
            );
        }
        if (this.props.members.tokenStatus === 'failed' && (this.props.members.tokenStatus !== prevProps.members.tokenStatus)) {
            this.setState({ responseMessage: "Villa kom upp við greiðslu.", responseStatus: "error", paymentButtonText: "Greiða" })
            clearInterval(this.timer)
        }

        $('#cardNumber').on('keypress change', function () {
            $(this).val(function (index, value) {
              return value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ').substr(0,19);
            });
        });
    }


    getChildSubscriptions() {
        const memberChildren = this.props.allChildren.map((child) => {
            if (child.membership === true) {
                return (
                    <>
                        <div className="member-info col-8 float-left text-left">{child.name}</div>
                        <div className="member-info col-4 float-right text-right">2.750kr.</div>
                    </>
                )
            }
        })
        return memberChildren;
    }

    getMainMember() {
        if (this.props.mainIsAdult) {
            const mainMember = {
                "plan": window.ASKELL_PLAN_ADULT,
                "name": this.props.mainName,
                "kennitala": this.props.mainKennitala
            }
            return mainMember;
        } else {
            const mainMember = {
                "plan": window.ASKELL_PLAN_CHILD,
                "name": this.props.singleChildName,
                "kennitala": this.props.mainKennitala
            }
            return mainMember;
        }
    }

    getChildrenMembers(childrenMembers) {
        if (this.props.allChildren.length > 0) {
            const childrenMembers = this.props.allChildren.filter(child => child.membership).map((child) => {
                if (child.membership) {
                    const childMember = {
                        "plan": window.ASKELL_PLAN_CHILD,
                        "name": child.name,
                        "kennitala": child.kennitala
                    }
                    return childMember;
                }
            })
            return childrenMembers;
        }
    }

    validatePaymentForm() {
        let paymentInfo = this.props.paymentInformation;
        let nameIncludesSpace = /\s/.test(paymentInfo.cardHolder);
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isEmailValid = re.test(String(this.props.email).toLowerCase());
        let validUntilContainsSlash = paymentInfo.validUntil.includes("/");

        if (paymentInfo.cardHolder === "" || !nameIncludesSpace) {
            this.setState({
                isCardHolderValid: false,
                formvalid: false,
            })
        } else {
            this.setState({
                isCardHolderValid: true,
            })
        }

        if (paymentInfo.cardNumber === "") {
            this.setState({
                isCardNumberValid: false,
                formvalid: false,
            })
        } else {
            this.setState({
                isCardNumberValid: true,
            })
        }


        if (this.props.email === "" || !isEmailValid) {
            this.setState({
                isEmailValid: false,
                formvalid: false,
            })
        } else {
            this.setState({
                isEmailValid: true,
            })
        }

        if (this.props.phone.length < 7) {
            this.setState({
                isPhoneNumberValid: false,
                formvalid: false,
            })
        } else {
            this.setState({
                isPhoneNumberValid: true,
            })
        }


        if (paymentInfo.validUntil.length < 5 || !validUntilContainsSlash) {
            this.setState({
                isValidUntilValid: false,
                formvalid: false,
            })
        } else {
            this.setState({
                isValidUntilValid: true,
            })
        }

        if (paymentInfo.cvc.length < 3) {
            this.setState({
                isCvcNumberValid: false,
                formvalid: false,
            })
        } else {
            this.setState({
                isCvcNumberValid: true,
            })
        }

        if(paymentInfo.validUntil.length < 5 || !validUntilContainsSlash || this.props.email === "" || !isEmailValid || paymentInfo.cardNumber === "" || paymentInfo.cardHolder === "" || !nameIncludesSpace || paymentInfo.cvc.length < 3 || this.props.phone.length !== 7) {
            return false
        } else {
            return true
        }
    }

    submitPayment() {
        let paymentInfo = this.props.paymentInformation;      

        if (this.validatePaymentForm()) {
            this.setState({
                formvalid: true,
                paymentButtonText: "Hinkrið augnablik..."
            })
            let childrenMembers = "";
            let subscriptions = [];

            if (this.props.membershipRequest) {
                subscriptions = subscriptions.concat(this.getMainMember())
            }
            if (this.props.allChildren.length > 0) {
                subscriptions = subscriptions.concat(this.getChildrenMembers(childrenMembers))
            }


            let fullname = this.props.paymentInformation.cardHolder.trim();
            let firstName = fullname.substr(0, fullname.lastIndexOf(' '));
            let lastName = fullname.substr(fullname.lastIndexOf(' ') + 1);

            let expDate = this.props.paymentInformation.validUntil.split("/");
            let expMonth = expDate[0];
            let expYear = expDate[1];

            let modifiedCardNumber = "";
            if (paymentInfo.cardNumber.includes('-')) {
                modifiedCardNumber = paymentInfo.cardNumber.replace('data-', '');
            } else {
                modifiedCardNumber = paymentInfo.cardNumber;
            }

            let paymentObj = {
                "payment_object":
                {
                    "customer": {
                        "customer_reference": this.props.mainIsAdult ? this.props.mainKennitala : this.props.payerKennitala,
                        "email": this.props.email,
                        "first_name": firstName,
                        "last_name": lastName,
                    },
                    "payment_method": {
                        "card_number": modifiedCardNumber,
                        "expiration_year": expYear,
                        "expiration_month": expMonth,
                        "cvv_number": this.props.paymentInformation.cvc
                    },
                    "subscriptions": subscriptions,
                    "phone_number": this.props.phone,
                }
            }
            Promise.resolve(this.props.dispatch(createTempToken(paymentObj))).then((res) => 
                {
                    this.setState({display: 'iframe', paymentObject: paymentObj})
                    if ( this.timer === null) {
                        this.timer = setInterval(() => this.props.dispatch(fetchTokenStatus(this.props.members.tempToken)), 1000)
                    }
                    if(this.props.availableApartments || this.props.newsletter){
                        this.props.dispatch(mailingListSignup(this.props.email, [this.props.availableApartments ? "1" : "", this.props.newsletter ? "2" : ""]))
                    }
                }
            )

        } else {
            this.setState({
                formvalid: false
            })
        }

    }

    displayContinueButton() {
        if (this.props.approveConditions) {
            return (
                <div className="col-12 continue-button-wrapper text-center">
                    <button
                        className="btn-primary btn-red"
                        type="button" onClick={this.submitPayment.bind(this)}>
                        {this.state.paymentButtonText}
                        </button>
                </div>
            )
        }
    }

    render() {
        if (this.props.currentStep !== 2) {
            return null
        }        

        return (
            <>
                <div className="col-11 member-summary">
                    <div className="row">
                        <div className="member-info-label col-8 float-left text-left">Aðild fyrir</div>
                        <div className="member-info-label col-4 float-right text-right">Árgjald</div>
                        {this.props.membershipRequest ?
                            <>
                                <div className="member-info col-8 float-left text-left">{this.props.mainIsAdult ? this.props.mainName : this.props.singleChildName}</div>
                                <div className="member-info col-4 float-right text-right">{this.props.mainIsAdult ? "5.500kr." : "2.750kr."}</div>
                            </> : null
                        }
                        {this.getChildSubscriptions()}
                    </div>
                </div>
                { this.state.display === 'cardform' &&
                    <div className="payment-information col-11">
                        <div className="row">
                            <div className="col-12 payment-info-label">Greiðslukort</div>
                            {!this.state.formvalid ? <div className="validation-warning col-12">Fylla verður rétt út í alla reiti í greiðsluforminu.</div> : null}
                            {this.state.responseStatus === "error" ? <div className="validation-warning col-12">{this.state.responseMessage}</div> : null}
                            <div className="form-group col-8">
                                <label className="col-form-label" htmlFor="cardHolder">Korthafi</label>
                                <input
                                    className="form-control"
                                    id="cardHolder"
                                    name="cardHolder"
                                    type="text"
                                    value={this.props.paymentInformation.cardHolder} // Prop: The kennitala input data
                                    onChange={this.props.handlePaymentInputChange}
                                    required // Prop: Puts data into state
                                    autoFocus
                                />
                                {!this.state.isCardHolderValid ? <div className="validation-warning pl-0 col-12">Slá þarf inn fullt nafn.</div> : null}
                            </div>
                            <div className="form-group col-8 col-md-4">
                                <label className="col-form-label" htmlFor="validUntil">Gildistími MM/ÁÁ</label>
                                <input
                                    className="form-control"
                                    id="validUntil"
                                    name="validUntil"
                                    type="text"
                                    value={this.props.paymentInformation.validUntil} // Prop: The kennitala input data
                                    onChange={this.props.handlePaymentInputChange}
                                    required // Prop: Puts data into state
                                />
                                {!this.state.isValidUntilValid ? <div className="validation-warning pl-0 col-12">Gildistími skal vera á forminu MM/ÁÁ.</div> : null}

                            </div>
                            <div className="form-group col-8">
                                <label className="col-form-label" htmlFor="cardNumber">Kortanúmer</label>
                                <input
                                    className="form-control"
                                    id="cardNumber"
                                    name="cardNumber"
                                    type="text"
                                    maxLength="19"
                                    value={this.props.paymentInformation.cardNumber} // Prop: The kennitala input data
                                    onChange={this.props.handlePaymentInputChange}
                                    required // Prop: Puts data into state
                                />
                                {!this.state.isCardNumberValid ? <div className="validation-warning pl-0 col-12">Slá þarf inn gilt kortanúmer.</div> : null}
                            </div>
                            <div className="form-group col-3 col-md-2">
                                <label className="col-form-label" htmlFor="cvc">CVC</label>
                                <input
                                    className="form-control"
                                    id="cvc"
                                    name="cvc"
                                    type="number"
                                    value={this.props.paymentInformation.cvc} // Prop: The kennitala input data
                                    onChange={this.props.handlePaymentInputChange}
                                    required // Prop: Puts data into state
                                />
                                {!this.state.isCvcNumberValid ? <div className="validation-warning pl-0 col-12">CVC of stutt.</div> : null}
                            </div>
                            <div className="form-group col-8">
                                <label className="col-form-label" htmlFor="email">Tölvupóstfang</label>
                                <input
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={this.props.email} // Prop: The kennitala input data
                                    onChange={this.props.handleChange}
                                    required
                                />
                                {!this.state.isEmailValid ? <div className="validation-warning pl-0 col-12">Slá þarf inn gilt tölvupóstfang.</div> : null}
                            </div>
                            <div className="form-group col-4">
                                <label className="col-form-label" htmlFor="phone">Símanúmer</label>
                                <input
                                    className="form-control"
                                    id="phone"
                                    name="phone"
                                    type="phone"
                                    value={this.props.phone} // Prop: The kennitala input data
                                    onChange={this.props.handleChange}
                                    required
                                />
                                {!this.state.isPhoneNumberValid ? <div className="validation-warning pl-0 col-12">Slá þarf inn gilt símanúmer.</div> : null}
                            </div>
                            <div className="d-none d-md-block col-1 col-md-2 col-lg-4"></div>
                            <div className="form-group type-checkbox col-11 col-md-8 col-xl-6">
                                <input
                                    id="approveConditions"
                                    name="approveConditions"
                                    type="checkbox"
                                    onChange={this.props.handleChange}
                                    checked={this.props.approveConditions}
                                    required />
                                <span className="checkmark"></span>
                                <label htmlFor="approveConditions">Ég samþykki <a href="/skilmalar" target="_blank" >skilmála</a> Búseta.</label>
                            </div>
                            <div className="d-none d-md-block col-1 col-md-2 col-lg-4"></div>
                            <div className="form-group type-checkbox col-11 col-md-8 col-xl-6">
                                <div className='col-12 newsletter-signup-label'>Skrá á póstlista Búseta</div>
                                <div>
                                    <input 
                                        id="availableApartments" 
                                        name="availableApartments" 
                                        type="checkbox" 
                                        onChange={this.props.handleChange}
                                        checked={this.props.availableApartments}/>
                                    <span className="checkmark"></span>
                                    <label htmlFor="availableApartments">Lausar íbúðir</label>
                                </div>
                                <div>
                                    <input 
                                        id="newsletter" 
                                        name="newsletter" 
                                        type="checkbox" 
                                        onChange={this.props.handleChange}
                                        checked={this.props.newsletter}/>
                                    <span className="checkmark"></span>
                                    <label htmlFor="newsletter">Fréttir og fróðleikur frá Búseta</label>
                                </div>
                            </div>


                            {this.displayContinueButton()}
                        </div>
                    </div>
                }
                { this.state.display === 'iframe' &&
                    <div className="payment-information col-11">
                        <div className="row">
                            <div className="col-12 payment-info-label">3D secure staðfesting</div>
                            <div className="col-12">
                                <iframe src={this.props.members.cardVerificationUrl} />
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }


}


Step2.defaultProps = {
}


export default Step2;
