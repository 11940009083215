import React from 'react';

import { apartmentTypes } from './actions'
import { apartmentMapTypes } from './actions'

const initialState = {
    isFetching: false,
    results: [],
}



export function apartments(state = initialState, action) {


    switch(action.type) {

        case apartmentTypes.REQUEST:
            state = {...state, isFetching: true,}
            state.isFetching = true
            return state

        case apartmentTypes.RECEIVE:
            state = {...state, isFetching: false}
            state.status = action.status
            state.results = action.data

            return state
        
        case apartmentTypes.ERROR:
            state = {...state}
            state.status = action.status
            state.isFetching = false

            return state

        default:
            return state
    }
}

export function mapApartments(state = initialState, action) {


    switch(action.type) {

        case apartmentMapTypes.REQUEST:
            state = {...state, isFetching: true,}
            state.isFetching = true
            return state

        case apartmentMapTypes.RECEIVE:
            state = {...state, isFetching: false}
            state.results = action.data
            ///state.results = 
            // do something with results
            return state

        default:
            return state
    }
}