import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const applicationWindowTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'APPLICATION_WINDOW');


export const requestApplicationWindow = () => {
    return {
        type: applicationWindowTypes.REQUEST,
    };
};

export const receiveApplicationWindow = (response, query={}) => {
    return {
        type: applicationWindowTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveApplicationWindowError = () => {
    return {
        type: applicationWindowTypes.ERROR,
    };
};

export const fetchApplicationWindowForApartment = (apartmentId) => {
    return dispatch => {
        dispatch(requestApplicationWindow());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        return fetch("/api/v1/umsoknargluggi/"+`${apartmentId}`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveApplicationWindow(response, apartmentId));
                } catch (e) {
                    dispatch(receiveApplicationWindowError(response))
                }


            })
            .catch(handleNetworkError);
    };
};

export const fetchApplicationWindow = () => {
    return dispatch => {
        dispatch(requestApplicationWindow());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        return fetch("/api/v1/umsokniropnar/", {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveApplicationWindow(response));
                } catch (e) {
                    dispatch(receiveApplicationWindowError(response))
                }


            })
            .catch(handleNetworkError);
    };
};
